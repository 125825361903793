export default {
  'nav.account': 'Centre personnel',
  'nav.language': 'Chinois simplifié',
  'signup.success': 'Inscription réussie',

  //erreur
  'contact.empty': 'Les informations de contact ne peuvent pas être vides',
  'phone.country.empty': 'Le préfixe téléphonique ne peut pas être vide',
  'phone.empty': 'Le téléphone ne peut pas être vide',
  'email.valid': 'Veuillez entrer le bon e-mail',

  // base
  'base.success': 'succès',
  'base.save': 'Enregistrer',
  'base.slide': 'Veuillez tirer sur le curseur pour vérifier',
  'base.send.code': 'Envoyer le code de vérification',
  'base.send.time': '({time}) renvoyer le code de vérification',
  'base.send.success': 'Envoi réussi',
  'base.view.more': 'Voir plus',
  'base.Language': 'Langue',
  'base.previous': 'Page précédente',
  'base.next': 'Page suivante',
  'base.total.page': 'Total {page} pages',
  'base.go.to.page': 'Aller à la page',
  'base.go': 'aller à',
  'base.followers': 'Abonnés',
  'base.follow': 'suivre',
  'base.following': 'Suivi',
  'base.visit.store': 'Parcourir la boutique',
  'base.contact': 'Contactez-nous',
  'base.delivery': 'Délai de livraison estimé: 1-7 jours',
  'base.search': 'Rechercher',
  'base.search.text': 'Entrez la recherche par mot-clé',
  'base.server': 'Service client en ligne',

  //indice
  'text.categories.title': 'Catégorie de produit',
  'text.welcome': 'Bienvenue dans le shopping!',
  'text.wishlist': "J'aime la liste",
  'text.account': 'Centre personnel',
  'text.orders': 'Commandes',
  'text.messages': 'Messages',
  'text.suggest.title': 'Offre exclusive',
  'text.suggest.content': 'Uniquement pour les nouveaux membres premium internationaux !',
  'text.super.deals': 'Top produits, prix incroyables.',

  //s'inscrire
  'signup.register': "S'inscrire",
  'signup.signin': 'Connexion',
  'signup.store.signin': 'Connexion professionnelle',
  'signup.sign.out': 'Déconnexion',
  'signup.email': 'Adresse e-mail',
  'signup.password': 'Mot de passe',
  'signup.qr.password': 'Confirmer le mot de passe',
  'signup.confirm.password': 'Confirmer le mot de passe',
  'signup.forgot.password': 'Mot de passe oublié',
  'signup.invitation': "Code d'invitation",
  'signup.char': 'Personnage',
  'signup.contains': 'Contient des chiffres, des lettres ou des symboles',
  'signup.qr.contains': 'Les deux mots de passe sont incohérents',
  'signup.create.account': 'Créer un compte',
  'signup.agree': 'Créez un compte, vous acceptez',
  'signup.member.agreement': "Accord d'adhésion",
  'inscription.et': 'et',
  'signup.privacy.policy': 'Politique de confidentialité',
  'signup.email.code': "Vérification de l'e-mail",
  'signup.last.step': 'Dernière étape',
  'signup.send.email': 'Veuillez entrer le code à 4 chiffres envoyé à {email}',
  'signup.modify.email': "Modifier l'e-mail",
  'signup.verify.email': "Vérifier l'e-mail",
  'signup.have.store': 'Avoir un compte marchand',
  'signup.goto.signin': 'Connexion professionnelle',
  'signup.no.store': 'Pas de compte marchand',
  'signup.goto.store': 'Inscrire un marchand',
  'signup.next': 'Suivant',
  'signup.your.email': 'votre e-mail',
  'signup.code.text': 'Code de vérification',
  'signup.submit.signup': 'Inscrivez-vous maintenant',
  'signup.smrz': 'Authentification par nom réel',
  'signup.derb': 'Deuxième étape',
  'signup.qsrxm': 'Veuillez entrer le nom du document',
  'signup.qsrhm': "Veuillez entrer le numéro d'identification",

  //oublié
  'forgot.title': 'Réinitialiser le mot de passe',
  'forgot.btn.check': "Vérifier l'e-mail",
  'forgot.reset.now': 'Réinitialiser maintenant',

  //magasin
  'store.info.open': 'Ouvert {y}semaines',

  // bas de page
  'footer.great.value': 'Excellent rapport qualité/prix',
  'footer.great.value.desc': "Nous offrons des prix compétitifs pour plus de 100 millions d'articles.",
  'footer.shopping': 'Shopping mondial',
  'footer.shopping.desc': 'Nous livrons dans plus de 200 pays et régions, et notre site Web est disponible en 7 langues.',
  'footer.safe.payment': 'Paiement sécurisé',
  'footer.safe.payment.desc': 'Payez avec les méthodes de paiement les plus populaires et les plus sûres au monde.',
  'footer.shop.with.confidence': 'Achetez en toute confiance',
  'footer.shop.with.confidence.desc': "Notre politique de protection des acheteurs couvre l'ensemble de votre processus d'achat.",
  'footer.help.center': "Centre d'aide",
  'footer.help.center.desc': "Assistance tout temps pour créer une expérience d'achat fluide.",
  'footer.terms.conditions': "Conditions d'utilisation",
  'footer.return.policy': 'Politique de retour',
  'footer.support.policy': "Politique d'assistance",
  'footer.privacy.policy': 'Politique de confidentialité',
  'footer.be.seller': 'Devenir vendeur',
  'footer.apply.now': 'Postuler maintenant',
  'footer.stay.connected': 'Rester connecté',

  'footer.about.us': 'À propos de nous',
  'footer.about.company': "Profil de l'entreprise",
  'footer.about.video': 'Présentation de la vidéo',
  'footer.contact': 'Coordonnées',

  'footer.my.account': 'Mon compte',
  'footer.my.logout': 'Déconnexion',
  'footer.ma.commande': 'Historique des commandes',
  'footer.my.wish': 'Ma liste de souhaits',
  'footer.my.join': 'Devenir membre partenaire',
  'footer.email': 'E-mail',
  'footer.gfemail': 'Courriel officiel',
  'footer.fwemail': 'Courriel de service',
  'footer.address': 'Adresse',

  'apply.success': 'Candidature réussie',
  'apply.success.desc': 'Appliquée avec succès, accédez au compte de connexion',

  // catégories
  'category.title': 'Catégories associées',
  'category.all': 'Toutes les catégories',

  //détail
  'detail.store.home': 'Accueil magasin',
  'detail.sale.items': 'Liste de produits',
  'detail.recommend': 'Recommander',
  'detail.orders': 'Ventes',
  'detail.quantity': 'Quantité',
  'detail.pieces.available': 'Disponible',
  'detail.delivery': 'Livraison',
  'detail.free.shipping': 'Livraison gratuite',
  'detail.estimated.delivery': 'Arrivée estimée',
  'détail.jours': 'jours',
  'detail.buy.now': 'Acheter',
  'detail.add.to.cart': 'Ajouter au panier',
  'detail.buyer.protection': "Protection de l'Acheteur",
  'detail.money.guarantee': 'Garantie de remboursement',
  'detail.refund.desc': "Obtenez un remboursement complet si l'article ne correspond pas à la description ou n'est pas livré",
  'detail.description': 'Description du produit',
  'detail.customer.reviews': 'Avis clients',
  'detail.specifications': 'Spécifications du produit',
  'detail.top.selling.products': 'Produits les plus vendus',
  'detail.recommended.for.you': 'Recommandé pour vous',
  'detail.vendu': 'Ventes',
  'detail.receipt': 'Confirmer la réception',
  'detail.receipt.title': 'Confirmer la réception de la commande? ',
  'detail.receipt.content': 'Après confirmation, la commande de la transaction est terminée',
  'detail.comment': 'Commentaire',
  'detail.refund.title': 'Confirmer la demande de remboursement',
  'detail.refund.content': 'Après confirmation, la commande demandera un remboursement',
  'detail.sqtk': 'Demander un remboursement',

  //confirmer
  'confirm.shipping.address': 'Adresse du destinataire',
  'confirmer.change': 'Modifier',
  'confirm.payment.methods': 'Modes de paiement',
  'confirm.summary': 'Règlement',
  'confirm.total.item.costs': 'Total des articles',
  'confirmer.total': 'Total',
  'confirm.checkout': 'Passer à la caisse',
  'confirm.place.order': 'Passez une commande maintenant',
  'confirm.pay.now': 'Payer maintenant',
  'confirm.order.desc': "Après avoir cliqué sur 'Passer commande', je confirme avoir lu et confirmé",
  'confirm.order.policy': 'Tous les termes et politiques',
  'confirm.payment': 'Global Shopping assure la sécurité de vos informations et de vos paiements',

  // adresse
  'address.title': 'Adresse de réception',
  'adresse.default': 'par défaut',
  'adresse.edit': 'Modifier',
  'adresse.delete': 'Supprimer',
  'address.new': 'Créer une nouvelle adresse',
  'adresse.contact': 'Contact',
  'adresse.adresse': 'Adresse',
  'adresse.téléphone': 'Numéro de téléphone',
  'address.set.default': 'Définir par défaut',
  'adresse.confirmer': 'Confirmer',
  'adresse.annuler': 'Annuler',
  'address.del.title': "Confirmer pour supprimer l'adresse?",
  'address.del.content': 'Êtes-vous sûr de vouloir supprimer cette adresse de livraison?',

  'payment.method': 'Mode de paiement',

  // Panier
  'shopping.title': 'Panier',
  'shopping.back.buy': 'Retour aux achats spéciaux',
  'shopping.start.shopping': 'Commencez vos achats',

  // membre
  'member.account': 'Centre personnel',
  'member.overview': "Vue d'ensemble",
  'member.orders': 'Commandes',
  'member.payment': 'Mon portefeuille',
  'member.address': 'Adresse de réception',
  'member.wishlist': "J'aime la liste",
  'member.followlist': 'Suivre la liste',
  'member.message.center': 'Centre de messagerie',
  'member.setting': 'Paramètres personnels',
  'member.shop.info': 'Informations sur la boutique',
  'member.shop.setting': 'Paramètres de la boutique',
  'member.order.notify': 'Notification de nouvelle commande',
  'member.order.tips': "En envoyant des e-mails à l'e-mail du compte marchand",

  // membre.commande
  'member.order.title': 'Ma commande',
  'member.order.view.all': 'Voir tout',
  'member.order.all': 'Tous',
  'member.order.unpaid': 'non payé',
  'member.order.paid': 'Payé',
  'member.order.procurement': 'à acheter',
  'member.order.seller.paid': 'Acheté',
  'member.order.processing': 'à traiter',
  'member.order.shipped': 'expédié',
  'member.order.return': 'De retour',
  'member.order.completed': 'Terminé',
  'member.order.refund': 'Remboursé',
  'member.order.all.time': 'tous',
  'member.order.empty': 'Pas encore de commande',
  'member.order.date': 'date',
  'member.order.purchase.date': 'Achat',
  'member.order.cpoy': 'Copier',
  'member.order.id': 'Commande',
  'member.order.detail': 'Détails de la commande',
  'member.order.logistics': 'Informations logistiques',

  // membre. paiement
  'member.payment.title': 'Mon portefeuille',
  'member.wallet.balance': 'solde du portefeuille',
  'membre.crypto.recharge': 'Recharger',
  'member.crypto.withdrawal': 'Retrait',
  'member.crypto.bank': 'Carte bancaire',
  'member.wallet.record': 'enregistrement de dépôt de pièces',
  'member.bankcard.record': 'Relevé de carte bancaire',
  'member.withdrawal.record': 'Enregistrement de retrait',
  'membre.revenu.record': 'record de revenu',
  'member.transaction.record': 'Enregistrement de la transaction',
  'member.wallet.freeze': 'Bloquer les fonds',
  'member.wallet.profit': 'Revenus estimés',

  //recharger
  'recharge.currency': 'Devise',
  'recharge.protocole': 'Protocole',
  'recharge.qrcode': 'code QR',
  'recharge.address': 'adresse du portefeuille',
  'recharge.copy': "Copier l'adresse",
  'recharge.ok.text': 'Confirmer',
  'recharge.cancel.text': 'Annuler',

  //banque
  'bank.recharge.title': 'Recharge carte bancaire',
  'bank.title': 'Nom de la banque',
  'bank.name': 'nom',
  'bank.account': 'Compte',
  'bank.routing': 'Routage',
  'bank.code': 'code',
  'bank.bankname': 'Nom de la banque',
  'bank.bankaddress': 'Adresse de la banque',
  'bank.ok.text': 'Suivant',
  'bank.ok.prev': 'Étape précédente',
  'bank.submit': 'Soumettre maintenant',
  'bank.amount': 'Montant de la recharge',
  'bank.amount.desc': 'Veuillez entrer le montant de la recharge',
  'bank.type': 'type',
  'bank.usd': 'USD',
  'bank.eur': 'Euro',
  'bank.receipt.number': 'Numéro de reçu',
  'bank.receipt.number.desc': 'Veuillez entrer le numéro de reçu de transaction',
  'bank.credential.picture': "Photo d'identification",
  'bank.credential.picture.desc': "Veuillez télécharger l'image d'identification",
  'bank.remark': 'Remarque',
  'bank.upload': 'Télécharger des images',
  'bank.text.title': 'Notes',
  'bank.text.t1': "Vous n'avez pas besoin de remarques pour le virement, laissez ce champ vide",
  'bank.text.t2': "Sélectionnez Hong Kong comme pays du ménage de Hong Kong, n'ajoutez pas la Chine",
  'bank.text.t3': "Les transferts de fonds aux États-Unis, en semaine, les transferts de fonds vers l'Europe avant 15h00, les transferts de fonds sont disponibles pendant les heures d'ouverture de la banque!",
  'bank.text.t4': 'Le délai de paiement est T+1, et le dernier paiement est T+3 en Europe',
  'bank.text.t5': "Informez la plateforme avant de transférer de l'argent pour confirmer si le compte est disponible. Si le compte est fermé, aucune indemnité ne sera versée.",
  'bank.text.t6': 'Dépôt par carte bancaire, toutes les informations du service client prévaudront.',

  //liste de banque
  'bank.list.title': 'Nom de la banque',
  'bank.list.code': 'Code banque',
  'bank.list.amount': 'Montant du transfert',
  'bank.list.number': 'Numéro de reçu',
  'bank.list.img': 'Image du bon',
  'bank.list.status': 'Statut',
  'bank.list.time': 'heure',
  'bank.list.status1': "En cours d'examen",
  'bank.list.status2': 'Réussi',
  'bank.list.status3': 'Refusé',

  // Retrait
  'withdrawal.address.desc': "Veuillez saisir l'adresse du portefeuille!",
  'retrait.numéro': 'Numéro',
  'withdrawal.real.number': 'arrivée réelle',
  'withdrawal.number.desc': 'Veuillez saisir le montant du retrait !',
  'retrait.btn.all': 'tous',
  'retrait.solde': 'Solde',
  'retrait.commission': 'commission',
  'withdrawal.actual.amount': 'Comptabilisé',
  'withdrawal.notice': 'Invite',
  'withdrawal.notice.text': "Avant de transférer, veuillez confirmer que les informations d'adresse de réception sont correctes. Une fois les actifs transférés, ils ne peuvent pas être restitués.",
  'withdrawal.notice.content': '{name}({cp_name}) commission: valeur marchande actuelle {fee_rate}%/stylo, norme minimale: {fee_min} {name}/stylo',
  'withdrawal.submit': 'Soumettre',
  'withdrawal.choice': 'Choisissez une crypto-monnaie',
  'retrait.yzm': 'Code de vérification',
  'retrait.fs': 'Envoyer',
  'withdrawal.qsryzm': "Veuillez saisir le code de vérification de l'e-mail",

  // recevoir
  'recive.method': 'Protocole',
  'recevoir.amount': 'Montant',
  'recive.address': 'Adresse',
  'reçu.date': 'heure',
  'recevoir.status': 'Statut',
  'recive.create.at': 'Heure de la transaction',
  'recevoir.type': 'Type',
  'recevoir.befor': 'avant la transaction',
  'recevoir.balance': 'Solde',
  'recive.freeze': 'Geler',
  'recive.review': 'Révision',
  'recive.success': 'Réussi',
  'recive.reject': 'Refusé',

  // afficher
  'advertise.title': 'Centre de promotion',
  'advertise.shop.title': 'Promotion de la boutique',
  'advertise.shop.status': 'Statut',
  'advertise.shop.expired': 'Expiré',
  'advertise.shop.promotion': 'Promotion',
  'advertise.shop.expire.date': "Date d'expiration",
  'advertise.shop.renew': "Renouvellement de l'annonce",
  'advertise.shop.payable': 'Montant à payer',
  'advertise.shop.explanation': 'Explication de la publicité',
  'advertise.shop.text': "Participer à la promotion publicitaire de la plateforme, augmenter l'exposition du magasin et promouvoir les commandes de transaction",
  'advertise.shop.paynow': 'Renouveler maintenant',
  'advertise.shop.modal.title': 'Confirmer le paiement',
  'advertise.shop.modal.desc': 'Confirmé de payer les frais de promotion',
  'advertise.shop.modal.btn': 'Confirmer le paiement',

  // Liste de souhaits
  'wishlist.title': 'Liste de souhaits',
  'wishlist.delete': 'Supprimer',
  'wishlist.orders': 'Ventes',

  // Suivre la liste
  'followlist.title': 'Suivre la liste',
  'followlist.delete': 'Supprimer',
  'followlist.follow': 'Suivre',

  // magasin
  'store.dashboard': 'Tableau de bord',
  'store.products': 'Produits',
  'store.products.list': 'Liste de produits',
  'store.products.reviews': 'Avis sur les produits',
  'store.orders': 'Commandes',
  'store.wallet': 'Portefeuille',
  'store.message': 'Centre de messagerie',
  'store.setting': 'paramètre',
  'store.order.total.profit': "Produit d'exploitation total estimé",

  //tableau de bord
  'dashboard.store.hour.views': 'Visites en temps réel',
  'dashboard.store.today.views': "Visites d'aujourd'hui",
  'dashboard.product.total': 'Produit total',
  'dashboard.product.today': "Nouveau aujourd'hui",
  'dashboard.order.total': 'Total de la commande',
  'dashboard.sales.total': "Chiffre d'affaires estimé",
  'dashboard.sales.real': 'Ventes réelles',
  'dashboard.sales.pay': 'Paiement total',
  'dashboard.sales.profit': 'Bénéfice total',
  'dashboard.commission.total': 'Revenu total',
  'dashboard.commission.today': "Revenus d'aujourd'hui",
  'dashboard.order.sales': 'Ventes de marchandises',
  'dashboard.sales.list': 'Classement des ventes',
  'dashboard.goods.cate.rate': 'Rapport de classification des produits',
  'dashboard.goods.wish': "J'aime le classement des produits",
  'dashboard.janvier': 'janvier',
  'dashboard.february': 'Février',
  'tableau de bord.march': 'mars',
  'tableau de bord.april': 'avril',
  'tableau de bord.may': 'mai',
  'tableau de bord.juin': 'juin',
  'tableau de bord.juillet': 'juillet',
  'tableau de bord.august': 'août',
  'dashboard.september': 'septembre',
  'dashboard.october': 'Octobre',
  'tableau de bord.novembre': 'novembre',
  'dashboard.december': 'décembre',

  // des produits
  'products.add.new': 'Ajouter un nouveau produit',
  'products.add.from.warehouse': "Ajouter des produits de l'entrepôt",
  'produits.delete': 'Supprimer',
  'produits.add': 'Ajouter',
  'produits.table.img': 'image',
  'products.table.name': 'nom du produit',
  'products.table.category': 'Catégorie',
  'produits.table.wish': "j'aime",
  'produits.table.stock': 'stock',
  'produits.table.prix': 'Prix',
  'products.table.profit': 'Profit',
  'produits.table.action': 'Action',
  'products.search.category': 'Recherche par catégorie',
  'products.back.product': 'Retour à la liste des produits',
  'products.total': 'Total des marchandises',
  'produits.oui': 'Oui',
  'products.no': 'Annuler',
  'products.batch.add': 'Augmentation de lot',
  'products.ask.add': 'Êtes-vous sûr de vouloir ajouter des produits?',
  'products.batch.delete': 'Suppression par lots',
  'products.ask.delete': 'Êtes-vous sûr de vouloir supprimer le produit?',
  'produits.haut': 'Haut',
  'products.syzd': "Haut de la page d'accueil",
  'produits.zdwz': 'Première position',
  'produits.t1': 'Promotion',
  'produits.t2': 'Préféré',
  'produits.t3': 'Boutique',
  'produits.t4': 'aucun',

  // Commentaires
  'reviews.title': 'Avis sur les produits',
  'reviews.product.name': 'Produit',
  'reviews.user.name': 'Utilisateur',
  'reviews.star': 'Note',
  'reviews.comment': 'Contenu du commentaire',
  'reviews.sku': 'Spécifications du produit',
  'avis.imgs': 'Atlas',
  'reviews.created': 'Date',

  // magasin.commande
  'store.order.purchase': 'Acheter maintenant',
  'store.order.purchase.desc': 'Êtes-vous sûr de vouloir payer pour ce produit?',
  'store.order.purchase.yes': 'Payer maintenant',
  'store.order.purchase.no': 'Annuler',
  'store.order.desc': "L'achat de cette commande nécessite un paiement et vous bénéficierez d'avantages une fois la commande terminée. ",
  'store.order.no': 'Numéro de commande',
  'store.order.number': 'Quantité',
  'store.order.buyer': 'Acheteur',
  'store.order.total': 'Montant total',
  'store.order.will.earning': 'Revenu',
  'store.order.profit': 'Bénéfice',
  'store.order.dividends': 'Dividendes',
  'store.order.payment.status': 'Statut du paiement',
  'store.order.seller.buy.status': "Statut de l'achat",
  'store.order.status': 'Statut de la commande',
  'store.order.date': 'date de commande',
  'store.order.purchase.date': "Date d'achat",
  'store.order.action': 'Opération',
  'store.order.purchase.amount': "Montant de l'achat",

  //revenu
  'revenu.create.at': "Temps d'enregistrement",
  'revenu.commande.sn': 'Numéro de commande',
  'revenu.realpay': 'Montant de la commande',
  'revenu.profit': 'revenu',

  //Paramètre
  'setting.avatar': 'Avatar professionnel',
  'setting.upload': 'Télécharger',
  'setting.shop.name': 'Nom de la boutique',
  'setting.shop.phone': 'Téléphone de contact',
  'setting.shop.address': 'Adresse de la boutique',
  'setting.shop.save': 'Enregistrer',
  'setting.upload.pic': 'Télécharger une image',
  'setting.send.pic': "Envoyer l'image",

  //beseller
  'beseller.title': 'Postuler pour devenir marchand',
  'beseller.account': 'Informations sur le compte',
  'beseller.store': 'Informations sur la boutique',
  'beseller.store.name': 'Nom du magasin',
  'beseller.store.address': 'Adresse du magasin',

  //savehome
  'store.home.title': "Paramètres de la page d'accueil",
  'store.home.topimg': 'image supérieure',
  'store.home.banner': 'Image carrousel',
  'store.home.up3': 'Remarques: Au moins 3 téléchargements',
  'store.upload.more': 'Télécharger plus de photos',
  'store.home.columns': 'Colonnes',
  'store.home.bgimg': "Image d'arrière-plan",
  'store.goods.remark': 'Remarque : 5 articles par ligne, 10 articles par assiette maximum',
  'store.home.select': 'Sélectionner un produit',
  'store.home.add': 'Ajouter une colonne',

  //informations personnelles
  'setting.update.user': 'Informations personnelles',
  'setting.update.info': 'Modifier les informations',
  'setting.user.avatar': 'Avatar utilisateur',
  'setting.user.nickname': "Pseudo de l'utilisateur",
  'setting.user.nickname.desc': "Veuillez entrer le surnom de l'utilisateur",

  'setting.safe.title': 'Informations de sécurité',
  'setting.user.passwd': 'Modifier le mot de passe',
  'setting.passwd.title1': 'Ancien mot de passe',
  'setting.passwd.desc1': "Veuillez entrer l'ancien mot de passe",
  'setting.passwd.title2': 'Nouveau mot de passe',
  'setting.passwd.desc2': 'Veuillez entrer un nouveau mot de passe',
  'setting.passwd.title3': 'Confirmer le mot de passe',
  'setting.passwd.desc3': 'Veuillez entrer un mot de passe de confirmation',
  'setting.invitation': 'Inviter des amis',
  'setting.copy': 'Copier',

  'adv.add.products': 'Ajouter des produits promotionnels',
  'adv.list.title': 'Promotion du produit',
  'adv.type': 'Type de promotion',
  'adv.begin.time': 'Heure de la promotion',
  'adv.end.time': 'Heure de fin',
  'adv.status': 'Statut',
  'adv.status.s1': 'Promouvoir',
  'adv.status.s2': 'Fin',
  'adv.add.back': 'Retour à la liste des promus',
  'adv.recharge': 'Recharger les points de promotion',
  'adv.select.goods': 'Sélectionner des marchandises',
  'adv.select.btn': 'Produits en promotion',
  'adv.recharge.title': 'Recharge des points promotionnels',
  'adv.recharge.balance': 'Solde de points',
  'adv.point': 'point',
  'adv.point.rate': 'Rapport de points',
  'adv.recharge.amount': 'Montant de la recharge',
  'adv.recharge.input.amount': 'Entrez le montant de la recharge',
  'adv.select.mod': 'Sélectionner le panneau',
  'adv.mod1': 'Colonnes recommandées',
  'adv.mod2': 'Colonne préférée',
  'adv.mod3': 'Colonne de vente à chaud',
  'adv.mod4': 'Excellente liste',
  'adv.mod5': 'Liste de classement',
  'adv.mod6': 'Promotion détaillée',
  'adv.ge': 'Quantité',
  'adv.xs': 'heure',
  'adv.xzbk': 'Sélectionner la plaque',
  'adv.syme': 'Quota restant',
  'adv.tgsc': 'Durée de la promotion',
  'adv.tgjg': 'Prix promotionnel',
  'adv.syye': 'Solde actuel',
  'adv.tgye': 'Solde des promotions',
  'adv.yj1': 'attendu',
  'adv.yj2': 'Démarrer la promotion',
  'adv.pay.point': 'Points de consommation',

  'wallet.ykcsxf': 'Les frais de gestion ont été déduits',
  'wallet.sxf': 'Frais de service',
  'wallet.zxje': 'Montant minimum',
  'wallet.24hxe': 'limite de 24heures',
  'wallet.desc.text': "En aucun cas, l'agent ne vous demandera de transférer de l'argent sur un compte, ni ne vous demandera de code de vérification. Ne participez pas à des activités illégales telles que l'achat pour le compte d'autrui, le blanchiment d'argent et la collecte de fonds illégale, et méfiez-vous de la fraude en ligne ",
  'wallet.cunbi.text': 'Vous ne pouvez déposer que {name} à cette adresse, les autres actifs ne seront pas récupérés',

  'credit.title': 'Limite de crédit',
  'credit.ed': 'quota',
  'credit.hk': 'Remboursement',
  'credit.kyye': 'Solde disponible',
  'credit.dqqk': 'Arriérés en cours',
  'credit.hkje': 'Montant du remboursement',
  'credit.hkje.desc': 'Veuillez saisir le montant du remboursement',
  'credit.cgje': "Montant de l'achat",
  'credit.qrz': "Aller à l'authentification",
  'credit.srxyj': 'Entrez le montant du paiement par crédit',

  'store.gzl': "Montant de l'attention",
  'store.wxz': 'Illimité',

  'auth.smrz': 'Authentification par nom réel',
  'auth.rzlx': 'Type',
  'auth.sfzm': "Carte d'identité recto",
  'auth.sffm': "Le verso de la carte d'identité",
  'auth.zsxm': 'vrai nom',
  'auth.zjhm': 'Numéro de document',
  'auth.yyzz': 'Licence commerciale',
  'auth.gsmc': "Nom de l'entreprise",
  'auth.shz': 'Audit',
  'auth.ytg': 'Réussi',
  'auth.ybh': 'Refusé',
  'auth.zt': 'état',
  'auth.gr': 'Personnel',
  'auth.gs': 'Entreprise',
  'auth.ljtj': "Demander l'authentification",
  'auth.wrz': 'Non authentifié',

  'credit.qts': 'Voir la description',
  'store.spss': 'Limite supérieure de la marchandise',
  'store.dpfh': 'Stocker les dividendes',
  'store.qydj': 'Niveau de fonds propres',
  'store.level': 'niveau',
  'store.jian': 'pièces',

  'order.notify.email': "En raison du contrôle des risques liés aux e-mails, les e-mails de rappel de commande de la plateforme seront fréquemment interceptés. Afin de les recevoir à temps, il est recommandé d'ajouter {e} à la liste blanche des e-mails de connexion",

  'setting.sjxx': "Informations sur l'entreprise",
  'setting.smrz': 'Authentification par nom réel',
  'setting.aqzx': 'Centre de sécurité',
  'setting.tzxx': 'Informations de notification',
  'setting.dpzx': "Page d'accueil décoration",
  'setting.sjqy': 'Intérêts commerciaux',
  'setting.sjdjqy': "Description des avantages au niveau de l'entreprise",

  'setting.fhbky': "Le dividende actuel n'est pas disponible",
  'setting.spgzyzy': 'Règles et directives du magasin',

  'desc.dqqy': 'Capitaux propres actuels',
  'desc.xjqy': 'Intérêts subordonnés',
  'desc.sjqy_0': 'Limite de crédit du magasin',
  'desc.sjqy_1': 'Stocker les dividendes',
  'desc.sjqy_2': 'Nombre de produits pouvant être publiés',

  'share.yqlb': "Liste d'invitations",
  'share.zmj': 'Vendeur total',
  'share.zdd': 'Total de la commande',
  'share.zsy': 'Revenu total de courtage',
  'share.wcl': 'non traité',
  'share.ycl': 'Traité',
  'share.ywc': 'Terminé',
  'share.dpmc': 'Nom du magasin',
  'share.ddl': 'Quantité de la commande',
  'share.jjsy': 'Revenus de courtage',
  'share.zts': 'Nombre total',

  'chat.state': 'état',
  'chat.zx': 'En ligne',
  'chat.ljz': 'Connexion',

  'bind.wallet.title': 'Adresse de retrait',
  'bind.address.title': 'Ajouter une adresse',
  'bind.bj': 'Modifier',
  'bind.sc': 'Supprimer',
  'bind.qrsc': 'Êtes-vous sûr de vouloir supprimer cette adresse?',
  'bind.qbdz': 'adresse du portefeuille',
  'bind.tjsj': 'Ajouter une heure',
  'bind.cz': 'Opération',
  'withdrawal.address.choice': "Veuillez choisir l'adresse de retrait !",

  'order.sxsj': 'Durée effective',
  'order.ljsj': 'immédiatement efficace',
  'order.dssj': 'Temporisé efficace',

  'store.st.zt': 'État de la boutique',
  'store.st.zc': 'normal',
  'store.st.zc.desc': 'Votre magasin est normal, continuez comme ça',
  'store.st.yc': 'Exception',
  'store.st.yc.desc': 'Votre boutique a des commandes anormales ou a reçu des réclamations clients',
  'store.st.dj': 'Geler',
  'store.st.dj.desc': 'Votre magasin présente un certain risque d"exploitation et les fonds sont gelés"',
  'store.st.jy': 'désactivé',
  'store.st.jy.desc': 'Votre boutique a enfreint les accords et règles liés à la plateforme et a été désactivée',

  'task.sy': "Page d'accueil",
  'task.rwlbo': 'Liste des tâches',
  'task.rwxqo': 'Détails de la tâche',
  'task.ljcy': 'Participer maintenant',
  'task.gdrw': 'Plus de tâches',
  'task.rwlb': 'Liste des tâches de saisie',
  'task.qbrw': 'Toutes les tâches',
  'task.wcyd': "J'ai participé à",
  'task.qdz': 'Récupérer des commandes',
  'task.ywc': 'Terminé',
  'task.djy': 'à échanger',
  'task.ddje': 'Montant de la commande',
  'task.cyrs': 'Nombre de participants',
  'task.ewsy': 'Avantages supplémentaires',
  'task.jzsj': 'Délai',
  'task.ckxq': 'Afficher les détails',
  'task.rwxq': 'Détails de la tâche',
  'task.cylb': 'Liste des participants',
  'task.qdcyrw': 'Êtes-vous sûr de vouloir participer à cette tâche de commande ?',
  'task.sd': 'Oui',
  'task.bue': 'non',

  'task.wdsy': 'Revenu',
  'task.wdfh': 'Dividendes',
  'task.wdqd': 'Récupérer la commande',
  'task.dd.leixin': 'Type de commande',
  'task.dd.qdrw': 'Tâche de commande rapide',
  'task.dd.ptdd': 'Commande ordinaire',

  'flow.lltg': 'Promotion de flux',
  'flow.gmll': 'Acheter un package de données',
  'flow.dqtc': 'Paquet actuel',
  'flow.tc1': 'Paquet',
  'flow.sy2': 'restant',
  'flow.tian3': 'jour',
  'flow.jlzs': "Nombre total d'enregistrements",
  'flow.lltc': 'Paquet de flux',
  'flux.ll': 'flux',
  'flow.gmsj': "Temps d'achat",
  'flow.tcjg': 'Prix du forfait',
  'flow.sjzf': 'paiement réel',
  'flow.tcsm': 'Description du paquet',
  'flow.tcsm.sm': "L'achat de ce package augmentera la visibilité du magasin et augmentera le trafic client. Afin d'obtenir plus de commandes et d'augmenter les revenus du magasin. ",

  'flow.tcmc': 'Nom du package',
  'flow.gmsc': "Durée d'achat",
  'flow.zje': 'Montant total',
  'flow.jzsj': 'Délai',
  'flow.gmrq': "Date d'achat",
  'flow.tian1': 'jour',
  'flow.zhou1': 'semaine',
  'flow.yue1': 'mois',

  'share.dj': 'niveau',
  'share.hz': 'Transfert',
  'share.hzd': 'Transférer vers',
  'share.qbye': 'solde du portefeuille',
  'share.tgje': 'Montant de la promotion',
  'share.hzje': 'Montant du transfert',
  'share.qsrhzje': 'Veuillez entrer le montant du transfert',

  '_inv._title': "Description de l'invitation",
  '_inv._t1': '1. Invitation et relation invitée',
  '_inv._desc1': "A invite B, B invite C, C invite D. Le niveau le plus élevé est 4, si D invite à nouveau E, alors E n'a rien à voir avec A. ",
  '_inv._t2': "2. L'invitant reçoit une récompense",
  '_inv._desc2': 'A reçoit 4 % du montant total réalisé par la commande B, A reçoit 2 % du montant total réalisé par la commande C, et A reçoit 1 % du montant total réalisé par la commande D',

  '_index._gm': 'Achat',
  '_index._sm': "Description : Augmenter l'exposition du magasin et attirer du trafic",

  '_shop._update': '{n} fois/mois, modifié {m} fois dans le mois en cours',

  '_chat._hc': 'Retirer',

  '_footer._suom': 'Description de la politique',

  '_footer._platform._qualification': "Qualification de l'entreprise",
  '_store._ptzz': 'Qualifications liées à la plateforme ShopifyOutlets',

  '_abt._gywm': 'À propos de nous',
  '_abt._syms': 'Modèle économique',
  '_abt._qyzr': "Responsabilité d'entreprise",
  '_abt._lxwm': 'Contactez-nous',

  '_wsj._wsjms': "Célébrez Halloween avec style dans notre boutique d'Halloween effrayante. Que vous recherchiez une astuce ou une friandise pendant cette saison effrayante, notre collection de costumes, décorations et accessoires d'Halloween a quelque chose pour toute la famille. Une déclaration effrayante avec nos collections pour femmes et hommes, ou laissez libre cours à l'imagination de votre enfant avec des costumes de déguisement. Des articles de maison et jouets mignons et inoubliables aux produits de beauté et cosmétiques glamour, trouvez les pièces parfaites pour célébrer les vacances les plus excitantes de l'année. ",

  '_wsj._b1': "Décoration d'automne",
  '_wsj._b2': 'Citrouille',
  '_wsj._b3': 'Accueil',
  '_wsj._b4': 'Enfants',
  '_wsj._b5': 'Bébé',
  '_wsj._b6': 'Femmes',
  '_wsj._b7': 'Déguisements',
  '_wsj._b8': 'Personnalisé',

  '_wsj._cj': 'Participer',
  '_wsj._gz': "Règles d'activité",
  '_wsj._flss': 'Recherche par catégorie',
  '_wsj._wsnz': "Vêtements pour femmes d'Halloween",
  '_wsj._wsn': "Vêtements pour hommes d'Halloween",
  '_wsj._wst': "Vêtements pour enfants d'Halloween",
  '_wsj._wszs': 'Décoration Halloween',
  '_wsj._wslp': "Cadeau d'Halloween",

  '_wsj._desc1': '"Spécial Halloween, Trick or Treat !" En octobre, nous avons préparé une fête de carnaval pour vous ! Venez vous procurer toutes sortes de produits effrayants et choquants, de grosses réductions vous attendent ! ',
  '_wsj._lkm': 'Faire du shopping',

  '_wsj._wsj': 'Halloween',

  '_wsj._wsjjz': 'Halloween approche',
  '_wsj._mzbt': "Calendriers de l'avent beauté",
  '_wsj._mznr': "Le compte à rebours pour la période la plus merveilleuse de l'année vient d'arriver avec des calendriers de l'Avent pour tous les goûts.",

  '_chr._tt1': 'Achetez tout Noël',
  '_chr._tt2': 'Décorations de Noël',
  '_chr._tt3': 'Acheter tous les cadeaux',
  '_chr._tt4': 'Achetez tous les vêtements de nuit de Noël',

  '_chr._dt1': 'La veille de Noël est aussi magique que le grand jour. Alors triez vos cadeaux, décorez vos couloirs et préparez vos pyjamas assortis pour la magie de Noël.',

  '_chr._dtt1': 'Vêtements de Noël',
  '_chr._dtt2': 'Noël en famille correspondant',
  '_chr._dtt3': "Calendriers de l'Avent",
  '_chr._dtt4': 'Noël à la maison',
  '_chr._dtt5': 'Boutique Cosy',
  '_chr._dtt6': 'Meubles à temps pour Noël',

  '_chr._tre1': 'Tendance actuelle',
  '_chr._tre2': 'Ajoutez un peu de fun festif à votre maison avec les tendances que tout le monde adore.',
  '_chr._tre3': "Pain d'épices",
  '_chr._tre4': 'Pudding de Noël',
  '_chr._tre5': 'Les Gonks de Noël',

  '_chr._ttre1': 'Cartes & Papier Cadeau',
  '_chr._ttre2': 'Jouets & Jeux',
  '_chr._ttre3': 'Cadeaux de nourriture et de boissons',
  '_chr._ttre4': 'Remplisseurs de bas',
  '_chr._ttre5': 'Père Noël secret',
  '_chr._ttre6': 'Le premier Noël de bébé',
  '_chr._ttre7': 'Cadeaux Beauté',
  '_chr._ttre8': 'Cadeaux Premium',
  '_chr._ttre9': 'Cadeaux personnalisés',

  '_sdj._sdnz': 'Vêtements pour femmes',
  '_sdj._sdn': 'Vêtements pour hommes',
  '_sdj._sdetlw': 'cadeau',
  '_sdj._sdzs': 'Décoration',
  '_sdj._sdjj': 'jouet',

  '_sdj._title1': 'Noël approche',
  '_sdj._desc1': '"Noël : la saison du don et de la gratitude." En décembre, nous vous avons préparé une fête de famille chaleureuse et aux couleurs vives ! Venez vous procurer une variété de produits lumineux et chaleureux avec de gros rabais qui vous attendent ! ',

  '_ssd._lhpp': 'Paiement flexible',
  '_ssd._lzhq': 'Source Globale',
  '_ssd._pwyx': 'noté excellent',
  '_ssd._qsth': 'Retour facile',
  '_ssd._jgppcn': 'Ajustement des prix et engagement',
  '_ssd._ztbt': 'Créer un guichet unique mondial',
  '_ssd._yzspl': "S'approvisionner directement auprès de plus de 8 000 marques indépendantes",
  '_ssd._zcgm': "S'inscrire pour acheter",
  '_ssd._zccs': "S'inscrire à la vente",

  '_ssd._dlwz': 'Connexion et inscription',

  '_ssd._qjrgwc': 'Tout ajouter au panier',

  '_zddy._gzlb': 'Liste de surveillance',
  '_zddy._zsersxs': 'Vendu chez shopifyoutlets',
  '_zddy._zccwsj': "S'inscrire en tant que commerçant",
  '_zddy._dlyh': 'Connexion',
  '_zddy._zcyh': "S'inscrire",
  '_zddy._xyh': 'Nouvel utilisateur',
  '_zddy._gwc': 'Panier',
  '_zddy._znxx': 'Message',
  '_zddy._zshy': 'Le plus populaire',
  '_zddy._ddl': 'Commander',
  '_zddy._mysj': 'Aucune donnée',
  '_zddy._ckgd': 'Voir plus',
  '_zddy._tjsp': 'Produits recommandés',
  '_zddy._rmsp': "Produits populaires, nouvelles tendances, plus d'opportunités d'achat",
  '_zddy._xsp': 'Nouveau produit',
  '_zddy._jlzzr': 'Les articles les plus chauds des deux dernières semaines',
  '_zddy._ljgm': 'Acheter maintenant',
  '_zddy._gdsp': 'Produits plus populaires',
  '_zddy._ssbt': 'Rechercher',
  '_zddy._ssgjz': 'Je cherche..',

  '_zddy._bdhb': 'Monnaie locale',
  '_zddy._xzbdhb': 'Sélectionnez la devise locale',
  '_zddy._zje': 'Montant total',
  '_zddy._xzzffs': 'Veuillez choisir le mode de paiement qui vous convient',
  '_zddy._jmhb': 'Crypto-monnaie',

  '_zddy._cp': 'produit',
  '_zddy._sj': "Homme d'affaires",
  '_zddy._mj': 'Vendeur',
  '_zddy._fhsy': "Retour à la page d'accueil",

  '_sjsy._ddpl': 'Liste des commandes',
  '_yhqd._czbz': 'Veuillez contacter le service client dédié pour confirmer les informations de recharge avant de recharger.',
  '_yhqd._lxkf': 'Contactez le service à la clientèle',
  '_yhqd._qsrje': 'Veuillez saisir le montant de la recharge',
  '_yhqd._wrhyhtd': 'Pas d\u0026#39;accès bancaire',
  '_yhqd._yhzz': 'Virement',
  '_yhbt._yhlx': 'Type de banque',
  '_zcyz._sjdxyz': 'Vérification des SMS mobiles',
  '_zcyz._sjhm': 'numéro de téléphone',
  '_zcyz._srsjhm': 'Entrez le numéro de portable',
  '_zzsd._qsrzzje': 'Veuillez saisir le montant du transfert',
  '_zzsd._zzje': 'montant du transfert',
  '_txcd._qsrtxje': 'Veuillez saisir le montant du retrait',
  '_txcd._skr': 'Bénéficiaire',
  '_txcd._skr._ms': 'Veuillez saisir le nom du titulaire de la carte',
  '_txcd._skzh': 'Numéro de compte de paiement',
  '_txcd._skzh._ms': 'Veuillez saisir le numéro de compte de paiement',
  '_txcd._tjyhk': 'Ajouter une nouvelle carte bancaire',
  '_txcd._txje': 'Montant du retrait',
  '_txcd._wdyhdz': 'Adresse de la banque',
  '_txcd._yhdm': 'Code programme',
  '_txcd._yhdm._ms': 'Veuillez saisir le code banque',
  '_txcd._yhdz': 'adresse',
  '_txcd._yhdz._ms': 'Veuillez saisir votre adresse bancaire',
  '_txcd._yhlx': 'type',
  '_txcd._yhlx._ms': 'Veuillez saisir le type de carte bancaire',
  '_txcd._yhly': 'routage',
  '_txcd._yhly._ms': 'Veuillez saisir le routage bancaire',
  '_txcd._yhmc': 'Banque',
  '_txcd._yhmc._ms': 'Veuillez saisir le nom de la banque',
  '_txcd._yhtx': 'Retrait bancaire',

  '_jys._gmjmhb': 'Acheter de la cryptomonnaie',
  '_jys._gmzy': 'Vous pouvez créer un compte et acheter de la crypto-monnaie en vous rendant sur les bourses suivantes et conformément aux politiques de votre pays actuel. Alors rechargez sur le compte de la plateforme ! ',

  '_kdydj._bt': 'Ouvrez une boutique et gagnez de gros prix',
  '_kdydj._xyzbt': 'La chance continue de tourner',
  '_kdydj._yx': 'Pomper {n} fois',
  '_kdydj._xh': 'Consommation {n} points',

  '_kdydj._jf': 'Points :',
  '_kdydj._zjjl': 'Record gagnant',

  '_kdydj._hdgz': "Règles d'activité",
  '_kdydj._qx': "S'il vous plaît",
  '_kdydj._dl': 'Connexion',

  '_kdydj._mjdl': 'Connexion vendeur',
  '_kdydj._yxh': 'E-mail',
  '_kdydj._yxhsm': 'Veuillez entrer votre email',
  '_kdydj._yxhmm': 'Veuillez saisir votre mot de passe',
  '_kdydj._rgmyzh': "Si vous n'avez pas de compte",
  '_kdydj._djzc': 'Cliquez pour vous inscrire',
  '_kdydj._wjmm': 'Mot de passe oublié',

  '_kdydj._wdjp': 'Mon prix',
  '_kdydj._zjts': 'Conseils gagnants',
  '_kdydj._jx': 'Continuer',
  '_kdydj._czl': 'Gagné',

  '_kdydj._ljjl': 'Récompense cumulée',
  '_kdydj._ycy': 'Participé : {n} fois',
  '_kdydj._cy': 'Participer {n} fois',
  '_kdydj._lq': 'Recevoir',
  '_kdydj._ylq': 'Reçu',
  '_kdydj._wdd': 'Non atteint',

  '_dk._dkbt1': "Le prêt entrepreneurial résout le problème d'emprunter de l'argent",
  '_dk._dkbt2': 'Fournir des prêts entrepreneuriaux et des services de circulation de capitaux',
  '_dk._dkbt3': "Nous vous aiderons à résoudre les problèmes de prêt tels que le manque de fonds, l'absence d'endroit où emprunter de l'argent et le fait d'être gêné de demander à des amis d'emprunter de l'argent. Nous fournissons des services professionnels tout au long du processus et répondons à vos questions à tout moment. L'objectif est de rendre chaque prêt transparent, permettant aux clients de les utiliser rapidement et en toute confiance. ",
  '_dk._zxsq': 'Candidature en ligne',
  '_dk._wddk': 'Mon prêt',
  '_dk._aqwy': 'Sécurité et sans souci',
  '_dk._lhed': 'Quota flexible',
  '_dk._lhedb': "Montant : 5,00 à 500 000, taux d'intérêt faible, méthodes de remboursement flexibles, pas de frais initiaux, prêt en ligne, pas d'appel de révision, confidentialité des informations et prêt le jour même. ",
  '_dk._jsfk': 'Prêt rapide',
  '_dk._jsfkb': "Réussissez l'examen dans l'heure la plus rapide et le paiement sera effectué immédiatement après l'examen. ",
  '_dk._aqww1': 'Sûr et sans souci',
  '_dk._aqww1b': "Pas de prêt hypothécaire, pas de dépôt requis, pas de retour d'appel, c'est une affaire privée. ",

  '_dk._gywm': 'À propos de nous',
  '_dk._gywm1': "Nous nous engageons à fournir des services financiers de prêt, basés sur la philosophie d'entreprise du « client d'abord » et avec pour mission de résoudre les problèmes de rotation du capital des clients. Depuis sa création, la société a aidé d'innombrables clients à résoudre la rotation du capital. problèmes. ",
  '_dk._gywm2': "Montant : 500 à 500 000, taux d'intérêt faible, méthodes de remboursement flexibles, pas de frais initiaux, prêt en ligne, pas d'appels de révision, confidentialité des informations et prêt le jour même. ",
  '_dk._gywm3': "Si vous ne souhaitez pas emprunter d'argent à vos camarades de classe et à vos amis, venez nous voir. ",
  '_dk._gywm4': 'Engagé dans les services de prêt aux entrepreneurs',
  '_dk._gywm5': 'Résolvez vos problèmes financiers',
  '_dk._hzhb': 'Partenaire - Plateforme de prêt en ligne',
  '_dk._smwda': 'Les utilisateurs de la station mondiale shopifyoutlets Business viennent de 112 pays à travers le monde et utilisent la crypto-monnaie USDT/ETH/BTC/TRX pour le règlement. La crypto-monnaie est un moyen de commerce sans frontières, permettant des transactions instantanées à faible coût partout dans le monde, sans attente ni frais internationaux. ',

  '_dk._yjwa': 'shopifyoutlets Business© Co., Ltd. 2022-2024 Tous droits réservés',
  '_dk._yjwab': 'ShopifyOutlets Holdings Global Sourcing Limited (0008945) est autorisé et réglementé par la Financial Conduct Authority en tant que courtier en crédit et travaille exclusivement avec le prêteur Home Retail Group CardServices Limited, exerçant ses activités sous le nom de shopifyoutlets Business Financial Services, shopifyoutlets Business Financial Services est autorisé et réglementé par la Financial Conduct Authority pour accorder du crédit. Home Retail GroupCard Services Limited est enregistrée en Angleterre (04007072), Avebury, 489-499Avebury Boulevard, Milton Keynes, Royaume-Uni. MK9 2NW. ',

  '_dk._cyzjsq': 'Remplir la demande de prêt commercial',
  '_dk._dkll': "Taux d'intérêt du prêt",
  '_dk._dkll2': "Description du taux d'intérêt du prêt (cliquez pour afficher)",
  '_dk._gs1': 'Formule',
  '_dk._gs2': 'Intérêt',
  '_dk._gs3': 'Montant du prêt',
  '_dk._gs4': "Taux d'intérêt annuel",
  '_dk._gs5': 'Nombre de jours du prêt',
  '_dk._gs6': 'par exemple',
  '_dk._gs7': 'Montant du prêt',
  '_dk._gs8': 'Cycle de prêt',
  '_dk._gs9': 'jour',
  '_dk._fkfs': 'Méthode de prêt',
  '_dk._zhye': 'Solde du compte',
  '_dk._dkqx': 'Durée du prêt',
  '_dk._dkqx1': 'Sélectionner la durée du prêt',
  '_dk._sqje': 'Montant de la demande',
  '_dk._qsrdk': 'Veuillez saisir le prêt',
  '_dk._dkje': 'Montant du prêt',
  '_dk._gj': 'Nationalité',
  '_dk._qxzgj': 'Veuillez sélectionner la nationalité',
  '_dk._sqr': 'Candidat',
  '_dk._qsrsqsm': 'Veuillez saisir le nom du candidat',
  '_dk._zjhm': "numéro d'identification",
  '_dk._qsrzjhm': "Veuillez saisir le numéro d'identification",
  '_dk._zjzp': "Photo d'identité",
  '_dk._zjzp1': "Veuillez télécharger une photo du recto de votre pièce d'identité",
  '_dk._zjfm1': "Veuillez télécharger une photo du verso de votre pièce d'identité",
  '_dk._zjsc1': "Veuillez télécharger une photo de votre carte d'identité",
  '_dk._zjzp2': 'Sélectionner une image',
  '_dk._pzslt': 'Exemple de photographie',
  '_dk._wytyb': "J'ai lu et j'accepte",
  '_dk._jkxy': 'Contrat de prêt',
  '_dk._tjsq': 'Soumettre la candidature',

  '_dk._wddk2': 'Mon prêt',
  '_dk._ydk': 'Prêté',
  '_dk._cslx': "Générer de l'intérêt",
  '_dk._yjhk': 'Remboursement estimé',
  '_dk._dkjl': 'Enregistrement du prêt',
  '_dk._hkjl': 'Dossier de remboursement',
  '_dk._sqrq': 'Date de candidature',
  '_dk._hkzq': 'Cycle de remboursement',
  '_dk._gdll': "Taux d'intérêt fixe",
  '_dk._hkz': 'Remboursement en cours',
  '_dk._yhk': 'Remboursé',
  '_dk._sqz': 'Candidature',
  '_dk._ytg': 'Réussi',
  '_dk._ybh': 'Rejeté',
  '_dk._tqhk': 'Remboursement anticipé',

  '_dk._ljhk': 'Rembourser immédiatement',
  '_dk._qrytqhk': "Etes-vous sûr de vouloir demander un remboursement à l'avance ?",
  '_dk._qrytqhk1': 'Remarque : Une fois la demande acceptée, le montant déposé sera directement reversé en priorité sur le prêt',
  '_dk._dkxq': 'Détails du prêt',
  '_dk._dkje1': 'Montant du prêt',
  '_dk._yhlx': 'Intérêts dus',
  '_dk._hkze': 'Remboursement total',
  '_dk._sqlhkz': 'Demande de remboursement',
  '_dk._zxydbty': `Veuillez lire attentivement et cocher la case pour accepter l'"Accord de prêt"`,
  '_dk._zzsqz': 'Application',

  '_dk._dksq': 'Crédit',

  '_dk._cs': 'Paramètres',
  '_dk._nllsm': 'Exprimé en pourcentage, par exemple : 6,25%',
  '_dk._dktssm': "Le nombre total de jours entre le début et la fin de l'approbation du prêt",
  '_dk._yndts': 'Le nombre de jours dans une année : généralement 365 jours',
  '_dk._zlj': 'Frais de retard',

  '_xbb._xz': 'Télécharger',
  '_xbb._xzapp': "Télécharger l'application ShopifyOutlets",
  '_xbb._smewm': 'Scannez le code QR pour télécharger',
  '_xbb._syfl': 'Toutes les catégories',
  '_xbb._gdfl': 'Plus',
  '_xbb._sbbt1': 'Livraison rapide',
  '_xbb._sbbt2': 'Des remboursements sont disponibles en cas de problème',
  '_xbb._sbbt3': 'Retours gratuits',
  '_xbb._sbbt4': 'Dans les 90 jours',
  '_xbb._tjsp1': 'Produits recommandés',
  '_xbb._tjsp2': 'De nombreuses réductions',
  '_xbb._tjsp3': 'Remise à durée limitée',

  '_xbb._asjgw': 'Achats par magasin',
  '_xbb._gdsp': 'Plus de produits',
  '_xbb._ljgm': 'Acheter maintenant',
  '_xbb._ptbz': 'Garantie ShopifyOutlets',
  '_xbb._aqzf': 'Paiement sécurisé',
  '_xbb._aqzf1': 'Un moyen de paiement utilisé par de nombreux acheteurs internationaux',
  '_xbb._aqys': 'Sécurité et confidentialité',
  '_xbb._aqys1': 'Nous respectons votre vie privée afin que vos informations personnelles soient en sécurité',
  '_xbb._mjbh': "Protection de l'acheteur",
  '_xbb._mjbh1': "Si votre commande n'est pas livrée avant la date prévue ou si vous n'êtes pas satisfait de la commande, vous pouvez vous faire rembourser",
  '_xbb._mzsm': 'Avertissement/Avis de non-responsabilité',

  '_xbb._sjxx': "Homme d'affaires",
  '_xbb._ggms': 'Spécification',
  '_xbb._mswa': 'Description',
  '_xbb._khpl': 'Commentaires clients',
  '_xbb._zmfk': 'Commentaires positifs',
  '_xbb._gzrs': 'Suivre',
  '_xbb._gzrsan': 'Suivre',
  '_xbb._ygzrsan': 'Déjà suivi',
  '_xbb._fsxx': 'Message',
  '_xbb._myrpl': "Aucun commentaire pour l'instant",
  '_xbb._llgd': 'Voir plus',
  '_xbb._pdcl': 'Promesse',
  '_xbb._myf': 'Livraison gratuite',
  '_xbb._sdsj': 'Livré dans environ 5 à 10',
  '_xbb._ksjh': 'Livraison rapide',
  '_xbb._bgdstk': 'Remboursement pour colis perdu',
  '_xbb._shbgtk': "Si l'article est endommagé, remboursement",
  '_xbb._s30gtk': 'Remboursements si non expédié sous 30 jours',
  '_xbb._aqyys': 'Sécurité et confidentialité',
  '_xbb._wjtrxk': 'Paiement sécurisé : Nous ne partagerons vos informations personnelles avec aucun tiers sans votre consentement. ',
  '_xbb._sfgrxx': 'Détails personnels sécurisés : nous protégeons votre vie privée et gardons vos informations personnelles en sécurité. ',
  '_xbb._sl': 'Quantité',
  '_xbb._kcld': 'Inventaire',

  '_xbb._sjrz': 'Marchand installé',
  '_xbb._hezjh': "Notre programme de partenariat vous offre une gamme complète de supports et de services marketing, et notre équipe de service client vous fournira une assistance et des conseils professionnels pour vous aider à optimiser l'affichage de vos produits et votre stratégie de vente. Rejoignez-nous maintenant ! Créons de plus grandes opportunités commerciales et grandissons ensemble ! ",
  '_xbb._xsje': 'Informations commerciales',
  '_xbb._rgsqjs': 'Si vous êtes déjà vendeur',
  '_xbb._qni': "S'il vous plaît",
  '_xbb._djdl': 'Cliquez pour vous connecter',
  '_xbb._dplog': 'Logo du magasin',
  '_xbb._dpmc': 'Nom du magasin',
  '_xbb._srdpmc': 'Veuillez saisir le nom du magasin',
  '_xbb._zjhm': "numéro d'identification",
  '_xbb._srzjhm': "Veuillez saisir le numéro d'identification",
  '_xbb._zsxm': 'vrai nom',
  '_xbb._srzsxm': 'Veuillez entrer votre vrai nom',
  '_xbb._srgj': 'Pays',
  '_xbb._srsrgj': 'Veuillez entrer le pays',
  '_xbb._zjz': "Photo d'identité",
  '_xbb._zjzm': 'Recto du document',
  '_xbb._zjfm': 'Verso du certificat',
  '_xbb._sczjz': "Conserver la photo d'identité",
  '_xbb._pssl': 'Exemple de photographie',
  '_xbb._dlyx': 'E-mail de connexion',
  '_xbb._srdlyx': 'Veuillez saisir votre email de connexion',
  '_xbb._dlmm': 'Mot de passe de connexion',
  '_xbb._srdlmm': 'Veuillez saisir votre mot de passe de connexion',
  '_xbb._qrmm': 'Confirmer le mot de passe',
  '_xbb._srqrmm': 'Veuillez saisir le mot de passe de confirmation',
  '_xbb._yqm': "code d'invitation",
  '_xbb._sryqm': "Entrez le code d'invitation",
  '_xbb._yxyzm': "Code de vérification de l'e-mail",
  '_xbb._sryzm': "Entrez le code de vérification de l'e-mail",
  '_xbb._yydbty': "J'ai lu et accepté",
  '_xbb._rzxy': 'Accord de règlement',

  '_xbb._qgxty': "Veuillez lire attentivement l'accord de règlement et cocher la case pour accepter",
  '_xbb._sryqmb': "Veuillez saisir le code d'invitation",

  '_xbb._ddpl': 'Commentaires sur la commande',
  '_xbb._dsppl': 'Commentaires sur les produits',
  '_xbb._dddpl': 'Commentaire sur la commande',

  '_xhd._sdj' : 'Noël'
}
