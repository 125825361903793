export default {
  'nav.account': 'Trung tâm cá nhân',
  'nav.language': 'Tiếng Trung Giản thể',
  'signup.success': 'Đăng ký thành công',

  //lỗi
  'contact.empty': 'Không được để trống thông tin liên hệ',
  'phone.country.empty': 'Không được để trống tiền tố điện thoại',
  'phone.empty': 'Không thể để trống điện thoại',
  'email.valid': 'Vui lòng nhập đúng email',

  // căn cứ
  'base.success': 'thành công',
  'base.save': 'Lưu',
  'base.slide': 'Vui lòng kéo thanh trượt để xác minh',
  'base.send.code': 'Gửi mã xác nhận',
  'base.send.time': '({time}) gửi lại mã xác minh',
  'base.send.success': 'Gửi thành công',
  'base.view.more': 'Xem thêm',
  'base.Language': 'Ngôn ngữ',
  'base.previous': 'Trang trước',
  'base.next': 'Trang tiếp theo',
  'base.total.page': 'Tổng cộng {page} trang',
  'base.go.to.page': 'Đi tới trang',
  'base.go': 'đi tới',
  'base.followers': 'Người theo dõi',
  'base.follow': 'Theo dõi',
  'base.following': 'Đã theo dõi',
  'base.visit.store': 'Duyệt qua cửa hàng',
  'base.contact': 'Liên hệ với chúng tôi',
  'base.delivery': 'Thời gian giao hàng ước tính: 1-7 ngày',
  'base.search': 'Tìm kiếm',
  'base.search.text': 'Nhập từ khóa tìm kiếm',
  'base.server': 'Dịch vụ khách hàng trực tuyến',

  //mục lục
  'text.categories.title': 'Danh mục sản phẩm',
  'text.welcome': 'Chào mừng đến với mua sắm!',
  'text.wishlist': 'Danh sách thích',
  'text.account': 'Trung tâm cá nhân',
  'text.orders': 'Đơn hàng',
  'text.messages': 'Tin nhắn',
  'text.suggest.title': 'Ưu đãi độc quyền',
  'text.suggest.content': 'Chỉ dành cho thành viên cao cấp toàn cầu mới!',
  'text.super.deals': 'Sản phẩm hàng đầu, giá không tưởng.',

  //đăng ký
  'signup.register': 'Đăng ký',
  'signup.signin': 'Đăng nhập',
  'signup.store.signin': 'Đăng nhập doanh nghiệp',
  'signup.sign.out': 'Đăng xuất',
  'signup.email': 'Địa chỉ email',
  'signup.password': 'Mật khẩu',
  'signup.qr.password': 'Xác nhận mật khẩu',
  'signup.confirm.password': 'Xác nhận mật khẩu',
  'signup.forgot.password': 'Quên mật khẩu',
  'signup.invitation': 'Mã thư mời',
  'signup.char': 'Nhân vật',
  'signup.contains': 'Chứa số, chữ cái hoặc ký hiệu',
  'signup.qr.contains': 'Hai mật khẩu không nhất quán',
  'signup.create.account': 'Tạo tài khoản',
  'signup.agree': 'Tạo một tài khoản, bạn đồng ý',
  'signup.member.agreement': 'Thỏa thuận thành viên',
  'signup.and': 'và',
  'signup.privacy.policy': 'Chính sách bảo mật',
  'signup.email.code': 'Email xác minh',
  'signup.last.step': 'Bước cuối cùng',
  'signup.send.email': 'Vui lòng nhập mã gồm 4 chữ số được gửi tới {email}',
  'signup.modify.email': 'Sửa đổi email',
  'signup.verify.email': 'Xác minh email',
  'signup.have.store': 'Có tài khoản người bán',
  'signup.goto.signin': 'Đăng nhập doanh nghiệp',
  'signup.no.store': 'Không có tài khoản người bán',
  'signup.goto.store': 'Người bán đăng ký',
  'signup.next': 'Tiếp theo',
  'signup.your.email': 'email của bạn',
  'signup.code.text': 'Mã xác nhận',
  'signup.submit.signup': 'Đăng ký ngay bây giờ',
  'signup.smrz': 'Xác thực tên thật',
  'signup.derb': 'Bước thứ hai',
  'signup.qsrxm': 'Vui lòng nhập tên tài liệu',
  'signup.qsrhm': 'Vui lòng nhập số ID',

  //quên
  'forgot.title': 'Đặt lại mật khẩu',
  'forgot.btn.check': 'Xác minh email',
  'forgot.reset.now': 'Đặt lại ngay bây giờ',

  //cửa hàng
  'store.info.open': 'Mở {y} tuần',

  // chân trang
  'footer.great.value': 'Giá trị lớn',
  'footer.great.value.desc': 'Chúng tôi đưa ra mức giá cạnh tranh cho hơn 100 triệu mặt hàng.',
  'footer.shopping': 'Mua sắm Toàn cầu',
  'footer.shopping.desc': 'Chúng tôi giao hàng đến hơn 200 quốc gia và khu vực, và trang web của chúng tôi có sẵn bằng 7 ngôn ngữ.',
  'footer.safe.payment': 'Thanh toán An toàn',
  'footer.safe.payment.desc': 'Thanh toán bằng các phương thức thanh toán an toàn và phổ biến nhất trên thế giới.',
  'footer.shop.with.trust': 'Tự tin mua sắm',
  'footer.shop.with.trust.desc': 'Chính sách bảo vệ người mua của chúng tôi bao gồm toàn bộ quá trình mua hàng của bạn.',
  'footer.help.center': 'Trung tâm trợ giúp',
  'footer.help.center.desc': 'Hỗ trợ trong mọi điều kiện thời tiết để tạo trải nghiệm mua sắm suôn sẻ.',
  'footer.terms.conditions': 'Điều khoản và Điều kiện',
  'footer.return.policy': 'Chính sách hoàn trả',
  'footer.support.policy': 'Chính sách hỗ trợ',
  'footer.privacy.policy': 'Chính sách Bảo mật',
  'footer.be.seller': 'Trở thành người bán',
  'footer.apply.now': 'Đăng ký ngay bây giờ',
  'footer.stay.connected': 'Duy trì kết nối',

  'footer.about.us': 'Giới thiệu về chúng tôi',
  'footer.about.company': 'Hồ sơ Công ty',
  'footer.about.video': 'Video giới thiệu',
  'footer.contact': 'Thông tin liên hệ',

  'footer.my.account': 'Tài khoản của tôi',
  'footer.my.logout': 'Đăng xuất',
  'footer.my.order': 'Lịch sử Đặt hàng',
  'footer.my.wish': 'Danh sách mong muốn của tôi',
  'footer.my.join': 'Trở thành đối tác thành viên',
  'footer.email': 'Email',
  'footer.gfemail': 'Email chính thức',
  'footer.fwemail': 'Email dịch vụ',
  'footer.address': 'Địa chỉ',

  'apply.success': 'Đăng ký thành công',
  'apply.success.desc': 'Đã đăng ký thành công, chuyển đến tài khoản đăng nhập',

  // Thể loại
  'category.title': 'Các danh mục liên quan',
  'category.all': 'Tất cả danh mục',

  //chi tiết
  'detail.store.home': 'Cửa hàng Trang chủ',
  'detail.sale.items': 'Danh sách sản phẩm',
  'detail.recommend': 'Đề xuất',
  'detail.orders': 'Bán hàng',
  'detail.quantity': 'Số lượng',
  'detail.pieces.available': 'Có sẵn',
  'detail.delivery': 'Giao hàng',
  'detail.free.shipping': 'Giao hàng miễn phí',
  'detail.estimated.delivery': 'Ước tính hàng đến',
  'detail.days': 'ngày',
  'detail.buy.now': 'Mua',
  'detail.add.to.cart': 'Thêm vào giỏ hàng',
  'detail.buyer.protection': 'Bảo vệ người mua',
  'detail.money.guarantee': 'Đảm bảo hoàn lại tiền',
  'detail.refund.desc': 'Được hoàn lại toàn bộ tiền nếu mặt hàng không đúng như mô tả hoặc không được giao',
  'detail.description': 'Mô tả sản phẩm',
  'detail.customer.reviews': 'Nhận xét của khách hàng',
  'detail.specations': 'Thông số kỹ thuật sản phẩm',
  'detail.top.selling.products': 'Sản phẩm bán chạy nhất',
  'detail.recommended.for.you': 'Được đề xuất cho bạn',
  'detail.sold': 'Bán hàng',
  'detail.receipt': 'Xác nhận đã nhận',
  'detail.receipt.title': 'Xác nhận đã nhận đơn đặt hàng? ',
  'detail.receipt.content': 'Sau khi xác nhận, lệnh giao dịch đã hoàn tất',
  'detail.comment': 'Bình luận',
  'detail.refund.title': 'Xác nhận đơn xin hoàn tiền',
  'detail.refund.content': 'Sau khi xác nhận, đơn hàng sẽ được hoàn tiền',
  'detail.sqtk': 'Đăng ký hoàn tiền',

  //xác nhận
  'confirm.shipping.address': 'Địa chỉ người nhận',
  'confirm.change': 'Thay đổi',
  'confirm.payment.methods': 'Phương thức thanh toán',
  'confirm.summary': 'Dàn xếp',
  'confirm.total.item.costs': 'Tổng số mặt hàng',
  'confirm.total': 'Tổng số',
  'confirm.checkout': 'Chuyển đến trang thanh toán',
  'confirm.place.order': 'Đặt hàng ngay bây giờ',
  'confirm.pay.now': 'Thanh toán ngay',
  'confirm.order.desc': "Sau khi nhấp vào 'Đặt hàng', tôi xác nhận rằng tôi đã đọc và xác nhận",
  'confirm.order.policy': 'Tất cả các điều khoản và chính sách',
  'confirm.payment': 'Global Shopping đảm bảo an toàn cho thông tin và thanh toán của bạn',

  // Địa chỉ
  'address.title': 'Địa chỉ biên nhận',
  'địa chỉ.default': 'mặc định',
  'address.edit': 'Chỉnh sửa',
  'address.delete': 'Xóa',
  'address.new': 'Tạo địa chỉ mới',
  'address.contact': 'Liên hệ',
  'address.address': 'Địa chỉ',
  'address.phone': 'Số điện thoại',
  'address.set.default': 'Đặt mặc định',
  'address.confirm': 'Xác nhận',
  'address.cancel': 'Hủy bỏ',
  'address.del.title': 'Xác nhận xóa địa chỉ?',
  'address.del.content': 'Bạn có chắc chắn muốn xóa địa chỉ giao hàng này không?',

  'thanh toán.method': 'Phương thức thanh toán',

  // Giỏ hàng
  'shopping.title': 'Giỏ hàng',
  'shopping.back.buy': 'Quay lại mua sắm đặc biệt',
  'shopping.start.shopping': 'Bắt ​​đầu mua sắm',

  // thành viên
  'member.account': 'Trung tâm cá nhân',
  'member.overview': 'Tổng quan',
  'member.orders': 'Đơn hàng',
  'thành viên.thanh toán': 'Ví của tôi',
  'member.address': 'Địa chỉ biên nhận',
  'member.wishlist': 'Danh sách thích',
  'member.followlist': 'Danh sách theo dõi',
  'member.message.center': 'Trung tâm Thông báo',
  'member.setting': 'Cài đặt cá nhân',
  'member.shop.info': 'Thông tin cửa hàng',
  'member.shop.setting': 'Cài đặt cửa hàng',
  'member.order.notify': 'Thông báo đơn hàng mới',
  'member.order.tips': 'Bằng cách gửi email đến email tài khoản người bán',

  // thành viên.đặt hàng
  'member.order.title': 'Đơn hàng của tôi',
  'member.order.view.all': 'Xem tất cả',
  'member.order.all': 'Tất cả',
  'member.order.unpaid': 'chưa thanh toán',
  'member.order.paid': 'Đã thanh toán',
  'member.order.procurement': 'được mua',
  'member.order.seller.paid': 'Đã mua',
  'member.order.processing': 'được xử lý',
  'member.order.shipped': 'đã vận chuyển',
  'member.order.return': 'Trở lại',
  'member.order.completed': 'Đã hoàn thành',
  'member.order.refund': 'Đã hoàn tiền',
  'thành viên.order.all.time': 'tất cả',
  'member.order.empty': 'Chưa có đơn đặt hàng',
  'thành viên.order.date': 'ngày',
  'member.order.purchase.date': 'Mua hàng',
  'member.order.cpoy': 'Sao chép',
  'member.order.id': 'Đặt hàng',
  'member.order.detail': 'Chi tiết Đơn hàng',
  'member.order.logistics': 'Thông tin hậu cần',

  // thành viên.thanh toán
  'member.payment.title': 'Ví của tôi',
  'member.wallet.balance': 'số dư trong ví',
  'member.crypto.recharge': 'Nạp tiền',
  'member.crypto.withdrawal': 'Rút tiền',
  'member.crypto.bank': 'Thẻ ngân hàng',
  'member.wallet.record': 'kỷ lục gửi tiền xu',
  'member.bankcard.record': 'Hồ sơ thẻ ngân hàng',
  'member.withdrawal.record': 'Hồ sơ rút tiền',
  'member. income.record': 'hồ sơ thu nhập',
  'member.transaction.record': 'Bản ghi giao dịch',
  'member.wallet.freeze': 'Khoản đóng băng',
  'member.wallet.profit': 'Thu nhập ước tính',

  //nạp điện
  'recharge.currency': 'Tiền tệ',
  'recharge.protocol': 'Giao thức',
  'recharge.qrcode': 'mã QR',
  'recharge.address': 'Địa chỉ ví',
  'recharge.copy': 'Sao chép địa chỉ',
  'recharge.ok.text': 'Xác nhận',
  'recharge.cancel.text': 'Hủy',

  //ngân hàng
  'bank.recharge.title': 'Nạp tiền vào thẻ ngân hàng',
  'bank.title': 'Tên ngân hàng',
  'bank.name': 'tên',
  'bank.account': 'Tài khoản',
  'bank.routing': 'Định tuyến',
  'bank.code': 'mã',
  'bank.bankname': 'Tên ngân hàng',
  'bank.bankaddress': 'Địa chỉ ngân hàng',

  'bank.ok.text': 'Tiếp theo',
  'bank.ok.prev': 'Bước trước',
  'bank.submit': 'Gửi ngay bây giờ',
  'bank.amount': 'Số tiền nạp',
  'bank.amount.desc': 'Vui lòng nhập số tiền nạp',
  'bank.type': 'loại',
  'bank.usd': 'USD',
  'bank.eur': 'Euro',
  'bank.receipt.number': 'Số biên nhận',
  'bank.receipt.number.desc': 'Vui lòng nhập số biên nhận giao dịch',
  'bank.credential.picture': 'Ảnh xác thực',
  'bank.credential.picture.desc': 'Vui lòng tải lên ảnh thông tin xác thực',
  'bank.remark': 'Ghi chú',
  'bank.upload': 'Tải ảnh lên',
  'bank.text.title': 'Ghi chú',
  'bank.text.t1': 'Chuyển khoản không cần ghi chú, để trống',
  'bank.text.t2': 'Chọn Hồng Kông làm quốc gia của hộ gia đình Hồng Kông, không thêm Trung Quốc',
  'bank.text.t3': 'Chuyển tiền đến Hoa Kỳ, vào các ngày trong tuần, chuyển tiền đến Châu Âu trước 3:00 chiều, chuyển tiền có sẵn trong giờ làm việc của ngân hàng!',
  'bank.text.t4': 'Thời gian thanh toán là T+1, và lần thanh toán gần nhất là T+3 ở Châu Âu',
  'bank.text.t5': 'Thông báo cho nền tảng trước khi chuyển tiền để xác nhận xem tài khoản có khả dụng hay không. Nếu tài khoản bị đóng, sẽ không có khoản bồi thường nào được trả.',
  'bank.text.t6': 'Gửi tiền qua thẻ ngân hàng, tất cả thông tin dịch vụ khách hàng sẽ được áp dụng.',

  //danh sách ngân hàng
  'bank.list.title': 'Tên ngân hàng',
  'bank.list.code': 'Mã ngân hàng',
  'bank.list.amount': 'Số tiền chuyển',
  'bank.list.number': 'Số biên nhận',
  'bank.list.img': 'Hình ảnh phiếu thưởng',
  'bank.list.status': 'Trạng thái',
  'bank.list.time': 'thời gian',
  'bank.list.status1': 'Đang xem xét',
  'bank.list.status2': 'Đạt',
  'bank.list.status3': 'Bị từ chối',

  // Rút tiền
  'drawal.address.desc': 'Vui lòng nhập địa chỉ ví!',
  'drawal.number': 'Số',
  'drawal.real.number': 'thực tế đến',
  'drawal.number.desc': 'Vui lòng nhập số tiền rút!',
  'rút tiền.btn.all': 'tất cả',
  'drawal.balance': 'Số dư',
  'rút tiền. hoa hồng': 'hoa hồng',
  'drawal.actual.amount': 'Đã tính',
  'rút tiền.notice': 'Nhắc nhở',
  'drawal.notice.text': 'Trước khi chuyển, vui lòng xác nhận rằng thông tin địa chỉ nhận là chính xác. Sau khi tài sản được chuyển đi, chúng không thể được trả lại.',
  'withdrawal.notice.content': '{name}({cp_name}) hoa hồng: giá trị thị trường hiện tại {fee_rate}%/pen, tiêu chuẩn tối thiểu: {fee_min} {name}/pen',
  'rút tiền.submit': 'Gửi',
  'drawal.choice': 'Chọn một loại tiền điện tử',
  'drawal.yzm': 'Mã xác minh',
  'drawal.fs': 'Gửi',
  'drawal.qsryzm': 'Vui lòng nhập mã xác minh email',

  // nhận lại
  'recive.method': 'Giao thức',
  'recive.amount': 'Số tiền',
  'recive.address': 'Địa chỉ',
  'recive.date': 'thời gian',
  'recive.status': 'Trạng thái',
  'recive.create.at': 'Thời gian giao dịch',
  'recive.type': 'loại',
  'recive.befor': 'trước giao dịch',
  'recive.balance': 'Số dư',
  'recive.freeze': 'Đóng băng',
  'recive.review': 'Đánh giá',
  'recive.success': 'Đạt',
  'recive.reject': 'Bị từ chối',

  // Quảng cáo
  'advertise.title': 'Trung tâm Quảng cáo',
  'advertise.shop.title': 'Khuyến mãi cửa hàng',
  'advertise.shop.status': 'Trạng thái',
  'advertise.shop.expired': 'Đã hết hạn',
  'advertise.shop.promotion': 'Quảng cáo',
  'advertise.shop.expire.date': 'Ngày hết hạn',
  'advertise.shop.renew': 'Gia hạn quảng cáo',
  'advertise.shop.payable': 'Số tiền phải trả',
  'advertise.shop.explanation': 'Giải thích quảng cáo',
  'advertise.shop.text': 'Tham gia quảng cáo nền tảng, tăng khả năng hiển thị cửa hàng và thúc đẩy các đơn đặt hàng giao dịch',
  'advertise.shop.paynow': 'Gia hạn ngay',
  'advertise.shop.modal.title': 'Xác nhận thanh toán',
  'advertise.shop.modal.desc': 'Đã xác nhận thanh toán phí khuyến mãi',
  'advertise.shop.modal.btn': 'Xác nhận Thanh toán',

  // Danh sách mong muốn
  'wishlist.title': 'Danh sách mong muốn',
  'wishlist.delete': 'Xóa',
  'wishlist.orders': 'Bán hàng',

  // Danh sách theo dõi
  'followlist.title': 'Danh sách theo dõi',
  'followlist.delete': 'Xóa',
  'followlist.follow': 'Theo dõi',

  // cửa hàng
  'store.dashboard': 'Bảng điều khiển',
  'store.products': 'Sản phẩm',
  'store.products.list': 'Danh sách sản phẩm',
  'store.products.reviews': 'Đánh giá sản phẩm',
  'store.orders': 'Đơn hàng',
  'store.wallet': 'Ví tiền',
  'store.message': 'Trung tâm tin nhắn',
  'store.setting': 'cài đặt',
  'store.order.total.profit': 'Tổng thu nhập hoạt động ước tính',

  //bảng điều khiển
  'dashboard.store.hour.views': 'Lượt truy cập trong thời gian thực',
  'dashboard.store.today.views': 'Lượt truy cập hôm nay',
  'dashboard.product.total': 'Tổng sản phẩm',
  'dashboard.product.today': 'Hôm nay mới',
  'dashboard.order.total': 'Tổng đơn hàng',
  'dashboard.sales.total': 'Doanh thu ước tính',
  'dashboard.sales.real': 'Doanh số thực',
  'dashboard.sales.pay': 'Tổng số tiền xuất chi',
  'dashboard.sales.profit': 'Tổng lợi nhuận',
  'dashboard.commission.total': 'Tổng doanh thu',
  'dashboard.commission.today': 'Thu nhập hôm nay',
  'dashboard.order.sales': 'Bán hàng hóa',
  'dashboard.sales.list': 'Xếp hạng doanh số',
  'dashboard.goods.cate.rate': 'Tỷ lệ phân loại sản phẩm',
  'dashboard.goods.wish': 'Giống như xếp hạng sản phẩm',
  'dashboard.january': 'Tháng Giêng',
  'dashboard.february': 'Tháng hai',
  'dashboard.march': 'Tháng ba',
  'dashboard.april': 'Tháng Tư',
  'dashboard.may': 'May',
  'dashboard.june': 'Tháng sáu',
  'dashboard.july': 'Tháng 7',
  'dashboard.august': 'Tháng 8',
  'dashboard.september': 'Tháng 9',
  'dashboard.october': 'Tháng 10',
  'dashboard.november': 'Tháng 11',
  'dashboard.december': 'Tháng 12',

  // các sản phẩm
  'products.add.new': 'Thêm sản phẩm mới',
  'products.add.from.warehouse': 'Thêm sản phẩm từ kho hàng',
  'products.delete': 'Xóa',
  'products.add': 'Thêm',
  'products.table.img': 'hình ảnh',
  'products.table.name': 'tên sản phẩm',
  'products.table.category': 'Danh mục',
  'products.table.wish': 'thích',
  'products.table.stock': 'cổ phiếu',
  'products.table.price': 'Giá',
  'products.table.profit': 'Lợi nhuận',
  'products.table.action': 'Hành động',
  'products.search.category': 'Tìm kiếm theo danh mục',
  'products.back.product': 'Quay lại danh sách sản phẩm',
  'products.total': 'Tổng số hàng hóa',
  'products.yes': 'Có',
  'products.no': 'Hủy',
  'products.batch.add': 'Tăng hàng loạt',
  'products.ask.add': 'Bạn có chắc chắn muốn thêm sản phẩm không?',
  'products.batch.delete': 'Xóa hàng loạt',
  'products.ask.delete': 'Bạn có chắc chắn muốn xóa sản phẩm không?',
  'products.top': 'Hàng đầu',
  'products.syzd': 'Đầu trang chủ',
  'products.zdwz': 'Vị trí hàng đầu',
  'products.t1': 'Khuyến mãi',
  'products.t2': 'Ưu tiên',
  'products.t3': 'Cửa hàng',
  'products.t4': 'không',

  // đánh giá
  'reviews.title': 'Đánh giá sản phẩm',
  'reviews.product.name': 'Sản phẩm',
  'reviews.user.name': 'Người dùng',
  'reviews.star': 'Xếp hạng',
  'reviews.comment': 'Nội dung bình luận',
  'reviews.sku': 'Thông số sản phẩm',
  'review.imgs': 'Bản đồ',
  'reviews.created': 'Ngày',

  // cửa hàng.đặt hàng
  'store.order.purchase': 'Mua ngay',
  'store.order.purchase.desc': 'Bạn có chắc chắn muốn thanh toán cho sản phẩm này không?',
  'store.order.purchase.yes': 'Thanh toán ngay',
  'store.order.purchase.no': 'Hủy',
  'store.order.desc': 'Việc mua đơn hàng này yêu cầu thanh toán, và bạn sẽ nhận được lợi ích sau khi đơn hàng hoàn tất. ',
  'store.order.no': 'Số đơn đặt hàng',
  'store.order.number': 'Số lượng',
  'store.order.buyer': 'Người mua',
  'store.order.total': 'Tổng số tiền',
  'store.order.will.earning': 'Thu nhập',
  'store.order.profit': 'Lợi nhuận',
  'store.order.dividends': 'Cổ tức',
  'store.order.payment.status': 'Trạng thái Thanh toán',
  'store.order.seller.buy.status': 'Trạng thái mua hàng',
  'store.order.status': 'Trạng thái Đơn hàng',
  'store.order.date': 'ngày đặt hàng',
  'store.order.purchase.date': 'Ngày mua',
  'store.order.action': 'Hoạt động',
  'store.order.purchase.amount': 'Số lượng mua hàng',

  //thu nhập
  ' income.create.at': 'Kỷ lục thời gian',
  ' income.order.sn': 'Số đơn đặt hàng',
  ' income.realpay': 'Số tiền đặt hàng',
  'thu nhập.lợi nhuận': 'thu nhập',

  //Cài đặt
  'setting.avatar': 'Hình đại diện doanh nghiệp',
  'setting.upload': 'Tải lên',
  'setting.shop.name': 'Tên cửa hàng',
  'setting.shop.phone': 'Số điện thoại liên hệ',
  'setting.shop.address': 'Địa chỉ cửa hàng',
  'setting.shop.save': 'Lưu',
  'setting.upload.pic': 'Tải ảnh lên',
  'setting.send.pic': 'Gửi ảnh',

  // người bán
  'beseller.title': 'Đăng ký làm thương gia',
  'beseller.account': 'Thông tin tài khoản',
  'beseller.store': 'Thông tin cửa hàng',
  'beseller.store.name': 'Tên cửa hàng',
  'beseller.store.address': 'Địa chỉ cửa hàng',

  //savehome
  'store.home.title': 'Cài đặt Trang chủ',
  'store.home.topimg': 'hình ảnh trên cùng',
  'store.home.banner': 'Hình ảnh băng chuyền',
  'store.home.up3': 'Ghi chú: Ít nhất 3 lần tải lên',
  'store.upload.more': 'Tải thêm hình ảnh lên',
  'store.home.columns': 'Cột',
  'store.home.bgimg': 'Hình nền',
  'store.goods.remark': 'Ghi chú: 5 mặt hàng mỗi dòng, tối đa 10 mặt hàng mỗi đĩa',
  'store.home.select': 'Chọn sản phẩm',
  'store.home.add': 'Thêm cột',

  //thông tin cá nhân
  'setting.update.user': 'Thông tin cá nhân',
  'setting.update.info': 'Sửa thông tin',
  'setting.user.avatar': 'Hình đại diện người dùng',
  'setting.user.nickname': 'Biệt danh người dùng',
  'setting.user.nickname.desc': 'Vui lòng nhập tên người dùng',

  'setting.safe.title': 'Thông tin An toàn',
  'setting.user.passwd': 'Thay đổi mật khẩu',
  'setting.passwd.title1': 'Mật khẩu cũ',
  'setting.passwd.desc1': 'Vui lòng nhập mật khẩu cũ',
  'setting.passwd.title2': 'Mật khẩu mới',
  'setting.passwd.desc2': 'Vui lòng nhập mật khẩu mới',
  'setting.passwd.title3': 'Xác nhận mật khẩu',
  'setting.passwd.desc3': 'Vui lòng nhập mật khẩu xác nhận',
  'setting.invitation': 'Mời bạn bè',
  'setting.copy': 'Sao chép',

  'adv.add.products': 'Thêm sản phẩm khuyến mại',
  'adv.list.title': 'Quảng cáo sản phẩm',
  'adv.type': 'Loại Quảng cáo',
  'adv.begin.time': 'Thời gian khuyến mãi',
  'adv.end.time': 'Thời gian kết thúc',
  'adv.status': 'Trạng thái',
  'adv.status.s1': 'Quảng cáo',
  'adv.status.s2': 'Kết thúc',
  'adv.add.back': 'Quay lại danh sách được thăng hạng',
  'adv.recharge': 'Nạp điểm khuyến mãi',
  'adv.select.goods': 'Chọn hàng hóa',
  'adv.select.btn': 'Sản phẩm được Quảng cáo',
  'adv.recharge.title': 'Nạp điểm Khuyến mãi',
  'adv.recharge.balance': 'Số dư điểm',
  'adv.điểm': 'điểm',
  'adv.point.rate': 'Tỷ lệ điểm',
  'adv.recharge.amount': 'Số tiền nạp lại',
  'adv.recharge.input.amount': 'Nhập số tiền nạp lại',
  'adv.select.mod': 'Chọn bảng điều khiển',
  'adv.mod1': 'Cột được đề xuất',
  'adv.mod2': 'Cột ưu tiên',
  'adv.mod3': 'Cột bán chạy',
  'adv.mod4': 'Danh sách xuất sắc',
  'adv.mod5': 'Danh sách phân loại',
  'adv.mod6': 'Khuyến mãi chi tiết',
  'adv.ge': 'Số lượng',
  'adv.xs': 'giờ',
  'adv.xzbk': 'Chọn tấm',
  'adv.syme': 'Hạn ngạch còn lại',
  'adv.tgsc': 'Thời gian khuyến mãi',
  'adv.tgjg': 'Giá khuyến mãi',
  'adv.syye': 'Số dư hiện tại',
  'adv.tgye': 'Số dư khuyến mãi',
  'adv.yj1': 'được mong đợi',
  'adv.yj2': 'Bắt ​​đầu quảng cáo',
  'adv.pay.point': 'Điểm tiêu dùng',

  'wallet.ykcsxf': 'Phí xử lý đã được khấu trừ',
  'wallet.sxf': 'Phí dịch vụ',
  'wallet.zxje': 'Số tiền tối thiểu',
  'wallet.24hxe': 'Giới hạn 24 giờ',
  'wallet.desc.text': 'Trong mọi trường hợp, quan chức sẽ không yêu cầu bạn chuyển tiền vào tài khoản, cũng như không yêu cầu bạn cung cấp mã xác minh. Không tham gia vào các hoạt động bất hợp pháp như mua hàng thay cho người khác, rửa tiền và gây quỹ bất hợp pháp và hãy cẩn thận với gian lận trực tuyến',
  'wallet.cunbi.text': 'Bạn chỉ có thể gửi {name} vào địa chỉ này, các tài sản khác sẽ không được lấy lại',

  'credit.title': 'Giới hạn tín dụng',
  'credit.ed': 'Hạn ngạch',
  'credit.hk': 'Trả nợ',
  'credit.kyye': 'Số dư khả dụng',
  'credit.dqqk': 'Khoản nợ hiện tại',
  'credit.hkje': 'Số tiền hoàn trả',
  'credit.hkje.desc': 'Vui lòng nhập số tiền hoàn trả',
  'credit.cgje': 'Số tiền mua hàng',
  'credit.qrz': 'Chuyển đến phần xác thực',
  'credit.srxyj': 'Nhập số tiền thanh toán tín dụng',

  'store.gzl': 'Số lượng chú ý',
  'store.wxz': 'Không giới hạn',

  'auth.smrz': 'Xác thực tên thật',
  'auth.rzlx': 'Loại',
  'auth.sfzm': 'Mặt trước thẻ căn cước',
  'auth.sffm': 'Mặt sau của chứng minh nhân dân',
  'auth.zsxm': 'tên thật',
  'auth.zjhm': 'Số tài liệu',
  'auth.yyzz': 'Giấy phép kinh doanh',
  'auth.gsmc': 'Tên công ty',
  'auth.shz': 'Kiểm tra',
  'auth.ytg': 'Đã đạt',
  'auth.ybh': 'Bị từ chối',
  'auth.zt': 'trạng thái',
  'auth.gr': 'Cá nhân',
  'auth.gs': 'Doanh nghiệp',
  'auth.ljtj': 'Đăng ký xác thực',
  'auth.wrz': 'Chưa xác thực',

  'credit.qts': 'Xem mô tả',
  'store.spss': 'Giới hạn trên của hàng hóa',
  'store.dpfh': 'Lưu trữ cổ tức',
  'store.qydj': 'Mức vốn chủ sở hữu',
  'store.level': 'cấp độ',
  'store.jian': 'miếng',

  'order.notify.email': 'Do kiểm soát rủi ro email, các email nhắc nhở đặt hàng nền tảng sẽ bị chặn thường xuyên. Để nhận được chúng kịp thời, bạn nên thêm {e} vào danh sách trắng email đăng nhập',

  'setting.sjxx': 'Thông tin doanh nghiệp',
  'setting.smrz': 'Xác thực tên thật',
  'setting.aqzx': 'Trung tâm bảo mật',
  'setting.tzxx': 'Thông tin thông báo',
  'setting.dpzx': 'Trang chủ trang trí',
  'setting.sjqy': 'Lợi ích kinh doanh',
  'setting.sjdjqy': 'Mô tả lợi ích cấp doanh nghiệp',

  'setting.fhbky': 'Không có cổ tức hiện tại',
  'setting.spgzyzy': 'Lưu trữ quy tắc và hướng dẫn',

  'desc.dqqy': 'Vốn chủ sở hữu hiện tại',
  'desc.xjqy': 'Lợi ích cấp dưới',
  'desc.sjqy_0': 'Lưu trữ giới hạn tín dụng',
  'desc.sjqy_1': 'Lưu trữ cổ tức',
  'desc.sjqy_2': 'Số lượng sản phẩm có thể xuất bản',

  'share.yqlb': 'Danh sách lời mời',
  'share.zmj': 'Tổng số người bán',
  'share.zdd': 'Tổng đơn hàng',
  'share.zsy': 'Tổng thu nhập từ môi giới',
  'share.wcl': 'chưa xử lý',
  'share.ycl': 'Đã xử lý',
  'share.ywc': 'Đã hoàn thành',
  'share.dpmc': 'Tên cửa hàng',
  'share.ddl': 'Số lượng đặt hàng',
  'share.jjsy': 'Thu nhập môi giới',
  'share.zts': 'Tổng số',

  'chat.state': 'trạng thái',
  'chat.zx': 'Trực tuyến',
  'chat.ljz': 'Đang kết nối',

  'bind.wallet.title': 'Địa chỉ rút tiền',
  'bind.address.title': 'Thêm địa chỉ',
  'bind.bj': 'Chỉnh sửa',
  'bind.sc': 'Xóa',
  'bind.qrsc': 'Bạn có chắc chắn muốn xóa địa chỉ này không?',
  'bind.qbdz': 'Địa chỉ ví',
  'bind.tjsj': 'Thêm thời gian',
  'bind.cz': 'Hoạt động',
  'drawal.address.choice': 'Vui lòng chọn địa chỉ rút tiền!',

  'order.sxsj': 'Thời gian hiệu lực',
  'order.ljsj': 'có hiệu lực ngay lập tức',
  'order.dssj': 'Hẹn giờ có hiệu lực',

  'store.st.zt': 'Trạng thái cửa hàng',
  'store.st.zc': 'bình thường',
  'store.st.zc.desc': 'Cửa hàng của bạn vẫn bình thường, hãy tiếp tục duy trì',
  'store.st.yc': 'Ngoại lệ',
  'store.st.yc.desc': 'Cửa hàng của bạn có một số đơn đặt hàng bất thường hoặc nhận được khiếu nại của khách hàng',
  'store.st.dj': 'Đóng băng',
  'store.st.dj.desc': 'Cửa hàng của bạn có rủi ro hoạt động nhất định và tiền bị đóng băng',
  'store.st.jy': 'đã tắt',
  'store.st.jy.desc': 'Cửa hàng của bạn đã vi phạm các thỏa thuận và quy tắc liên quan đến nền tảng và đã bị vô hiệu hóa',

  'task.sy': 'Trang chủ',
  'task.rwlbo': 'Danh sách nhiệm vụ',
  'task.rwxqo': 'Chi tiết nhiệm vụ',
  'task.ljcy': 'Tham gia ngay',
  'task.gdrw': 'Thêm nhiệm vụ',
  'task.rwlb': 'Danh sách các nhiệm vụ lấy',
  'task.qbrw': 'Tất cả tác vụ',
  'task.wcyd': 'Tôi đã tham gia',
  'task.qdz': 'Nhận đơn đặt hàng',
  'task.ywc': 'Đã hoàn thành',
  'task.djy': 'được giao dịch',
  'task.ddje': 'Số lượng đặt hàng',
  'task.cyrs': 'Số người tham gia',
  'task.ewsy': 'Các lợi ích bổ sung',
  'task.jzsj': 'Hạn chót',
  'task.ckxq': 'Xem chi tiết',
  'task.rwxq': 'Chi tiết nhiệm vụ',
  'task.cylb': 'Danh sách tham gia',
  'task.qdcyrw': 'Bạn có chắc chắn muốn tham gia vào nhiệm vụ đặt hàng này không?',
  'nhiệm vụ.sd': 'Có',
  'nhiệm vụ.bue': 'không',

  'task.wdsy': 'Doanh thu',
  'task.wdfh': 'Cổ tức',
  'task.wdqd': 'Nhận đơn đặt hàng',
  'task.dd.leixin': 'Loại Đơn đặt hàng',
  'task.dd.qdrw': 'Nhiệm vụ đặt hàng nhanh',
  'task.dd.ptdd': 'Thứ tự bình thường',

  'flow.lltg': 'Xúc tiến dòng chảy',
  'flow.gmll': 'Mua gói dữ liệu',
  'flow.dqtc': 'Gói hiện tại',
  'flow.tc1': 'Gói',
  'flow.sy2': 'còn lại',
  'flow.tian3': 'ngày',
  'flow.jlzs': 'Tổng số bản ghi',
  'flow.lltc': 'Gói lưu lượng',
  'flow.ll': 'flow',
  'flow.gmsj': 'Thời gian mua hàng',
  'flow.tcjg': 'Giá trọn gói',
  'flow.sjzf': 'thanh toán thực tế',
  'flow.tcsm': 'Mô tả gói',
  'flow.tcsm.sm': 'Mua gói này sẽ tăng mức độ hiển thị của cửa hàng và có thêm lưu lượng khách hàng. Nhằm có được nhiều đơn hàng và tăng doanh thu cho cửa hàng. ',

  'flow.tcmc': 'Tên gói',
  'flow.gmsc': 'Thời hạn mua hàng',
  'flow.zje': 'Tổng số tiền',
  'flow.jzsj': 'Hạn chót',
  'flow.gmrq': 'Ngày mua',
  'flow.tian1': 'ngày',
  'flow.zhou1': 'tuần',
  'flow.yue1': 'tháng',

  'share.dj': 'cấp độ',
  'share.hz': 'Chuyển',
  'share.hzd': 'Chuyển tới',
  'share.qbye': 'số dư trong ví',
  'share.tgje': 'Số tiền khuyến mãi',
  'share.hzje': 'Số tiền chuyển',
  'share.qsrhzje': 'Vui lòng nhập số tiền chuyển',

  '_inv._title': 'Mô tả lời mời',
  '_inv._t1': '1. Lời mời và mối quan hệ được mời',
  '_inv._desc1': 'A mời B, B mời C, C mời D. Mức cao nhất là 4, nếu D mời E lại thì E không liên quan gì đến A. ',
  '_inv._t2': '2. Người mời nhận được phần thưởng',
  '_inv._desc2': 'A nhận được 4% tổng số tiền đã hoàn thành theo đơn đặt hàng B, A nhận được 2% tổng số tiền đã hoàn thành theo đơn đặt hàng C và A nhận được 1% trên tổng số tiền đã hoàn thành theo đơn đặt hàng D',

  '_index._gm': 'Mua',
  '_index._sm': 'Mô tả: Tăng mức độ hiển thị của cửa hàng và thu hút lưu lượng truy cập',

  '_shop._update': '{n} lần/tháng, sửa đổi {m} lần trong tháng hiện tại',

  '_chat._hc': 'Rút tiền',

  '_footer._suom': 'Mô tả chính sách',

  '_footer._platform._qualification': 'Chứng chỉ của công ty',
  '_store._ptzz': 'Các bằng cấp liên quan đến nền tảng ShopifyOutlets',

  '_abt._gywm': 'Giới thiệu về chúng tôi',
  '_abt._syms': 'Mô hình kinh doanh',
  '_abt._qyzr': 'Trách nhiệm doanh nghiệp',
  '_abt._lxwm': 'Liên hệ với chúng tôi',

  '_wsj._wsjms': 'Tổ chức Halloween theo phong cách trong cửa hàng Halloween ma quái của chúng tôi. Cho dù bạn đang tìm thủ thuật hay chiêu đãi trong mùa ma quái này, bộ sưu tập trang phục, đồ trang trí và phụ kiện Halloween của chúng tôi đều có thứ gì đó dành cho cả gia đình. Hãy làm một tuyên bố rùng rợn với các bộ sưu tập dành cho phụ nữ và nam giới của chúng tôi, hoặc khơi dậy trí tưởng tượng của con bạn với những bộ trang phục lạ mắt. Từ đồ gia dụng và đồ chơi dễ thương khó quên cho đến vẻ đẹp và mỹ phẩm quyến rũ, hãy tìm những món đồ hoàn hảo để chào mừng ngày lễ thú vị nhất trong năm. ',

  '_wsj._b1': 'Trang trí nhà mùa thu',
  '_wsj._b2': 'Bí ngô',
  '_wsj._b3': 'Nhà',
  '_wsj._b4': 'Dành cho trẻ em',
  '_wsj._b5': 'Em bé',
  '_wsj._b6': 'Nữ',
  '_wsj._b7': 'Váy lạ mắt',
  '_wsj._b8': 'Được cá nhân hóa',

  '_wsj._cj': 'Tham gia',
  '_wsj._gz': 'Quy tắc hoạt động',
  '_wsj._flss': 'Tìm kiếm danh mục',
  '_wsj._wsnz': 'Quần áo Halloween dành cho nữ',
  '_wsj._wsn': 'Quần áo nam Halloween',
  '_wsj._wst': 'Quần áo trẻ em Halloween',
  '_wsj._wszs': 'Trang trí Halloween',
  '_wsj._wslp': 'Quà Halloween',

  '_wsj._desc1': '"Halloween đặc biệt, Trick or Treat!" Tháng 10 này, chúng tôi đã chuẩn bị một bữa tiệc hóa trang dành cho bạn! Hãy đến và chộp lấy đủ loại sản phẩm đáng sợ và gây sốc, những đợt giảm giá lớn đang chờ bạn! ',
  '_wsj._lkm': 'Đi mua sắm',

  '_wsj._wsj': 'Halloween',

  '_wsj._wsjjz': 'Halloween đang đến',
  '_wsj._mzbt': 'Lịch khám phá sắc đẹp',
  '_wsj._mznr': 'Đếm ngược đến thời điểm tuyệt vời nhất trong năm vừa cập bến với lịch phiêu lưu phù hợp với mọi người.',

  '_chr._tt1': 'Mua sắm trong dịp Giáng sinh',
  '_chr._tt2': 'Trang trí Giáng sinh',
  '_chr._tt3': 'Mua tất cả quà tặng',
  '_chr._tt4': 'Mua tất cả quần áo ngủ Giáng sinh',

  '_chr._dt1': 'Việc chuẩn bị cho lễ Giáng sinh cũng kỳ diệu như ngày trọng đại vậy. Vì vậy, hãy sắp xếp những món quà của bạn, trang hoàng hội trường và chuẩn bị sẵn bộ đồ ngủ phù hợp cho lễ Giáng sinh kỳ diệu.',

  '_chr._dtt1': 'Quần áo Giáng sinh',
  '_chr._dtt2': 'Giáng Sinh Gia Đình Phù Hợp',
  '_chr._dtt3': 'Lịch Mùa Vọng',
  '_chr._dtt4': 'Giáng sinh ở nhà',
  '_chr._dtt5': 'Cửa hàng ấm cúng',
  '_chr._dtt6': 'Nội thất đúng dịp Giáng sinh',

  '_chr._tre1': 'Đang thịnh hành',
  '_chr._tre2': 'Thêm một chút không khí lễ hội vào ngôi nhà của bạn với những xu hướng mà mọi người đều yêu thích.',
  '_chr._tre3': 'Bánh gừng',
  '_chr._tre4': 'Bánh pudding Giáng sinh',
  '_chr._tre5': 'Giáng sinh vui vẻ',

  '_chr._ttre1': 'Thiệp & Giấy gói',
  '_chr._ttre2': 'Đồ chơi & Trò chơi',
  '_chr._ttre3': 'Quà tặng Đồ ăn & Đồ uống',
  '_chr._ttre4': 'Đổ đầy',
  '_chr._ttre5': 'Ông già Noel bí mật',
  '_chr._ttre6': 'Giáng sinh đầu tiên của em bé',
  '_chr._ttre7': 'Quà tặng làm đẹp',
  '_chr._ttre8': 'Quà tặng cao cấp',
  '_chr._ttre9': 'Quà tặng được cá nhân hóa',

  '_sdj._sdnz': 'Quần áo nữ',
  '_sdj._sdn': 'Quần áo nam',
  '_sdj._sdetlw': 'quà tặng',
  '_sdj._sdzs': 'Trang trí',
  '_sdj._sdjj': 'đồ chơi',

  '_sdj._title1': 'Giáng sinh đang đến',
  '_sdj._desc1': '"Giáng sinh: mùa cho đi và tri ân." Tháng 12 này, chúng tôi đã chuẩn bị một bữa tiệc gia đình được trang trí rực rỡ và ấm áp dành cho bạn! Hãy đến và sở hữu nhiều sản phẩm tươi sáng và ấm áp với mức giảm giá lớn đang chờ bạn! ',

  '_ssd._lhpp': 'Thanh toán linh hoạt',
  '_ssd._lzhq': 'Nguồn toàn cầu',
  '_ssd._pwyx': 'được đánh giá là xuất sắc',
  '_ssd._qsth': 'Trả lại dễ dàng',
  '_ssd._jgppcn': 'So khớp giá và cam kết',
  '_ssd._ztbt': 'Tạo cơ hội mua sắm toàn cầu',
  '_ssd._yzspl': 'Tìm nguồn cung ứng hàng tồn kho trực tiếp từ hơn 8.000 thương hiệu độc lập',
  '_ssd._zcgm': 'Đăng ký mua hàng',
  '_ssd._zccs': 'Đăng ký bán',

  '_ssd._dlwz': 'Đăng nhập và đăng ký',

  '_ssd._qjrgwc': 'Thêm tất cả vào giỏ hàng',

  '_zddy._gzlb': 'Danh sách theo dõi',
  '_zddy._zsersxs': 'Bán tại shopifyoutlets',
  '_zddy._zccwsj': 'Đăng ký làm người bán',
  '_zddy._dlyh': 'Đăng nhập',
  '_zddy._zcyh': 'Đăng ký',
  '_zddy._xyh': 'Người dùng mới',
  '_zddy._gwc': 'Giỏ hàng',
  '_zddy._znxx': 'Tin nhắn',
  '_zddy._zshy': 'Phổ biến nhất',
  '_zddy._ddl': 'Đặt hàng',
  '_zddy._mysj': 'Không có dữ liệu',
  '_zddy._ckgd': 'Xem thêm',
  '_zddy._tjsp': 'Sản phẩm được đề xuất',
  '_zddy._rmsp': 'Sản phẩm phổ biến, xu hướng mới, nhiều cơ hội mua hàng hơn',
  '_zddy._xsp': 'Sản phẩm mới',
  '_zddy._jlzzr': 'Những mặt hàng hot nhất trong hai tuần qua',
  '_zddy._ljgm': 'Mua ngay',
  '_zddy._gdsp': 'Sản phẩm phổ biến hơn',
  '_zddy._ssbt': 'Tìm kiếm',
  '_zddy._ssgjz': 'Tôi đang tìm..',

  '_zddy._bdhb': 'Tiền địa phương',
  '_zddy._xzbdhb': 'Chọn nội tệ',
  '_zddy._zje': 'Tổng số tiền',
  '_zddy._xzzffs': 'Hãy chọn phương thức thanh toán phù hợp với bạn',
  '_zddy._jmhb': 'Tiền điện tử',

  '_zddy._cp': 'sản phẩm',
  '_zddy._sj': 'Doanh nhân',
  '_zddy._mj': 'Người bán',
  '_zddy._fhsy': 'Quay lại trang chủ',

  '_sjsy._ddpl': 'Danh sách đơn hàng',
  '_yhqd._czbz': 'Vui lòng liên hệ bộ phận chăm sóc khách hàng chuyên dụng để xác nhận thông tin nạp tiền trước khi nạp tiền.',
  '_yhqd._lxkf': 'liên hệ với dịch vụ khách hàng',
  '_yhqd._qsrje': 'Vui lòng nhập số tiền nạp',
  '_yhqd._wrhyhtd': 'Không có quyền truy cập vào ngân hàng',
  '_yhqd._yhzz': 'Chuyển khoản ngân hàng',
  '_yhbt._yhlx': 'Loại ngân hàng',
  '_zcyz._sjdxyz': 'Xác minh SMS di động',
  '_zcyz._sjhm': 'số điện thoại',
  '_zcyz._srsjhm': 'Nhập số điện thoại di động',
  '_zzsd._qsrzzje': 'Vui lòng nhập số tiền chuyển',
  '_zzsd._zzje': 'Số tiền chuyển khoản',
  '_txcd._qsrtxje': 'Vui lòng nhập số tiền rút',
  '_txcd._skr': 'Người nhận thanh toán',
  '_txcd._skr._ms': 'Vui lòng nhập tên chủ thẻ',
  '_txcd._skzh': 'Số tài khoản thanh toán',
  '_txcd._skzh._ms': 'Vui lòng nhập số tài khoản thanh toán',
  '_txcd._tjyhk': 'Thêm thẻ ngân hàng',
  '_txcd._txje': 'Số tiền rút',
  '_txcd._wdyhdz': 'địa chỉ ngân hàng',
  '_txcd._yhdm': 'mã số',
  '_txcd._yhdm._ms': 'Vui lòng nhập mã ngân hàng',
  '_txcd._yhdz': 'Địa chỉ',
  '_txcd._yhdz._ms': 'Vui lòng nhập địa chỉ ngân hàng',
  '_txcd._yhlx': 'kiểu',
  '_txcd._yhlx._ms': 'Vui lòng nhập loại thẻ ngân hàng',
  '_txcd._yhly': 'lộ trình',
  '_txcd._yhly._ms': 'Vui lòng nhập định tuyến ngân hàng',
  '_txcd._yhmc': 'ngân hàng',
  '_txcd._yhmc._ms': 'Vui lòng nhập tên ngân hàng',
  '_txcd._yhtx': 'Rút tiền mặt ngân hàng',

  '_jys._gmjmhb': 'Mua tiền điện tử',
  '_jys._gmzy': 'Bạn có thể đăng ký tài khoản và mua tiền điện tử bằng cách truy cập các sàn giao dịch sau và theo chính sách của quốc gia hiện tại của bạn. Sau đó nạp tiền vào tài khoản nền tảng! ',

  '_kdydj._bt': 'Mở cửa hàng và giành giải thưởng lớn',
  '_kdydj._xyzbt': 'Vận may không ngừng xoay chuyển',
  '_kdydj._yx': 'Bơm {n} lần',
  '_kdydj._xh': 'Tiêu thụ {n} điểm',

  '_kdydj._jf': 'Điểm:',
  '_kdydj._zjjl': 'Kỷ lục chiến thắng',

  '_kdydj._hdgz': 'Quy tắc hoạt động',
  '_kdydj._qx': 'Xin vui lòng',
  '_kdydj._dl': 'Đăng nhập',

  '_kdydj._mjdl': 'Đăng nhập người bán',
  '_kdydj._yxh': 'Email',
  '_kdydj._yxhsm': 'Vui lòng nhập email của bạn',
  '_kdydj._yxhmm': 'Xin hãy nhập mật khẩu',
  '_kdydj._rgmyzh': 'Nếu bạn chưa có tài khoản',
  '_kdydj._djzc': 'Click để đăng ký',
  '_kdydj._wjmm': 'Quên mật khẩu',

  '_kdydj._wdjp': 'Giải thưởng của tôi',
  '_kdydj._zjts': 'Mẹo chiến thắng',
  '_kdydj._jx': 'Tiếp tục',
  '_kdydj._czl': 'Thắng',

  '_kdydj._ljjl': 'Phần thưởng tích lũy',
  '_kdydj._ycy': 'Đã tham gia: {n} lần',
  '_kdydj._cy': 'Tham gia {n} lần',
  '_kdydj._lq': 'Nhận',
  '_kdydj._ylq': 'Đã nhận',
  '_kdydj._wdd': 'Chưa đạt được',

  '_dk._dkbt1': 'Khoản vay khởi nghiệp giải quyết rắc rối khi vay tiền',
  '_dk._dkbt2': 'Cung cấp các khoản vay kinh doanh và dịch vụ lưu thông vốn',
  '_dk._dkbt3': 'Chúng tôi sẽ giúp bạn giải quyết các vấn đề liên quan đến khoản vay như quỹ eo hẹp, không biết vay tiền ở đâu và ngại nhờ bạn bè vay tiền. Chúng tôi cung cấp các dịch vụ chuyên nghiệp trong suốt quá trình và giải đáp thắc mắc của bạn bất cứ lúc nào. mục tiêu là làm cho mọi khoản vay đều minh bạch, cho phép khách hàng sử dụng chúng một cách nhanh chóng và tự tin. ',
  '_dk._zxsq': 'Ứng dụng trực tuyến',
  '_dk._wddk': 'Khoản vay của tôi',
  '_dk._aqwy': 'An toàn và không cần lo lắng',
  '_dk._lhed': 'Hạn ngạch linh hoạt',
  '_dk._lhedb': 'Số tiền: 5,00 đến 500.000, lãi suất thấp, phương thức trả nợ linh hoạt, không mất phí trả trước, cho vay trực tuyến, không gọi điện xem xét, bảo mật thông tin và cho vay trong ngày. ',
  '_dk._jsfk': 'Cho vay nhanh',
  '_dk._jsfkb': 'Vượt qua bài đánh giá trong thời gian nhanh nhất là một giờ và việc thanh toán sẽ được thực hiện ngay sau khi xem xét. ',
  '_dk._aqww1': 'An toàn và không cần lo lắng',
  '_dk._aqww1b': 'Không cần vay thế chấp, không cần đặt cọc, không gọi lại, đây là vấn đề riêng tư. ',

  '_dk._gywm': 'Giới thiệu về chúng tôi',
  '_dk._gywm1': 'Chúng tôi cam kết cung cấp các dịch vụ tài chính cho vay, dựa trên triết lý kinh doanh "khách hàng là trên hết" và với sứ mệnh giải quyết vấn đề quay vòng vốn của khách hàng kể từ khi thành lập đến nay, công ty đã giúp vô số khách hàng giải quyết vấn đề quay vòng vốn. các vấn đề. ',
  '_dk._gywm2': 'Số tiền: 500 đến 500.000, lãi suất thấp, phương thức trả nợ linh hoạt, không mất phí trả trước, cho vay trực tuyến, không gọi điện xem xét, bảo mật thông tin và cho vay trong ngày. ',
  '_dk._gywm3': 'Nếu bạn không muốn vay tiền từ bạn cùng lớp và bạn bè, hãy đến với chúng tôi. ',
  '_dk._gywm4': 'Cam kết cung cấp dịch vụ cho vay dành cho doanh nhân',
  '_dk._gywm5': 'Giải quyết vấn đề tài chính của bạn',
  '_dk._hzhb': 'Đối tác - Nền tảng cho vay trực tuyến',
  '_dk._smwda': 'Người dùng trạm toàn cầu shopifyoutlets Business đến từ 112 quốc gia trên thế giới và sử dụng tiền điện tử USDT/ETH/BTC/TRX để thanh toán. Tiền điện tử là một cách giao dịch không biên giới, cho phép giao dịch tức thời với chi phí thấp trên toàn thế giới mà không phải chờ đợi và không mất phí quốc tế. ',

  '_dk._yjwa': 'shopifyoutlets Business© Co., Ltd. 2022-2024 Mọi quyền được bảo lưu',
  '_dk._yjwab': 'ShopifyOutlets Holdings Global Sourcing Limited (0008945) được Cơ quan quản lý tài chính ủy quyền và quản lý với tư cách là nhà môi giới tín dụng và làm việc độc quyền với người cho vay Home Retail Group CardServices Limited, giao dịch với tên shopifyoutlets Business Financial Services , shopifyoutlets Business Financial Services Dịch vụ được Cơ quan quản lý tài chính ủy quyền và quản lý để cung cấp tín dụng. Home Retail GroupCard Services Limited được đăng ký tại Anh (04007072), Avebury, 489-499Avebury Boulevard, Milton Keynes, Vương quốc Anh. MK9 2NW. ',

  '_dk._cyzjsq': 'Điền vào đơn đăng ký vay vốn kinh doanh',
  '_dk._dkll': 'Lãi suất cho vay',
  '_dk._dkll2': 'Mô tả lãi suất khoản vay (bấm để hiển thị)',
  '_dk._gs1': 'Công thức',
  '_dk._gs2': 'Sở thích',
  '_dk._gs3': 'Số tiền cho vay',
  '_dk._gs4': 'Lãi suất hàng năm',
  '_dk._gs5': 'Số ngày mượn',
  '_dk._gs6': 'ví dụ',
  '_dk._gs7': 'Số tiền cho vay',
  '_dk._gs8': 'Chu kỳ cho vay',
  '_dk._gs9': 'ngày',
  '_dk._fkfs': 'Phương thức cho vay',
  '_dk._zhye': 'Số dư tài khoản',
  '_dk._dkqx': 'Thời gian cho mượn',
  '_dk._dkqx1': 'Chọn thời gian vay',
  '_dk._sqje': 'Số tiền đăng ký',
  '_dk._qsrdk': 'Hãy nhập khoản vay',
  '_dk._dkje': 'Số tiền vay',
  '_dk._gj': 'Quốc tịch',
  '_dk._qxzgj': 'Hãy chọn quốc tịch',
  '_dk._sqr': 'Người nộp đơn',
  '_dk._qsrsqsm': 'Vui lòng nhập tên của người nộp đơn',
  '_dk._zjhm': 'Số ID',
  '_dk._qsrzjhm': 'Vui lòng nhập số ID',
  '_dk._zjzp': 'Ảnh ID',
  '_dk._zjzp1': 'Vui lòng tải lên ảnh mặt trước giấy tờ tùy thân của bạn',
  '_dk._zjfm1': 'Vui lòng tải lên ảnh chụp mặt sau giấy tờ tùy thân của bạn',
  '_dk._zjsc1': 'Vui lòng tải lên ảnh CMND của bạn',
  '_dk._zjzp2': 'Chọn ảnh',
  '_dk._pzslt': 'Ví dụ về nhiếp ảnh',
  '_dk._wytyb': 'Tôi đã đọc và đồng ý',
  '_dk._jkxy': 'Thỏa thuận cho vay',
  '_dk._tjsq': 'Gửi đơn đăng ký',

  '_dk._wddk2': 'Khoản vay của tôi',
  '_dk._ydk': 'Cho mượn',
  '_dk._cslx': 'Tạo sự quan tâm',
  '_dk._yjhk': 'Số tiền hoàn trả ước tính',
  '_dk._dkjl': 'Hồ sơ khoản vay',
  '_dk._hkjl': 'Hồ sơ trả nợ',
  '_dk._sqrq': 'Ngày nộp đơn',
  '_dk._hkzq': 'Chu kỳ hoàn trả',
  '_dk._gdll': 'Lãi suất cố định',
  '_dk._hkz': 'Đang hoàn trả',
  '_dk._yhk': 'Đã hoàn trả',
  '_dk._sqz': 'Đang đăng ký',
  '_dk._ytg': 'Đã vượt qua',
  '_dk._ybh': 'Bị từ chối',
  '_dk._tqhk': 'Trả nợ sớm',

  '_dk._ljhk': 'Trả nợ ngay lập tức',
  '_dk._qrytqhk': 'Bạn có chắc chắn muốn đăng ký trả nợ trước không?',
  '_dk._qrytqhk1': 'Lưu ý: Sau khi đăng ký thành công, số tiền gửi sẽ được ưu tiên hoàn trả trực tiếp cho khoản vay',
  '_dk._dkxq': 'Chi tiết khoản vay',
  '_dk._dkje1': 'Số tiền cho vay',
  '_dk._yhlx': 'Lãi đến hạn',
  '_dk._hkze': 'Tổng số tiền hoàn trả',
  '_dk._sqhkz': 'Đang xin trả nợ',
  '_dk._zxydbty': 'Vui lòng đọc kỹ và đánh dấu vào ô để đồng ý với "Thỏa thuận cho vay"',
  '_dk._zzsqz': 'Đang đăng ký',

  '_dk._dksq': 'Tín dụng',

  '_dk._cs': 'Thông số',
  '_dk._nllsm': 'Được biểu thị dưới dạng phần trăm, ví dụ: 6,25%',
  '_dk._dktssm': 'Tổng số ngày từ đầu đến cuối phê duyệt khoản vay',
  '_dk._yndts': 'Số ngày trong một năm: thường là 365 ngày',
  '_dk._zlj': 'Phí trả chậm',

  '_xbb._xz': 'Tải xuống',
  '_xbb._xzapp': 'Tải xuống ứng dụng ShopifyOutlets',
  '_xbb._smewm': 'Quét mã QR để tải xuống',
  '_xbb._syfl': 'Tất cả danh mục',
  '_xbb._gdfl': 'Thêm',
  '_xbb._sbbt1': 'Giao hàng nhanh',
  '_xbb._sbbt2': 'Có thể hoàn lại tiền nếu có bất kỳ vấn đề nào',
  '_xbb._sbbt3': 'Trả lại miễn phí',
  '_xbb._sbbt4': 'Trong vòng 90 ngày',
  '_xbb._tjsp1': 'Sản phẩm được đề xuất',
  '_xbb._tjsp2': 'Giảm giá nhiều',
  '_xbb._tjsp3': 'Giảm giá trong thời gian có hạn',

  '_xbb._asjgw': 'Mua sắm theo cửa hàng',
  '_xbb._gdsp': 'Thêm sản phẩm',
  '_xbb._ljgm': 'Mua ngay',
  '_xbb._ptbz': 'Đảm bảo của ShopifyOutlets',
  '_xbb._aqzf': 'Thanh toán an toàn',
  '_xbb._aqzf1': 'Phương thức thanh toán được nhiều người mua sắm quốc tế sử dụng',
  '_xbb._aqys': 'Bảo mật và quyền riêng tư',
  '_xbb._aqys1': 'Chúng tôi tôn trọng quyền riêng tư của bạn nên thông tin cá nhân của bạn được an toàn',
  '_xbb._mjbh': 'Bảo vệ người mua',
  '_xbb._mjbh1': 'Nếu đơn hàng của bạn không được giao trước ngày dự kiến ​​hoặc bạn không hài lòng với đơn hàng, bạn có thể được hoàn lại tiền',
  '_xbb._mzsm': 'Cảnh báo/Tuyên bố từ chối trách nhiệm',

  '_xbb._sjxx': 'Doanh nhân',
  '_xbb._ggms': 'Thông số kỹ thuật',
  '_xbb._mswa': 'Mô tả',
  '_xbb._khpl': 'Nhận xét của khách hàng',
  '_xbb._zmfk': 'Phản hồi tích cực',
  '_xbb._gzrs': 'Theo dõi',
  '_xbb._gzrsan': 'Theo dõi',
  '_xbb._ygzrsan': 'Đã theo dõi',
  '_xbb._fsxx': 'Tin nhắn',
  '_xbb._myrpl': 'Chưa có nhận xét nào',
  '_xbb._llgd': 'Xem thêm',
  '_xbb._pdcl': 'Hứa',
  '_xbb._myf': 'Giao hàng miễn phí',
  '_xbb._sdsj': 'Giao trong khoảng 5-10',
  '_xbb._ksjh': 'Giao hàng nhanh',
  '_xbb._bgdstk': 'Hoàn tiền cho gói hàng bị mất',
  '_xbb._shbgtk': 'Nếu mặt hàng bị hỏng, hoàn tiền',
  '_xbb._s30gtk': 'Hoàn tiền nếu không được giao trong vòng 30 ngày',
  '_xbb._aqyys': 'Bảo mật và quyền riêng tư',
  '_xbb._wjtrxk': 'Thanh toán an toàn: Chúng tôi sẽ không chia sẻ thông tin cá nhân của bạn với bất kỳ bên thứ ba nào nếu không có sự đồng ý của bạn. ',
  '_xbb._sfgrxx': 'Bảo mật thông tin cá nhân: Chúng tôi bảo vệ quyền riêng tư của bạn và giữ thông tin cá nhân của bạn an toàn và bảo mật. ',
  '_xbb._sl': 'Số lượng',
  '_xbb._kcld': 'Hàng tồn kho',

  '_xbb._sjrz': 'Người bán đã định cư',
  '_xbb._hezjh': 'Chương trình đối tác của chúng tôi cung cấp cho bạn đầy đủ các dịch vụ và hỗ trợ tiếp thị, đồng thời nhóm dịch vụ khách hàng của chúng tôi sẽ cung cấp hỗ trợ và lời khuyên chuyên nghiệp để giúp bạn tối ưu hóa chiến lược bán hàng và trưng bày sản phẩm của mình. Tham gia với chúng tôi ngay bây giờ! Chúng ta hãy tạo ra những cơ hội kinh doanh lớn hơn và cùng nhau phát triển! ',
  '_xbb._xsje': 'Thông tin doanh nghiệp',
  '_xbb._rgsqjs': 'Nếu bạn đã là người bán',
  '_xbb._qni': 'Xin vui lòng',
  '_xbb._djdl': 'Nhấp để đăng nhập',
  '_xbb._dplog': 'LOGO cửa hàng',
  '_xbb._dpmc': 'Tên cửa hàng',
  '_xbb._srdpmc': 'Vui lòng nhập tên cửa hàng',
  '_xbb._zjhm': 'Số ID',
  '_xbb._srzjhm': 'Vui lòng nhập số ID',
  '_xbb._zsxm': 'tên thật',
  '_xbb._srzsxm': 'Hãy nhập tên thật của bạn',
  '_xbb._srgj': 'Quốc gia',
  '_xbb._srsrgj': 'Xin vui lòng nhập quốc gia',
  '_xbb._zjz': 'Ảnh ID',
  '_xbb._zjzm': 'Mặt trước của tài liệu',
  '_xbb._zjfm': 'Mặt sau của chứng chỉ',
  '_xbb._sczjz': 'Giữ ảnh ID',
  '_xbb._pssl': 'Ví dụ về ảnh',
  '_xbb._dlyx': 'Email đăng nhập',
  '_xbb._srdlyx': 'Xin vui lòng nhập email đăng nhập của bạn',
  '_xbb._dlmm': 'Mật khẩu đăng nhập',
  '_xbb._srdlmm': 'Hãy nhập mật khẩu đăng nhập của bạn',
  '_xbb._qrmm': 'Xác nhận mật khẩu',
  '_xbb._srqrmm': 'Xin vui lòng nhập mật khẩu xác nhận',
  '_xbb._yqm': 'mã mời',
  '_xbb._sryqm': 'Nhập mã mời',
  '_xbb._yxyzm': 'Mã xác minh email',
  '_xbb._sryzm': 'Nhập mã xác minh email',
  '_xbb._yydbty': 'Tôi đã đọc và đồng ý',
  '_xbb._rzxy': 'Thỏa thuận dàn xếp',

  '_xbb._qgxty': 'Vui lòng đọc kỹ thỏa thuận giải quyết và đánh dấu vào ô đồng ý',
  '_xbb._sryqmb': 'Hãy nhập mã mời',

  '_xbb._ddpl': 'Nhận xét đặt hàng',
  '_xbb._dsppl': 'Nhận xét về sản phẩm',
  '_xbb._dddpl': 'Nhận xét về đơn hàng',

  '_xhd._sdj': 'Giáng sinh'
}
