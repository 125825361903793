export default {
  'nav.account': 'Personal Center',
  'nav.language': 'Simplified Chinese',
  'signup.success': 'Signup successful',

  //error
  'contact.empty': 'Contact information cannot be empty',
  'phone.country.empty': 'The phone prefix cannot be empty',
  'phone.empty': 'Phone cannot be empty',
  'email.valid': 'Please enter a valid email',

  // base
  'base.success': 'Success',
  'base.save': 'Save',
  'base.slide': 'Please pull the slider to verify',
  'base.send.code': 'Send code',
  'base.send.time': '({time}) Resend code',
  'base.send.success': 'Send successfully',
  'base.view.more': 'View More',
  'base.Language': 'Language',
  'base.previous': 'Previous page',
  'base.next': 'Next page',
  'base.total.page': 'Total {page} pages',
  'base.go.to.page': 'Go to page',
  'base.go': 'Go to',
  'base.followers': 'Followers',
  'base.follow': 'Follow',
  'base.following': 'Following',
  'base.visit.store': 'Browse Store',
  'base.contact': 'Contact us',
  'base.delivery': 'Estimated delivery time: 1-7 days',
  'base.search': 'Search',
  'base.search.text': 'Enter keywords to search',
  'base.server': 'Service',

  //index
  'text.categories.title': 'Product Category',
  'text.welcome': 'Welcome to shopping!',
  'text.wishlist': 'Wishlist',
  'text.account': 'Personal Center',
  'text.orders': 'Orders',
  'text.messages': 'Message',
  'text.suggest.title': 'Exclusive Offer',
  'text.suggest.content': 'Only for new global premium members!',
  'text.super.deals': 'Top products at unbelievable prices.',

  //signup
  'signup.register': 'Signup',
  'signup.signin': 'Sign in',
  'signup.store.signin': 'Business Login',
  'signup.sign.out': 'Logout',
  'signup.email': 'Email address',
  'signup.password': 'Password',
  'signup.qr.password': 'Confirm Password',
  'signup.confirm.password': 'Confirm Password',
  'signup.forgot.password': 'Forgot Password',
  'signup.invitation': 'Invitation Code',
  'signup.char': 'Character',
  'signup.contains': 'Contains numbers, letters or symbols',
  'signup.qr.contains': 'The two passwords are inconsistent',
  'signup.create.account': 'Create Account',
  'signup.agree': 'Create an account, you agree',
  'signup.member.agreement': 'Member Agreement',
  'signup.and': 'and',
  'signup.privacy.policy': 'Privacy Policy',
  'signup.email.code': 'Email Verification',
  'signup.last.step': 'Last step',
  'signup.send.email': 'Please enter the 4-digit code sent to {email}',
  'signup.modify.email': 'Modify email',
  'signup.verify.email': 'Verification Email',
  'signup.have.store': 'Have merchant account',
  'signup.goto.signin': 'Business Login',
  'signup.no.store': 'No merchant account',
  'signup.goto.store': 'Signup Merchant',
  'signup.next': 'Next',
  'signup.your.email': 'Your email',
  'signup.code.text': 'Verification Code',
  'signup.submit.signup': 'Sign up now',
  'signup.smrz': 'Real-name authentication',
  'signup.derb': 'Second step',
  'signup.qsrxm': 'Please enter the name of the document',
  'signup.qsrhm': 'Please enter the ID number',

  //forgot
  'forgot.title': 'Reset Password',
  'forgot.btn.check': 'Verify Email',
  'forgot.reset.now': 'Reset Now',

  //store
  'store.info.open': 'Open {y} Week',

  // footer
  'footer.great.value': 'Great value',
  'footer.great.value.desc': 'We offer competitive prices for over 100 million items.',
  'footer.shopping': 'Global Shopping',
  'footer.shopping.desc': 'We ship to over 200 countries and regions, and our website is available in 7 languages.',
  'footer.safe.payment': 'Safe Payment',
  'footer.safe.payment.desc': "Pay with the world's most popular and safest payment methods.",
  'footer.shop.with.confidence': 'Shop with confidence',
  'footer.shop.with.confidence.desc': 'Our buyer protection policy covers your entire purchase process.',
  'footer.help.center': 'Help Center',
  'footer.help.center.desc': '24/7 assistance to create a smooth shopping experience.',
  'footer.terms.conditions': 'Terms and Conditions',
  'footer.return.policy': 'Return Policy',
  'footer.support.policy': 'Support Policy',
  'footer.privacy.policy': 'Privacy Policy',
  'footer.be.seller': 'Become a seller',
  'footer.apply.now': 'Apply Now',
  'footer.stay.connected': 'Stay Connected',

  'footer.about.us': 'About Us',
  'footer.about.company': 'Company Profile',
  'footer.about.video': 'Video Introduction',
  'footer.contact': 'Contact',

  'footer.my.account': 'My Account',
  'footer.my.logout': 'Logout',
  'footer.my.order': 'Order History',
  'footer.my.wish': 'My Wish List',
  'footer.my.join': 'Become a member partner',
  'footer.email': 'Email',
  'footer.gfemail': 'Official email',
  'footer.fwemail': 'Service Email',
  'footer.address': 'Address',

  'apply.success': 'Application successful',
  'apply.success.desc': 'Applied successfully, go to login account',

  // categories
  'category.title': 'Related Category',
  'category.all': 'All categories',

  //detail
  'detail.store.home': 'Store Home',
  'detail.sale.items': 'Item List',
  'detail.recommend': 'Recommended',
  'detail.orders': 'Sales',
  'detail.quantity': 'Quantity',
  'detail.pieces.available': 'Available',
  'detail.delivery': 'Delivery',
  'detail.free.shipping': 'Free Shipping',
  'detail.estimated.delivery': 'Estimated Delivery',
  'detail.days': 'Days',
  'detail.buy.now': 'Buy',
  'detail.add.to.cart': 'Add to cart',
  'detail.buyer.protection': 'Buyer Protection',
  'detail.money.guarantee': 'Money Back Guarantee',
  'detail.refund.desc': 'Get a full refund if the item is not as described or not delivered',
  'detail.description': 'Product Description',
  'detail.customer.reviews': 'Customer Reviews',
  'detail.specifications': 'Product Specifications',
  'detail.top.selling.products': 'Best Selling Products',
  'detail.recommended.for.you': 'Recommended for you',
  'detail.sold': 'Sales',
  'detail.receipt': 'Confirm Receipt',
  'detail.receipt.title': 'Confirm receipt of the order?',
  'detail.receipt.content': 'After confirmation, the transaction order is completed',
  'detail.comment': 'Comment',

  //confirm
  'confirm.shipping.address': 'Recipient Address',
  'confirm.change': 'Change',
  'confirm.payment.methods': 'Payment Methods',
  'confirm.summary': 'Settlement',
  'confirm.total.item.costs': 'Item Total',
  'confirm.total': 'Total',
  'confirm.checkout': 'Checkout',
  'confirm.place.order': 'Place an order now',
  'confirm.pay.now': 'Pay now',
  'confirm.order.desc': "After clicking 'Place Order', I confirm that I have read and confirm",
  'confirm.order.policy': 'All terms and policies',
  'confirm.payment': 'Global Shopping ensures your information and payment security',

  // address
  'address.title': 'Recipient Address',
  'address.default': 'default',
  'address.edit': 'Edit',
  'address.delete': 'Delete',
  'address.new': 'Create a new address',
  'address.contact': 'Contact',
  'address.address': 'Address',
  'address.phone': 'Phone number',
  'address.set.default': 'Set Default',
  'address.confirm': 'Confirm',
  'address.cancel': 'Cancel',
  'address.del.title': 'Confirm to delete address?',
  'address.del.content': 'Are you sure you want to delete this shipping address?',

  'payment.method': 'Payment Method',

  // Shopping Cart
  'shopping.title': 'Shopping Cart',
  'shopping.back.buy': 'Back to special shopping',
  'shopping.start.shopping': 'Start Shopping',

  // member
  'member.account': 'Personal Center',
  'member.overview': 'Overview',
  'member.orders': 'Orders',
  'member.payment': 'My Wallet',
  'member.address': 'Shipping Address',
  'member.wishlist': 'Wishlist',
  'member.followlist': 'Followlist',
  'member.message.center': 'Message Center',
  'member.setting': 'Personal Settings',
  'member.shop.info': 'Shop Information',
  'member.shop.setting': 'Shop Settings',
  'member.order.notify': 'New Order Notify',
  'member.order.tips': 'By sending email to merchant account email',

  // member. order
  'member.order.title': 'My Order',
  'member.order.view.all': 'View All',
  'member.order.all': 'All',
  'member.order.unpaid': 'Unpaid',
  'member.order.paid': 'Paid',
  'member.order.procurement': 'Purchase',
  'member.order.seller.paid': 'Purchased',
  'member.order.processing': 'Pending',
  'member.order.shipped': 'shipped',
  'member.order.return': 'Returning',
  'member.order.completed': 'Completed',
  'member.order.refund': 'Refunded',
  'member.order.all.time': 'All',
  'member.order.empty': 'No order yet',
  'member.order.date': 'Date',
  'member.order.purchase.date': 'Purchase',
  'member.order.cpoy': 'Copy',
  'member.order.id': 'Order',
  'member.order.detail': 'Details',
  'member.order.logistics': 'logistics information',

  // member. payment
  'member.payment.title': 'My Wallet',
  'member.wallet.balance': 'Wallet Balance',
  'member.crypto.recharge': 'Recharge',
  'member.crypto.withdrawal': 'Withdraw',
  'member.crypto.bank': 'Bank Card',
  'member.wallet.record': 'Coin Deposit Record',
  'member.bankcard.record': 'Bank Card Record',
  'member.withdrawal.record': 'Withdrawal Record',
  'member.income.record': 'Income Record',
  'member.transaction.record': 'Transaction Record',
  'member.wallet.freeze': 'Freeze funds',
  'member.wallet.profit': 'Estimated earnings',

  //recharge
  'recharge.currency': 'Currency',
  'recharge.protocol': 'Protocol',
  'recharge.qrcode': 'QR code',
  'recharge.address': 'Wallet Address',
  'recharge.copy': 'Copy address',
  'recharge.ok.text': 'Confirmation',
  'recharge.cancel.text': 'Cancel',

  //bank
  'bank.recharge.title': 'Bank card recharge',
  'bank.title': 'Bank Name',
  'bank.name': 'Name',
  'bank.account': 'Account',
  'bank.routing': 'Routing',
  'bank.code': 'Code',
  'bank.bankname': 'Bank name',
  'bank.bankaddress': 'Bank address',
  'bank.ok.text': 'Next',
  'bank.ok.prev': 'Previous step',
  'bank.submit': 'Submit now',
  'bank.amount': 'Recharge Amount',
  'bank.amount.desc': 'Please enter the recharge amount',
  'bank.type': 'Type',
  'bank.usd': 'USD',
  'bank.eur': 'Euro',
  'bank.receipt.number': 'Receipt number',
  'bank.receipt.number.desc': 'Please enter the transaction receipt number',
  'bank.credential.picture': 'Credential Picture',
  'bank.credential.picture.desc': 'Please upload the credential picture',
  'bank.remark': 'Remark',
  'bank.upload': 'Upload picture',
  'bank.text.title': 'Notes',
  'bank.text.t1': "Don't need any remarks for transfer, leave it blank",
  'bank.text.t2': 'For Hong Kong households, select Hong Kong instead of China',
  'bank.text.t3': 'American remittance, on weekdays, remit European remittance before 3:00 p.m., remittance is available during bank working hours!',
  'bank.text.t4': 'The payment time is T+1, and the latest payment is T+3 in Europe',
  'bank.text.t5': 'Inform the platform before transferring money to confirm whether the account is available. If the account is closed, no compensation will be paid.',
  'bank.text.t6': 'Deposit via bank card. Customer service information shall prevail.',

  //banklist
  'bank.list.title': 'Bank Name',
  'bank.list.code': 'Bank Code',
  'bank.list.amount': 'Transfer Amount',
  'bank.list.number': 'Receipt number',
  'bank.list.img': 'Voucher Image',
  'bank.list.status': 'Status',
  'bank.list.time': 'Time',
  'bank.list.status1': 'Under review',
  'bank.list.status2': 'Passed',
  'bank.list.status3': 'Rejected',

  // Withdrawal
  'withdrawal.address.desc': 'Please enter the wallet address!',
  'withdrawal.number': 'Number',
  'withdrawal.real.number': 'actual arrival',
  'withdrawal.number.desc': 'Please enter the withdrawal amount!',
  'withdrawal.btn.all': 'All',
  'withdrawal.balance': 'Balance',
  'withdrawal.commission': 'Commission',
  'withdrawal.actual.amount': 'Account',
  'withdrawal.notice': 'Notice',
  'withdrawal.notice.text': 'Before transferring, please confirm that the receiving address information is correct. Once the assets are transferred out, they cannot be returned.',
  'withdrawal.notice.content': '{name}({cp_name}) commission: current market value {fee_rate}%/transaction, minimum standard: {fee_min} {name}/transaction',
  'withdrawal.submit': 'Submit',
  'withdrawal.choice': 'Choose a cryptocurrency',
  'withdrawal.yzm': 'Email-Code',
  'withdrawal.fs': 'Send',
  'withdrawal.qsryzm': 'Please enter the email code',

  // recive
  'recive.method': 'Protocol',
  'recive.amount': 'Amount',
  'recive.address': 'Address',
  'recive.date': 'Time',
  'recive.status': 'Status',
  'recive.create.at': 'Transaction Time',
  'recive.type': 'Type',
  'recive.befor': 'Before transaction',
  'recive.balance': 'Balance',
  'recive.freeze': 'Freeze',
  'recive.review': 'Reviewing',
  'recive.success': 'Passed',
  'recive.reject': 'Rejected',

  // advertise
  'advertise.title': 'Promotion Center',
  'advertise.shop.title': 'Shop Promotion',
  'advertise.shop.status': 'Status',
  'advertise.shop.expired': 'Expired',
  'advertise.shop.promotion': 'Promoting',
  'advertise.shop.expire.date': 'Expiration date',
  'advertise.shop.renew': 'Advertisement renewal',
  'advertise.shop.payable': 'Amount payable',
  'advertise.shop.explanation': 'Advertisement Explanation',
  'advertise.shop.text': 'Participate in platform advertising promotion, increase store exposure, and promote transaction orders',
  'advertise.shop.paynow': 'Renew immediately',
  'advertise.shop.modal.title': 'Confirm Payment',
  'advertise.shop.modal.desc': 'Confirmed to pay the promotion fee',
  'advertise.shop.modal.btn': 'Confirm payment',

  //Wish List
  'wishlist.title': 'Wishlist',
  'wishlist.delete': 'Delete',
  'wishlist.orders': 'Sales',

  //Follow List
  'followlist.title': 'Followlist',
  'followlist.delete': 'Delete',
  'followlist.follow': 'Follow',

  // store
  'store.dashboard': 'Dashboard',
  'store.products': 'Products',
  'store.products.list': 'Product List',
  'store.products.reviews': 'Product Reviews',
  'store.orders': 'Orders',
  'store.wallet': 'Wallet',
  'store.message': 'Message Center',
  'store.setting': 'Settings',
  'store.order.total.profit': 'Estimated total profit',

  //dashboard
  'dashboard.store.hour.views': 'Real-time visits',
  'dashboard.store.today.views': "Today's Views",
  'dashboard.product.total': 'Total Product',
  'dashboard.product.today': 'Added today',
  'dashboard.order.total': 'Order Total',
  'dashboard.sales.total': 'Estimated turnover',
  'dashboard.sales.real': 'Real sales',
  'dashboard.sales.pay': 'Total Payout',
  'dashboard.sales.profit': 'Total Profit',
  'dashboard.commission.total': 'Total revenue',
  'dashboard.commission.today': "Today's earnings",
  'dashboard.order.sales': 'Product Sales',
  'dashboard.sales.list': 'Sales Ranking',
  'dashboard.goods.cate.rate': 'Product category ratio',
  'dashboard.goods.wish': 'Like Goods Ranking',
  'dashboard.january': 'January',
  'dashboard.february': 'February',
  'dashboard.march': 'March',
  'dashboard.april': 'April',
  'dashboard.may': 'May',
  'dashboard.june': 'June',
  'dashboard.july': 'July',
  'dashboard.august': 'August',
  'dashboard.september': 'September',
  'dashboard.october': 'October',
  'dashboard.november': 'November',
  'dashboard.december': 'December',

  // products
  'products.add.new': 'Add new product',
  'products.add.from.warehouse': 'Add products from warehouse',
  'products.delete': 'Delete',
  'products.add': 'Add',
  'products.table.img': 'Image',
  'products.table.name': 'Name',
  'products.table.category': 'Category',
  'products.table.wish': 'like',
  'products.table.stock': 'Stock',
  'products.table.price': 'Price',
  'products.table.profit': 'Profit',
  'products.table.action': 'Action',
  'products.search.category': 'Search by Category',
  'products.back.product': 'Back to product list',
  'products.total': 'Total Products',
  'products.yes': 'Yes',
  'products.no': 'Cancel',
  'products.batch.add': 'Batch Add',
  'products.ask.add': 'Are you sure you want to add products?',
  'products.batch.delete': 'Batch delete',
  'products.ask.delete': 'Are you sure you want to delete the product?',
  'products.top': 'Top',
  'products.syzd': 'Home Top',
  'products.zdwz': 'Position',
  'products.t1': 'Promotion',
  'products.t2': 'Preferred',
  'products.t3': 'Boutique',
  'products.t4': 'none',

  // reviews
  'reviews.title': 'Product Reviews',
  'reviews.product.name': 'Product',
  'reviews.user.name': 'User',
  'reviews.star': 'Rating',
  'reviews.comment': 'Review Content',
  'reviews.sku': 'Product Specifications',
  'reviews.imgs': 'Photo Gallery',
  'reviews.created': 'Date',

  // store. order
  'store.order.purchase': 'Purchase',
  'store.order.purchase.desc': 'Are you sure you want to pay for this product?',
  'store.order.purchase.yes': 'Pay Now',
  'store.order.purchase.no': 'Cancel',
  'store.order.desc': 'Payment is required to purchase the order, and you will receive benefits after the order is completed.',
  'store.order.no': 'Order Number',
  'store.order.number': 'Quantity',
  'store.order.buyer': 'Buyer',
  'store.order.total': 'Total Amount',
  'store.order.will.earning': 'Earning',
  'store.order.profit': 'Profit',
  'store.order.dividends': 'Dividends',
  'store.order.payment.status': 'Payment Status',
  'store.order.seller.buy.status': 'Purchase Status',
  'store.order.status': 'Order Status',
  'store.order.date': 'Order Date',
  'store.order.purchase.date': 'Purchase Date',
  'store.order.action': 'Action',
  'store.order.purchase.amount': 'Purchase amount',

  //income
  'income.create.at': 'Record Time',
  'income.order.sn': 'Order Number',
  'income.realpay': 'Order Amount',
  'income.profit': 'income',

  //Setting
  'setting.avatar': 'Business Avatar',
  'setting.upload': 'Upload',
  'setting.shop.name': 'Shop Name',
  'setting.shop.phone': 'Contact Phone',
  'setting.shop.address': 'Shop Address',
  'setting.shop.save': 'Save',
  'setting.upload.pic': 'Upload picture',
  'setting.send.pic': 'Send picture',

  //beseller
  'beseller.title': 'Apply to be a merchant',
  'beseller.account': 'Account Information',
  'beseller.store': 'Store Information',
  'beseller.store.name': 'Store Name',
  'beseller.store.address': 'Store Address',

  //savehome
  'store.home.title': 'Homepage Settings',
  'store.home.topimg': 'top image',
  'store.home.banner': 'Carousel',
  'store.home.up3': 'Remarks: At least 3 uploads',
  'store.upload.more': 'upload multiple images',
  'store.home.columns': 'Columns',
  'store.home.bgimg': 'Background Image',
  'store.goods.remark': 'Remark: 5 items per line, maximum 10 items per section',
  'store.home.select': 'Select product',
  'store.home.add': 'Add column',

  //personal information
  'setting.update.user': 'personal information',
  'setting.update.info': 'Modify information',
  'setting.user.avatar': 'User Avatar',
  'setting.user.nickname': 'User Nickname',
  'setting.user.nickname.desc': 'Please enter user nickname',

  'setting.safe.title': 'Safety Information',
  'setting.user.passwd': 'Change password',
  'setting.passwd.title1': 'Old Password',
  'setting.passwd.desc1': 'Please enter the old password',
  'setting.passwd.title2': 'New Password',
  'setting.passwd.desc2': 'Please enter a new password',
  'setting.passwd.title3': 'Confirm Password',
  'setting.passwd.desc3': 'Please enter the confirmation password',
  'setting.invitation': 'Invite friends',
  'setting.copy': 'Copy',

  'adv.add.products': 'Add promotional products',
  'adv.list.title': 'Product promotion',
  'adv.type': 'Promotion Type',
  'adv.begin.time': 'Promotion time',
  'adv.end.time': 'End time',
  'adv.status': 'Status',
  'adv.status.s1': 'Promoting',
  'adv.status.s2': 'End',
  'adv.add.back': 'Return to the promoted list',
  'adv.recharge': 'Recharge promotion points',
  'adv.select.goods': 'Select Goods',
  'adv.select.btn': 'Promoted Products',
  'adv.recharge.title': 'Recharge Promotion Points',
  'adv.recharge.balance': 'Point balance',
  'adv.point': 'point',
  'adv.point.rate': 'Point ratio',
  'adv.recharge.amount': 'Recharge amount',
  'adv.recharge.input.amount': 'Enter recharge amount',
  'adv.select.mod': 'Select panel',
  'adv.mod1': 'Recommended columns',
  'adv.mod2': 'Preferred column',
  'adv.mod3': 'Hot selling column',
  'adv.mod4': 'Excellent list',
  'adv.mod5': 'Classification list',
  'adv.mod6': 'Detailed Promotion',
  'adv.ge': 'quantity',
  'adv.xs': 'hour',
  'adv.xzbk': 'Select plate',
  'adv.syme': 'Remaining quota',
  'adv.tgsc': 'Promotion duration',
  'adv.tgjg': 'Promotion price',
  'adv.syye': 'Current balance',
  'adv.tgye': 'Promotion balance',
  'adv.yj1': 'expected',
  'adv.yj2': 'Start promotion',
  'adv.pay.point': 'Consumption points',

  'wallet.ykcsxf': 'The handling fee has been deducted',
  'wallet.sxf': 'Service Fee',
  'wallet.zxje': 'Minimum amount',
  'wallet.24hxe': '24-hour limit',
  'wallet.desc.text': 'Under any circumstances, the official will not ask you to transfer money to an account, nor will it ask you for a verification code. Do not participate in illegal activities such as purchasing on behalf of others, money laundering, and illegal fundraising, and beware of online fraud',
  'wallet.cunbi.text': 'You can only deposit {name} to this address, other assets will not be retrieved',

  'credit.title': 'Credit Limit',
  'credit.ed': 'Quota',
  'credit.hk': 'Repayment',
  'credit.kyye': 'Available balance',
  'credit.dqqk': 'Current arrears',
  'credit.hkje': 'Repayment amount',
  'credit.hkje.desc': 'Please enter the repayment amount',
  'credit.cgje': 'Purchase Amount',
  'credit.qrz': 'Certification',
  'credit.srxyj': 'Enter credit payment amount',

  'store.gzl': 'follow',
  'store.wxz': 'Unlimited',

  'auth.smrz': 'Real-name authentication',
  'auth.rzlx': 'Type',
  'auth.sfzm': 'ID card front',
  'auth.sffm': 'The reverse side of the ID card',
  'auth.zsxm': 'real name',
  'auth.zjhm': 'Document number',
  'auth.yyzz': 'Business License',
  'auth.gsmc': 'Company Name',
  'auth.shz': 'Auditing',
  'auth.ytg': 'Passed',
  'auth.ybh': 'Rejected',
  'auth.zt': 'status',
  'auth.gr': 'Personal',
  'auth.gs': 'Enterprise',
  'auth.ljtj': 'Apply for authentication',
  'auth.wrz': 'Unauthenticated',

  'credit.qts': 'View description',
  'store.spss': 'Commodity upper limit',
  'store.dpfh': 'Store dividends',
  'store.qydj': 'Equity level',
  'store.level': 'level',
  'store.jian': 'pieces',

  'order.notify.email': 'Due to email risk control, platform order reminder emails are frequently sent and will be intercepted. In order to receive them in time, it is recommended to add {e} to the login email whitelist',

  'setting.sjxx': 'Information',
  'setting.smrz': 'Authentication',
  'setting.aqzx': 'Security',
  'setting.tzxx': 'Notification',
  'setting.dpzx': 'Decoration',
  'setting.sjqy': 'Equity',
  'setting.sjdjqy': 'Description of Merchant Level Benefits',

  'setting.fhbky': 'The current dividend is not available',
  'setting.spgzyzy': 'Store rules and guidelines',

  'desc.dqqy': 'Current equity',
  'desc.xjqy': 'Subordinate interests',
  'desc.sjqy_0': 'Store credit limit',
  'desc.sjqy_1': 'Store dividends',
  'desc.sjqy_2': 'Number of products that can be published',

  'share.yqlb': 'Invitation list',
  'share.zmj': 'Total seller',
  'share.zdd': 'Total order',
  'share.zsy': 'Total brokerage income',
  'share.wcl': 'unprocessed',
  'share.ycl': 'Processed',
  'share.ywc': 'Completed',
  'share.dpmc': 'Store Name',
  'share.ddl': 'Order Quantity',
  'share.jjsy': 'Brokerage income',
  'share.zts': 'Total number of entries',

  'chat.state': 'State',
  'chat.zx': 'Online',
  'chat.ljz': 'Connecting',

  'bind.wallet.title': 'Withdrawal address',
  'bind.address.title': 'Add address',
  'bind.bj': 'Edit',
  'bind.sc': 'Delete',
  'bind.qrsc': 'Are you sure you want to delete this address?',
  'bind.qbdz': 'Wallet address',
  'bind.tjsj': 'Add time',
  'bind.cz': 'Operation',
  'withdrawal.address.choice': 'Please choose the withdrawal address!',

  'order.sxsj': 'Effective time',
  'order.ljsj': 'immediately effective',
  'order.dssj': 'Timed effective',

  'store.st.zt': 'Store Status',
  'store.st.zc': 'normal',
  'store.st.zc.desc': 'Your store is normal, please keep it up',
  'store.st.yc': 'Exception',
  'store.st.yc.desc': 'Your store has some abnormal orders or received customer complaints',
  'store.st.dj': 'Freeze',
  'store.st.dj.desc': 'Your store has a certain operating risk and the funds are frozen',
  'store.st.jy': 'disabled',
  'store.st.jy.desc': 'Your store has violated the platform-related agreements and rules and has been disabled',

  'task.sy': 'Homepage',
  'task.rwlbo': 'Task list',
  'task.rwxqo': 'Task details',
  'task.ljcy': 'Participate now',
  'task.gdrw': 'More tasks',
  'task.rwlb': 'List of grabbing tasks',
  'task.qbrw': 'All tasks',
  'task.wcyd': 'I participated in',
  'task.qdz': 'Getting orders',
  'task.ywc': 'Completed',
  'task.djy': 'to be traded',
  'task.ddje': 'Amount',
  'task.cyrs': 'Number',
  'task.ewsy': 'Income',
  'task.jzsj': 'Deadline',
  'task.ckxq': 'View details',
  'task.rwxq': 'Task details',
  'task.cylb': 'Participation list',
  'task.qdcyrw': 'Are you sure you want to participate in this order task?',
  'task.sd': 'Yes',
  'task.bue': 'no',

  'task.wdsy': 'Revenue',
  'task.wdfh': 'Dividends',
  'task.wdqd': 'Get order',
  'task.dd.leixin': 'Order Type',
  'task.dd.qdrw': 'Quick order task',
  'task.dd.ptdd': 'Ordinary order',

  'flow.lltg': 'Flow Promotion',
  'flow.gmll': 'Purchase data package',
  'flow.dqtc': 'Current package',
  'flow.tc1': 'Package',
  'flow.sy2': 'remaining',
  'flow.tian3': 'day',
  'flow.jlzs': 'Total number of records',
  'flow.lltc': 'Flow package',
  'flow.ll': 'flow',
  'flow.gmsj': 'Purchase time',
  'flow.tcjg': 'Package price',
  'flow.sjzf': 'actual payment',
  'flow.tcsm': 'Package description',
  'flow.tcsm.sm': "Purchasing this package will increase the store's exposure and gain more customer traffic. In order to get more orders and increase store revenue. ",

  'flow.tcmc': 'Package name',
  'flow.gmsc': 'Purchase duration',
  'flow.zje': 'Total amount',
  'flow.jzsj': 'Deadline',
  'flow.gmrq': 'Purchase date',
  'flow.tian1': 'day',
  'flow.zhou1': 'week',
  'flow.yue1': 'month',

  'share.dj': 'level',

  'share.hz': 'Transfer',
  'share.hzd': 'Transfer to',
  'share.qbye': 'wallet balance',
  'share.tgje': 'Promotion amount',
  'share.hzje': 'Transfer amount',
  'share.qsrhzje': 'Please enter the transfer amount',

  '_inv._title': 'Invitation Description',
  '_inv._t1': '1. Invitation and invited relationship',
  '_inv._desc1': 'A invites B, B invites C, C invites D. The highest level is 4, if D invites E again, then E has nothing to do with A. ',
  '_inv._t2': '2. The inviter gets a reward',
  '_inv._desc2': 'A gets 4% of the total amount completed by order B, A gets 2% of the total amount completed by order C, and A gets 1% of the total amount completed by order D',

  '_index._gm': 'Purchase',
  '_index._sm': 'Description: Increase store exposure and attract traffic',

  '_shop._update': '{n} times/month, modified {m} times in the current month',

  '_chat._hc': 'revoke',

  '_footer._suom': 'Policy Description',

  '_footer._platform._qualification': 'Company qualification',
  '_store._ptzz': 'ShopifyOutlets platform related qualifications',

  '_abt._gywm': 'About us',
  '_abt._syms': 'Business model',
  '_abt._qyzr': 'Corporate Responsibility',
  '_abt._lxwm': 'Contact us',

  '_wsj._wsjms': "Celebrate Halloween in style in our spooky Halloween store. Whether you're looking for a trick or a treat during this spooky season, our collection of Halloween costumes, decorations and accessories has something for the whole family. Make a spine-chilling statement with our women's and men's collections, or unleash your child's imagination with fancy dress costumes. From unforgettable cute homewares and toys, to glamorous beauty and cosmetics, find the perfect pieces to celebrate the most exciting holiday of the year. ",

  '_wsj._b1': 'Autumn Home Decor',
  '_wsj._b2': 'Pumpkin',
  '_wsj._b3': 'Home',
  '_wsj._b4': "Children's",
  '_wsj._b5': "Baby's",
  '_wsj._b6': "Women's",
  '_wsj._b7': 'Fancy Dress',
  '_wsj._b8': 'Personalised',

  '_wsj._cj': 'Join',
  '_wsj._gz': 'Activity rules',
  '_wsj._flss': 'Category search',
  '_wsj._wsnz': "Halloween women's clothing",
  '_wsj._wsn': "Halloween men's clothing",
  '_wsj._wst': "Halloween children's clothing",
  '_wsj._wszs': 'Halloween decoration',
  '_wsj._wslp': 'Halloween gift',

  '_wsj._desc1': '"Halloween Special, Trick or Treat!" This October, we have prepared a carnival party for you! Come and snap up all kinds of scary and shocking products, big discounts are waiting for you! ',
  '_wsj._lkm': 'Go shopping',

  '_wsj._wsj': 'Halloween',

  '_wsj._wsjjz': 'Halloween is comming',
  '_wsj._mzbt': 'Beauty advent calendars',
  '_wsj._mznr': 'The countdown to the most wonderful time of the year has just landed with advent calendars to suit everyone.',

  '_chr._tt1': 'Shop all Christmas',
  '_chr._tt2': 'Christmas Decorations',
  '_chr._tt3': 'Shop all Gifting',
  '_chr._tt4': 'Shop all Christmas Nightwear',

  '_chr._dt1': 'The run-up to Christmas is just as magical as the big day. So get your gifts sorted, your halls decked out, and your matching pyjamas ready for the magic of Christmas.',

  '_chr._dtt1': 'Christmas Clothing',
  '_chr._dtt2': 'Matching Family Christmas',
  '_chr._dtt3': 'Advent Calendars',
  '_chr._dtt4': 'Christmas at Home',
  '_chr._dtt5': 'Cosy Shop',
  '_chr._dtt6': 'Furniture in Time For Christmas',

  '_chr._tre1': 'Trending now',
  '_chr._tre2': 'Add a bit of festive fun to your home with the trends everyone is loving.',
  '_chr._tre3': 'Gingerbread',
  '_chr._tre4': 'Christmas Pudding',
  '_chr._tre5': 'Christmas Gonks',

  '_chr._ttre1': 'Cards & Wrapping Paper',
  '_chr._ttre2': 'Toys & Games',
  '_chr._ttre3': 'Food & Drink Gifts',
  '_chr._ttre4': 'Stocking Fillers',
  '_chr._ttre5': 'Secret Santa',
  '_chr._ttre6': "Baby's First Christmas",
  '_chr._ttre7': 'Beauty Gifts',
  '_chr._ttre8': 'Premium Gifts',
  '_chr._ttre9': 'Personalised Gifts',

  '_sdj._sdnz': "Women's clothing",
  '_sdj._sdn': "Men's clothing",
  '_sdj._sdetlw': 'gift',
  '_sdj._sdzs': 'Decoration',
  '_sdj._sdjj': 'toy',

  '_sdj._title1': 'Christmas is coming',
  '_sdj._desc1': '"Christmas: the season of giving and gratitude." This December, we have prepared a brightly decorated and warm family party for you! Come and grab a variety of bright and warm products with big discounts waiting for you! ',

  '_ssd._lhpp': 'Flexible payment',
  '_ssd._lzhq': 'Source Global',
  '_ssd._pwyx': 'rated as excellent',
  '_ssd._qsth': 'Easy return',
  '_ssd._jgppcn': 'Price Matching and Commitment',
  '_ssd._ztbt': 'Create a global one-stop shopping',
  '_ssd._yzspl': 'Sourcing inventory directly from over 8,000 independent brands',
  '_ssd._zcgm': 'Register to purchase',
  '_ssd._zccs': 'Register for sale',

  '_ssd._dlwz': 'Login and register',

  '_ssd._qjrgwc': 'Add all to cart',

  '_zddy._gzlb': 'Watchlist',
  '_zddy._zsersxs': 'Sold at shopifyoutlets',
  '_zddy._zccwsj': 'Register as a merchant',
  '_zddy._dlyh': 'Login',
  '_zddy._zcyh': 'Register',
  '_zddy._xyh': 'New user',
  '_zddy._gwc': 'Shopping cart',
  '_zddy._znxx': 'Message',
  '_zddy._zshy': 'Most popular',
  '_zddy._ddl': 'Order',
  '_zddy._mysj': 'No data',
  '_zddy._ckgd': 'See more',
  '_zddy._tjsp': 'Recommended products',
  '_zddy._rmsp': 'Popular products, new trends, more purchasing opportunities',
  '_zddy._xsp': 'New product',
  '_zddy._jlzzr': 'The hottest items in the past two weeks',
  '_zddy._ljgm': 'Buy now',
  '_zddy._gdsp': 'More popular products',
  '_zddy._ssbt': 'Search',
  '_zddy._ssgjz': "I'm looking for..",

  '_zddy._bdhb': 'Local currency',
  '_zddy._xzbdhb': 'Select local currency',
  '_zddy._zje': 'Total amount',
  '_zddy._xzzffs': 'Please choose the payment method that suits you',
  '_zddy._jmhb': 'Cryptocurrency',

  '_zddy._cp': 'product',
  '_zddy._sj': 'Businessman',
  '_zddy._mj': 'Seller',
  '_zddy._fhsy': 'Return',

  '_sjsy._ddpl': 'Order list',
  '_yhqd._czbz': 'Please contact dedicated customer service to confirm the recharge information before recharging.',
  '_yhqd._lxkf': 'Contact Customer Service',
  '_yhqd._qsrje': 'Please enter the recharge amount',
  '_yhqd._wrhyhtd': 'No bank access',
  '_yhqd._yhzz': 'Bank Transfer',
  '_yhbt._yhlx': 'Bank type',
  '_zcyz._sjdxyz': 'Mobile SMS verification',
  '_zcyz._sjhm': 'phone number',
  '_zcyz._srsjhm': 'Enter mobile number',
  '_zzsd._qsrzzje': 'Please enter the transfer amount',
  '_zzsd._zzje': 'transfer amount',
  '_txcd._qsrtxje': 'Please enter the withdrawal amount',
  '_txcd._skr': 'Payee',
  '_txcd._skr._ms': 'Please enter the cardholder name',
  '_txcd._skzh': 'Receiving account',
  '_txcd._skzh._ms': 'Please enter the payment account number',
  '_txcd._tjyhk': 'Add bank card',
  '_txcd._txje': 'Withdrawal Amount',
  '_txcd._wdyhdz': 'Bank address',
  '_txcd._yhdm': 'Code',
  '_txcd._yhdm._ms': 'Please enter bank code',
  '_txcd._yhdz': 'address',
  '_txcd._yhdz._ms': 'Please enter bank address',
  '_txcd._yhlx': 'type',
  '_txcd._yhlx._ms': 'Please enter the bank card type',
  '_txcd._yhly': 'routing',
  '_txcd._yhly._ms': 'Please enter bank routing number',
  '_txcd._yhmc': 'bank',
  '_txcd._yhmc._ms': 'Please enter bank name',
  '_txcd._yhtx': 'Bank withdrawal',

  '_jys._gmjmhb': 'Buy cryptocurrency',
  '_jys._gmzy': "You can register an account and buy cryptocurrency by going to the following exchanges and according to your current country's policies. Then recharge to the platform account! ",

  '_kdydj._bt': 'LOTTERY',
  '_kdydj._xyzbt': 'Lucky turns',
  '_kdydj._yx': 'Draw {n} times',
  '_kdydj._xh': 'Consume {n} points',

  '_kdydj._jf': 'Points:',
  '_kdydj._zjjl': 'Winning record',

  '_kdydj._hdgz': 'Activity rules',
  '_kdydj._qx': 'Please',
  '_kdydj._dl': 'Login',

  '_kdydj._mjdl': 'Seller login',
  '_kdydj._yxh': 'Email',
  '_kdydj._yxhsm': 'Please enter your email',
  '_kdydj._yxhmm': 'Please enter your password',
  '_kdydj._rgmyzh': "If you don't have an account",
  '_kdydj._djzc': 'Click to register',
  '_kdydj._wjmm': 'Forgot your password',

  '_kdydj._wdjp': 'My Prize',
  '_kdydj._zjts': 'Winning Tips',
  '_kdydj._jx': 'Continue',
  '_kdydj._czl': 'Won',

  '_kdydj._ljjl': 'Accumulated rewards',
  '_kdydj._ycy': 'Participated: {n} times',
  '_kdydj._cy': 'Participated {n} times',
  '_kdydj._lq': 'Received',
  '_kdydj._ylq': 'Received',
  '_kdydj._wdd': 'Not reached',

  '_dk._dkbt1': 'Entrepreneurship loans solve the troubles of borrowing money',
  '_dk._dkbt2': 'Provide entrepreneurial loans and capital turnover services',
  '_dk._dkbt3': 'Solve your loan problems such as tight funds, nowhere to borrow money, and being embarrassed to ask friends for money. Professionals will serve you throughout the process and answer your questions at any time. Our goal is to make every loan transparent so that customers can use it quickly and safely. ',
  '_dk._zxsq': 'Apply online',
  '_dk._wddk': 'My loan',
  '_dk._aqwy': 'Safe and worry-free',
  '_dk._lhed': 'Flexible amount',
  '_dk._lhedb': 'Amount: 5,00 to 500,000, low interest, flexible repayment method, no upfront fees, online loan, no review call, information confidentiality, and loan on the same day. ',
  '_dk._jsfk': 'Quick loan',
  '_dk._jsfkb': 'The fastest review is passed in one hour, and the loan is immediately issued after the review is passed. ',
  '_dk._aqww1': 'Safe and worry-free',
  '_dk._aqww1b': 'Unsecured loans, no deposit required, no return calls, this is a private matter. ',

  '_dk._gywm': 'About us',
  '_dk._gywm1': 'We are committed to loan financial services, based on the "customer first" business philosophy, with the mission of solving customer capital turnover problems. Since its establishment, the company has helped countless customers solve capital turnover problems. ',
  '_dk._gywm2': 'Amount: 500 to 500,000, low interest, flexible repayment methods, no upfront fees, online loan, no review calls, information confidentiality, and loan on the same day. ',
  '_dk._gywm3': "If you don't want to borrow money from classmates and friends, please find us. ",
  '_dk._gywm4': 'Dedicated to entrepreneur loan services',
  '_dk._gywm5': 'Solve your funding problems',
  '_dk._hzhb': 'Partners - Online lending platform',
  '_dk._smwda': 'shopifyoutlets Business users come from 112 countries around the world and use cryptocurrency USDT/ETH/BTC/TRX for settlement. Cryptocurrency is a borderless trading method that can achieve instant low-cost transactions around the world without waiting and no international fees. ',

  '_dk._yjwa': 'shopifyoutlets Business© Limited 2022-2024 All Rights Reserved',
  '_dk._yjwab': 'ShopifyOutlets Holdings Global Sourcing Limited (0008945) is authorised and regulated by the Financial Conduct Authority as a credit broker and works exclusively with lender Home Retail Group CardServices Limited trading as shopifyoutlets Business Financial Services, which is authorised and regulated by the Financial Conduct Authority to provide credit. Home Retail GroupCard Services Limited is registered in England (04007072) and its address is Avebury, 489-499Avebury Boulevard, Milton Keynes, United Kingdom. MK9 2NW. ',

  '_dk._cyzjsq': 'Entrepreneurship loan application form',
  '_dk._dkll': 'Loan interest rate',
  '_dk._dkll2': 'Loan interest rate description (click to display)',
  '_dk._gs1': 'Formula',
  '_dk._gs2': 'Interest',
  '_dk._gs3': 'Loan amount',
  '_dk._gs4': 'Annual interest rate',
  '_dk._gs5': 'Loan days',
  '_dk._gs6': 'For example',
  '_dk._gs7': 'Loan amount',
  '_dk._gs8': 'Loan period',
  '_dk._gs9': 'Days',
  '_dk._fkfs': 'Loan method',
  '_dk._zhye': 'Account balance',
  '_dk._dkqx': 'Loan term',
  '_dk._dkqx1': 'Select loan term',
  '_dk._sqje': 'Application amount',
  '_dk._qsrdk': 'Please enter the loan',
  '_dk._dkje': 'Loan amount',
  '_dk._gj': 'Nationality',
  '_dk._qxzgj': 'Please select nationality',
  '_dk._sqr': 'Applicant',
  '_dk._qsrsqsm': "Please enter the applicant's name",
  '_dk._zjhm': 'ID number',
  '_dk._qsrzjhm': 'Please enter your ID number',
  '_dk._zjzp': 'ID photo',
  '_dk._zjzp1': 'Please upload a photo of the front of your ID',
  '_dk._zjfm1': 'Please upload a photo of the back of your ID',
  '_dk._zjsc1': 'Please upload a photo of you holding your ID',
  '_dk._zjzp2': 'Select a picture',
  '_dk._pzslt': 'Photo example',
  '_dk._wytyb': 'I have read and agreed',
  '_dk._jkxy': 'Loan agreement',
  '_dk._tjsq': 'Submit application',

  '_dk._wddk2': 'My loan',
  '_dk._ydk': 'Loaned',
  '_dk._cslx': 'Interest generated',
  '_dk._yjhk': 'Expected repayment',
  '_dk._dkjl': 'Loan record',
  '_dk._hkjl': 'Repayment record',
  '_dk._sqrq': 'Application date',
  '_dk._hkzq': 'Repayment period',
  '_dk._gdll': 'Fixed interest rate',
  '_dk._hkz': 'Repaying',
  '_dk._yhk': 'Repaid',
  '_dk._sqz': 'Applying',
  '_dk._ytg': 'Approved',
  '_dk._ybh': 'Rejected',
  '_dk._tqhk': 'Repay in advance',

  '_dk._ljhk': 'Repay immediately',
  '_dk._qrytqhk': 'Are you sure you want to apply for repayment in advance',
  '_dk._qrytqhk1': 'Note: After the application is successful, the amount of the deposit will be directly used to repay the loan first',
  '_dk._dkxq': 'Loan details',
  '_dk._dkje1': 'Loan amount',
  '_dk._yhlx': 'Interest payable',
  '_dk._hkze': 'Total repayment amount',
  '_dk._sqhkz': 'Applying for repayment',
  '_dk._zxydbty': 'Please read carefully and tick the box to agree to the "Loan Agreement"',
  '_dk._zzsqz': 'Applying',

  '_dk._dksq': 'Credit business',

  '_dk._cs': 'Parameters',
  '_dk._nllsm': 'Expressed as a percentage, for example: 6.25%',
  '_dk._dktssm': 'Total number of days from the beginning to the end of the loan',
  '_dk._yndts': 'Number of days in a year: usually 365 days',
  '_dk._zlj': 'Late payment fee',

  '_xbb._xz': 'Download',
  '_xbb._xzapp': 'Download ShopifyOutlets App',
  '_xbb._smewm': 'Scan QR code to download',
  '_xbb._syfl': 'All categories',
  '_xbb._gdfl': 'More',
  '_xbb._sbbt1': 'Fast delivery',
  '_xbb._sbbt2': 'Refunds for any issues',
  '_xbb._sbbt3': 'Free returns',
  '_xbb._sbbt4': 'Within 90 days',
  '_xbb._tjsp1': 'Recommended products',
  '_xbb._tjsp2': 'Many discounted products',
  '_xbb._tjsp3': 'Limited Time Discount',

  '_xbb._asjgw': 'Shop by Store',
  '_xbb._gdsp': 'More Items',
  '_xbb._ljgm': 'Buy Now',
  '_xbb._ptbz': 'ShopifyOutlets Guarantee',
  '_xbb._aqzf': 'Secure Payment',
  '_xbb._aqzf1': 'Payment method used by many international shoppers',
  '_xbb._aqys': 'Security and Privacy',
  '_xbb._aqys1': 'We respect your privacy, so your personal information is safe',
  '_xbb._mjbh': 'Buyer Protection',
  '_xbb._mjbh1': 'If your order is not delivered by the estimated date or you are not satisfied with the order, you can get a refund',
  '_xbb._mzsm': 'Warning/Disclaimer',

  '_xbb._sjxx': 'Merchant',
  '_xbb._ggms': 'Specifications',
  '_xbb._mswa': 'Description',
  '_xbb._khpl': 'Customer Reviews',
  '_xbb._zmfk': 'Positive Feedback',
  '_xbb._gzrs': 'Follow',
  '_xbb._gzrsan': 'Follow',
  '_xbb._ygzrsan': 'Already Followed',
  '_xbb._fsxx': 'Message',
  '_xbb._myrpl': 'No one has commented yet',
  '_xbb._llgd': 'View more',
  '_xbb._pdcl': 'Promise',
  '_xbb._myf': 'Free shipping',
  '_xbb._sdsj': 'Delivered in about 5-10 days',
  '_xbb._ksjh': 'Fast delivery',
  '_xbb._bgdstk': 'Refund if package lost',
  '_xbb._shbgtk': 'Refund if item is damaged',
  '_xbb._s30gtk': 'Refund if not shipped within 30 days',
  '_xbb._aqyys': 'Security and Privacy',
  '_xbb._wjtrxk': 'Secure payment: We will not share your personal information with any third party without your consent. ',
  '_xbb._sfgrxx': 'Secure Personal Details: We protect your privacy and keep your personal details safe and secure. ',
  '_xbb._sl': 'Quantity',
  '_xbb._kcld': 'Inventory',

  '_xbb._sjrz': 'Merchant Settlement',
  '_xbb._hezjh': 'Our partner program provides you with a full range of marketing support and services. Our customer service team will provide professional support and advice to help you optimize your product display and sales strategy. Join us now! Let us create greater business opportunities and grow together! ',
  '_xbb._xsje': 'Business information',
  '_xbb._rgsqjs': 'If you are already a seller',
  '_xbb._qni': 'Please',
  '_xbb._djdl': 'Click to log in',
  '_xbb._dplog': 'Shop LOGO',
  '_xbb._dpmc': 'Shop name',
  '_xbb._srdpmc': 'Please enter the shop name',
  '_xbb._zjhm': 'ID number',
  '_xbb._srzjhm': 'Please enter the ID number',
  '_xbb._zsxm': 'Real name',
  '_xbb._srzsxm': 'Please enter the real name',
  '_xbb._srgj': 'Country',
  '_xbb._srsrgj': 'Please enter your country',
  '_xbb._zjz': 'ID photo',
  '_xbb._zjzm': 'Front of ID',
  '_xbb._zjfm': 'Back of ID',
  '_xbb._sczjz': 'Handheld ID photo',
  '_xbb._pssl': 'Photo example',
  '_xbb._dlyx': 'Login email',
  '_xbb._srdlyx': 'Please enter your login email',
  '_xbb._dlmm': 'Login password',
  '_xbb._srdlmm': 'Please enter your login password',
  '_xbb._qrmm': 'Confirm password',
  '_xbb._srqrmm': 'Please enter the confirmation password',
  '_xbb._yqm': 'Invitation code',
  '_xbb._sryqm': 'Enter the invitation code',
  '_xbb._yxyzm': 'Email verification code',
  '_xbb._sryzm': 'Enter the email verification code',
  '_xbb._yydbty': 'I have read and agreed',
  '_xbb._rzxy': 'Settlement Agreement',

  '_xbb._qgxty': 'Please read the entry agreement carefully and tick the box to agree',
  '_xbb._sryqmb': 'Please enter the invitation code',

  '_xbb._ddpl': 'Comments on the order',
  '_xbb._dsppl': 'Comments on the product',
  '_xbb._dddpl': 'Comments on the order',

  '_xhd._sdj': 'Christmas'
}
