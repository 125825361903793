export default {
  'nav.account': 'Centro Pessoal',
  'nav.language': 'Chinês simplificado',
  'signup.success': 'Inscrição bem-sucedida',

  //erro
  'contact.empty': 'As informações de contato não podem estar vazias',
  'phone.country.empty': 'O prefixo do telefone não pode estar vazio',
  'phone.empty': 'O telefone não pode estar vazio',
  'email.valid': 'Por favor, digite o e-mail correto',

  // base
  'base.success': 'sucesso',
  'base.save': 'Salvar',
  'base.slide': 'Puxe o controle deslizante para verificar',
  'base.send.code': 'Enviar código de verificação',
  'base.send.time': '({time}) reenviar código de verificação',
  'base.send.success': 'Enviado com sucesso',
  'base.view.more': 'Ver mais',
  'base.Language': 'Idioma',
  'base.previous': 'Página anterior',
  'base.next': 'Próxima página',
  'base.total.page': 'Total de {page} páginas',
  'base.go.to.page': 'Ir para a página',
  'base.go': 'ir para',
  'base.followers': 'Seguidores',
  'base.follow': 'seguir',
  'base.following': 'Seguido',
  'base.visit.store': 'Navegar na loja',
  'base.contact': 'Fale conosco',
  'base.delivery': 'Tempo estimado de entrega: 1-7 dias',
  'base.search': 'Pesquisar',
  'base.search.text': 'Digite a pesquisa por palavra-chave',
  'base.server': 'Atendimento ao cliente online',

  //índice
  'text.categories.title': 'Categoria do produto',
  'text.welcome': 'Bem-vindo às compras!',
  'text.wishlist': 'Lista de curtidas',
  'text.account': 'Central Pessoal',
  'text.orders': 'Pedidos',
  'text.messages': 'Mensagens',
  'text.suggest.title': 'Oferta exclusiva',
  'text.suggest.content': 'Apenas para novos membros premium globais!',
  'text.super.deals': 'Os melhores produtos, preços inacreditáveis.',

  //inscrever-se
  'signup.register': 'Registrar',
  'signup.signin': 'Login',
  'signup.store.signin': 'Login comercial',
  'signup.sign.out': 'Sair',
  'signup.email': 'Endereço de e-mail',
  'signup.password': 'Senha',
  'signup.qr.password': 'Confirmar senha',
  'signup.confirm.password': 'Confirmar senha',
  'signup.forgot.password': 'Esqueci a senha',
  'signup.invitation': 'Código de convite',
  'signup.char': 'Caractere',
  'signup.contains': 'Contém números, letras ou símbolos',
  'signup.qr.contains': 'As duas senhas são inconsistentes',
  'signup.create.account': 'Criar conta',
  'signup.agree': 'Criar uma conta, você concorda',
  'signup.member.agreement': 'Contrato de Associação',
  'cadastro.e': 'e',
  'signup.privacy.policy': 'Política de Privacidade',
  'signup.email.code': 'Verificação de e-mail',
  'signup.last.step': 'Última etapa',
  'signup.send.email': 'Por favor, digite o código de 4 dígitos enviado para {email}',
  'signup.modify.email': 'Modificar e-mail',
  'signup.verify.email': 'Verificar e-mail',
  'signup.have.store': 'Tem conta de comerciante',
  'signup.goto.signin': 'Login comercial',
  'signup.no.store': 'Sem conta de comerciante',
  'signup.goto.store': 'Cadastre-se como comerciante',
  'signup.next': 'Próximo',
  'signup.your.email': 'seu e-mail',
  'signup.code.text': 'Código de verificação',
  'signup.submit.signup': 'Inscreva-se agora',
  'signup.smrz': 'Autenticação de nome real',
  'signup.derb': 'Segundo passo',
  'signup.qsrxm': 'Por favor, digite o nome do documento',
  'signup.qsrhm': 'Por favor, digite o número de identificação',

  //esquecido
  'forgot.title': 'Redefinir senha',
  'forgot.btn.check': 'Verificar e-mail',
  'forgot.reset.now': 'Reiniciar agora',

  //loja
  'store.info.open': 'Aberto {y} semanas',

  // rodapé
  'footer.great.value': 'Grande valor',
  'footer.great.value.desc': 'Oferecemos preços competitivos para mais de 100 milhões de itens.',
  'footer.shopping': 'Compras globais',
  'footer.shopping.desc': 'Enviamos para mais de 200 países e regiões, e nosso site está disponível em 7 idiomas.',
  'footer.safe.payment': 'Pagamento Seguro',
  'footer.safe.payment.desc': 'Pague com os métodos de pagamento mais populares e seguros do mundo.',
  'footer.shop.with.trust': 'Compre com confiança',
  'footer.shop.with.trust.desc': 'Nossa política de proteção ao comprador cobre todo o seu processo de compra.',
  'footer.help.center': 'Central de Ajuda',
  'footer.help.center.desc': 'Assistência em qualquer clima para criar uma experiência de compra tranquila.',
  'footer.terms.conditions': 'Termos e Condições',
  'footer.return.policy': 'Política de Devolução',
  'footer.support.policy': 'Política de Suporte',
  'footer.privacy.policy': 'Política de Privacidade',
  'footer.be.seller': 'Torne-se um vendedor',
  'footer.apply.now': 'Aplicar agora',
  'footer.stay.connected': 'Mantenha-se conectado',

  'footer.about.us': 'Sobre nós',
  'footer.about.company': 'Perfil da Empresa',
  'footer.about.video': 'Introdução do vídeo',
  'footer.contact': 'Informações de contato',

  'footer.my.account': 'Minha conta',
  'footer.my.logout': 'Sair',
  'footer.my.order': 'Histórico de Pedidos',
  'footer.my.wish': 'Minha lista de desejos',
  'footer.my.join': 'Torne-se um parceiro membro',
  'footer.email': 'E-mail',
  'footer.gfemail': 'E-mail oficial',
  'footer.fwemail': 'E-mail de serviço',
  'footer.address': 'Endereço',

  'apply.success': 'Aplicativo bem-sucedido',
  'apply.success.desc': 'Aplicado com sucesso, vá para a conta de login',

  // categorias
  'category.title': 'Categorias relacionadas',
  'category.all': 'Todas as categorias',

  //detalhe
  'detail.store.home': 'Página inicial da loja',
  'detail.sale.items': 'Lista de produtos',
  'detail.recommend': 'Recomendo',
  'detail.orders': 'Vendas',
  'detail.quantity': 'Quantidade',
  'detail.pieces.available': 'Disponível',
  'detail.delivery': 'Entrega',
  'detail.free.shipping': 'Frete Grátis',
  'detail.estimated.delivery': 'Chegada estimada',
  'detail.days': 'dias',
  'detail.buy.now': 'Comprar',
  'detail.add.to.cart': 'Adicionar ao carrinho',
  'detail.buyer.protection': 'Proteção do Comprador',
  'detail.money.guarantee': 'Garantia de devolução do dinheiro',
  'detail.refund.desc': 'Receba um reembolso total se o item não for conforme descrito ou não for entregue',
  'detail.description': 'Descrição do produto',
  'detail.customer.reviews': 'Avaliações de clientes',
  'detail.specifications': 'Especificações do produto',
  'detail.top.selling.products': 'Produtos mais vendidos',
  'detail.recommended.for.you': 'Recomendado para você',
  'detail.sold': 'Vendas',
  'detail.recipt': 'Confirmar recebimento',
  'detail.recipt.title': 'Confirmar recebimento do pedido? ',
  'detail.receipt.content': 'Após a confirmação, o pedido de transação é concluído',
  'detail.comment': 'Comentário',
  'detail.refund.title': 'Confirmar pedido de reembolso',
  'detail.refund.content': 'Após a confirmação, o pedido será reembolsado',
  'detail.sqtk': 'Solicitar reembolso',

  //confirme
  'confirm.shipping.address': 'Endereço do destinatário',
  'confirm.change': 'Alterar',
  'confirm.payment.methods': 'Formas de pagamento',
  'confirm.summary': 'Liquidação',
  'confirm.total.item.costs': 'Total de itens',
  'confirm.total': 'Total',
  'confirm.checkout': 'Ir para checkout',
  'confirm.place.order': 'Faça um pedido agora',
  'confirm.pay.now': 'Pagar agora',
  'confirm.order.desc': "Após clicar em 'Fazer pedido', confirmo que li e confirmo",
  'confirm.order.policy': 'Todos os termos e políticas',
  'confirm.payment': 'Global Shopping garante a segurança de suas informações e pagamentos',

  // endereço
  'address.title': 'Endereço de recebimento',
  'endereço.padrão': 'padrão',
  'address.edit': 'Editar',
  'address.delete': 'Excluir',
  'address.new': 'Criar um novo endereço',
  'endereço.contato': 'Contato',
  'endereço.endereço': 'Endereço',
  'address.phone': 'Número de telefone',
  'address.set.default': 'Definir padrão',
  'address.confirm': 'Confirmar',
  'address.cancel': 'Cancelar',
  'address.del.title': 'Confirmar a exclusão do endereço?',
  'address.del.content': 'Tem certeza que deseja excluir este endereço de entrega?',

  'payment.method': 'Forma de pagamento',

  // Carrinho de compras
  'shopping.title': 'Carrinho de compras',
  'shopping.back.buy': 'Voltar às compras especiais',
  'shopping.start.shopping': 'Começar a comprar',

  // membro
  'member.account': 'Centro Pessoal',
  'member.overview': 'Visão geral',
  'member.orders': 'Pedidos',
  'member.payment': 'Minha Carteira',
  'member.address': 'Endereço de recebimento',
  'member.wishlist': 'Lista de curtidas',
  'member.followlist': 'Lista de seguidores',
  'member.message.center': 'Central de Mensagens',
  'member.setting': 'Configurações pessoais',
  'member.shop.info': 'Informações da loja',
  'member.shop.setting': 'Configurações da loja',
  'member.order.notify': 'Nova notificação de pedido',
  'member.order.tips': 'Ao enviar e-mails para o e-mail da conta do comerciante',

  //membro.ordem
  'member.order.title': 'Meu pedido',
  'member.order.view.all': 'Ver tudo',
  'member.order.all': 'Todos',
  'member.order.unpaid': 'não pago',
  'member.order.paid': 'Pago',
  'member.order.procurement': 'a ser comprado',
  'member.order.seller.paid': 'Comprado',
  'member.order.processing': 'a ser processado',
  'member.order.shipped': 'enviado',
  'member.order.return': 'Retornando',
  'member.order.completed': 'Concluído',
  'member.order.refund': 'Reembolsado',
  'member.order.all.time': 'todos',
  'member.order.empty': 'Nenhum pedido ainda',
  'membro.pedido.data': 'data',
  'member.order.purchase.date': 'Compra',
  'member.order.cpoy': 'Copiar',
  'membro.pedido.id': 'Pedido',
  'member.order.detail': 'Detalhes do pedido',
  'member.order.logistics': 'Informações de logística',

  // membro. pagamento
  'member.payment.title': 'Minha Carteira',
  'member.wallet.balance': 'saldo da carteira',
  'member.crypto.recharge': 'Recarregar',
  'member.crypto.withdrawal': 'Retirada',
  'member.crypto.bank': 'Cartão bancário',
  'member.wallet.record': 'registro de depósito de moeda',
  'member.bankcard.record': 'Registro do cartão bancário',
  'member.withdrawal.record': 'Registro de retirada',
  'member.income.record': 'registro de renda',
  'member.transaction.record': 'Registro de transação',
  'member.wallet.freeze': 'Congelar fundos',
  'member.wallet.profit': 'Ganhos estimados',

  //recarrega
  'recharge.currency': 'Moeda',
  'recharge.protocol': 'Protocolo',
  'recharge.qrcode': 'Código QR',
  'recharge.address': 'Endereço da carteira',
  'recharge.copy': 'Copiar endereço',
  'recharge.ok.text': 'Confirmar',
  'recharge.cancel.text': 'Cancelar',

  //banco
  'bank.recharge.title': 'Recarga de cartão bancário',
  'bank.title': 'Nome do banco',
  'bank.name': 'nome',
  'bank.account': 'Conta',
  'bank.routing': 'Roteamento',
  'bank.code': 'código',
  'bank.bankname': 'Nome do banco',
  'bank.bankaddress': 'Endereço do banco',
  'bank.ok.text': 'Avançar',
  'bank.ok.prev': 'Etapa anterior',
  'bank.submit': 'Enviar agora',
  'bank.amount': 'Valor da recarga',
  'bank.amount.desc': 'Insira o valor da recarga',
  'banco.tipo': 'tipo',
  'bank.usd': 'USD',
  'bank.eur': 'Euro',
  'bank.recipt.number': 'Número do recibo',
  'bank.recipt.number.desc': 'Por favor, digite o número do recibo da transação',
  'bank.credential.picture': 'Imagem da credencial',
  'bank.credential.picture.desc': 'Favor enviar a imagem da credencial',
  'bank.remark': 'Observação',
  'bank.upload': 'Enviar fotos',
  'bank.text.title': 'Notas',
  'bank.text.t1': 'Não precisa de comentários para transferência, deixe em branco',
  'bank.text.t2': 'Selecione Hong Kong como o país da família de Hong Kong, não adicione China',
  'bank.text.t3': 'Remessas dos EUA, durante a semana, remessas para a Europa antes das 15h, remessas disponíveis durante o horário de expediente do banco!',
  'bank.text.t4': 'O tempo de pagamento é T+1, e o último pagamento é T+3 na Europa',
  'bank.text.t5': 'Informe a plataforma antes de transferir dinheiro para confirmar se a conta está disponível. Se a conta for encerrada, nenhuma compensação será paga.',
  'bank.text.t6': 'Depósito via cartão bancário. As informações de atendimento ao cliente devem prevalecer.',

  //lista bancária
  'bank.list.title': 'Nome do banco',
  'bank.list.code': 'Código do banco',
  'bank.list.amount': 'Valor da transferência',
  'bank.list.number': 'Número do recibo',
  'bank.list.img': 'Imagem do voucher',
  'bank.list.status': 'Status',
  'bank.list.time': 'hora',
  'bank.list.status1': 'Em análise',
  'bank.list.status2': 'Aprovado',
  'bank.list.status3': 'Rejeitado',

  // Cancelamento
  'withdrawal.address.desc': 'Por favor, insira o endereço da carteira!',
  'withdrawal.number': 'Número',
  'withdrawal.real.number': 'chegada real',
  'withdrawal.number.desc': 'Por favor, insira o valor do saque!',
  'withdrawal.btn.all': 'todos',
  'withdrawal.balance': 'Saldo',
  'withdrawal.commission': 'comissão',
  'withdrawal.actual.amount': 'Contabilidade',
  'withdrawal.notice': 'Prompt',
  'withdrawal.notice.text': 'Antes de transferir, confirme se as informações do endereço de recebimento estão corretas. Depois que os ativos forem transferidos, eles não poderão ser devolvidos.',
  'withdrawal.notice.content': '{name}({cp_name}) comissão: valor de mercado atual {fee_rate}%/pen, padrão mínimo: {fee_min} {name}/pen',
  'withdrawal.submit': 'Enviar',
  'withdrawal.choice': 'Escolha uma criptomoeda',
  'withdrawal.yzm': 'Código de verificação',
  'withdrawal.fs': 'Enviar',
  'withdrawal.qsryzm': 'Por favor, insira o código de verificação de e-mail',

  // recebe
  'recive.method': 'Protocolo',
  'receber.amount': 'Valor',
  'receber.address': 'Endereço',
  'receber.date': 'hora',
  'recive.status': 'Estado',
  'recive.create.at': 'Tempo de transação',
  'receber.type': 'Tipo',
  'receber.befor': 'antes da transação',
  'recive.balance': 'Saldo',
  'recive.freeze': 'Congelar',
  'recive.review': 'Revisando',
  'receber.success': 'Aprovado',
  'recive.reject': 'Rejeitado',

  // anunciar
  'advertise.title': 'Centro de promoções',
  'advertise.shop.title': 'Promoção da loja',
  'advertise.shop.status': 'Status',
  'advertise.shop.expired': 'Expirou',
  'advertise.shop.promotion': 'Promoção',
  'advertise.shop.expire.date': 'Data de vencimento',
  'advertise.shop.renew': 'Renovação de anúncio',
  'advertise.shop.payable': 'Valor a Pagar',
  'advertise.shop.explanation': 'Explicação do anúncio',
  'advertise.shop.text': 'Participe da promoção de publicidade da plataforma, aumente a exposição da loja e promova pedidos de transação',
  'advertise.shop.paynow': 'Renovar agora',
  'advertise.shop.modal.title': 'Confirmar pagamento',
  'advertise.shop.modal.desc': 'Confirmado para pagar a taxa de promoção',
  'advertise.shop.modal.btn': 'Confirmar Pagamento',

  //Lista de Desejos
  'lista de desejos.title': 'lista de desejos',
  'lista de desejos.delete': 'Excluir',
  'lista de desejos.pedidos': 'Vendas',

  //Seguir lista
  'followlist.title': 'Lista de seguidores',
  'followlist.delete': 'Excluir',
  'followlist.follow': 'Seguir',

  // loja
  'store.dashboard': 'Painel',
  'store.products': 'Produtos',
  'store.products.list': 'Lista de produtos',
  'store.products.reviews': 'Avaliações de produtos',
  'store.orders': 'Pedidos',
  'store.wallet': 'Carteira',
  'store.message': 'Central de Mensagens',
  'store.setting': 'configuração',
  'store.order.total.profit': 'Receita operacional total estimada',

  //painel
  'dashboard.store.hour.views': 'Visitas em tempo real',
  'dashboard.store.today.views': 'Visitas de hoje',
  'dashboard.product.total': 'Total do produto',
  'dashboard.product.today': 'Novo hoje',
  'dashboard.order.total': 'Pedido Total',
  'dashboard.sales.total': 'Venda estimada',
  'dashboard.sales.real': 'Vendas reais',
  'dashboard.sales.pay': 'Pagamento Total',
  'dashboard.sales.profit': 'Lucro Total',
  'dashboard.commission.total': 'Receita total',
  'dashboard.commission.today': 'Ganhos de hoje',
  'dashboard.order.sales': 'Vendas de mercadorias',
  'dashboard.sales.list': 'Classificação de vendas',
  'dashboard.goods.cate.rate': 'Relação de classificação do produto',
  'dashboard.goods.wish': 'Like product ranking',
  'dashboard.january': 'janeiro',
  'dashboard.february': 'fevereiro',
  'dashboard.março': 'março',
  'dashboard.april': 'abril',
  'dashboard.may': 'Maio',
  'dashboard.june': 'Junho',
  'dashboard.july': 'Julho',
  'dashboard.august': 'Agosto',
  'dashboard.setembro': 'Setembro',
  'dashboard.october': 'Outubro',
  'dashboard.november': 'Novembro',
  'dashboard.december': 'Dezembro',

  // produtos
  'products.add.new': 'Adicionar novo produto',
  'products.add.from.warehouse': 'Adicionar produtos do armazém',
  'products.delete': 'Excluir',
  'products.add': 'Adicionar',
  'products.table.img': 'imagem',
  'products.table.name': 'nome do produto',
  'products.table.category': 'Categoria',
  'products.table.wish': 'curtir',
  'products.table.stock': 'estoque',
  'products.table.price': 'Preço',
  'products.table.profit': 'Lucro',
  'products.table.action': 'Ação',
  'products.search.category': 'Pesquisar por categoria',
  'products.back.product': 'Voltar à lista de produtos',
  'products.total': 'Total de mercadorias',
  'products.yes': 'Sim',
  'products.no': 'Cancelar',
  'products.batch.add': 'Aumento de lote',
  'products.ask.add': 'Tem certeza que deseja adicionar produtos?',
  'products.batch.delete': 'Excluir lote',
  'products.ask.delete': 'Tem certeza que deseja excluir o produto?',
  'products.top': 'Top',
  'products.syzd': 'Topo da página inicial',
  'products.zdwz': 'Posição superior',
  'products.t1': 'Promoção',
  'products.t2': 'Preferido',
  'products.t3': 'Boutique',
  'produtos.t4': 'nenhum',

  // avaliações
  'reviews.title': 'Reviews do produto',
  'reviews.product.name': 'Produto',
  'reviews.user.name': 'Usuário',
  'reviews.star': 'Avaliação',
  'reviews.comment': 'conteúdo do comentário',
  'reviews.sku': 'Especificações do produto',
  'reviews.imgs': 'Atlas',
  'reviews.created': 'Data',

  // loja. pedido
  'store.order.purchase': 'Compre agora',
  'store.order.purchase.desc': 'Tem certeza que deseja pagar por este produto?',
  'store.order.purchase.yes': 'Pague agora',
  'store.order.purchase.no': 'Cancelar',
  'store.order.desc': 'A compra deste pedido requer pagamento e você receberá benefícios após a conclusão do pedido. ',
  'store.order.no': 'Número do pedido',
  'store.order.number': 'Quantidade',
  'store.order.buyer': 'Comprador',
  'store.order.total': 'Valor total',
  'store.order.will.earning': 'Receita',
  'store.order.profit': 'Lucro',
  'store.order.dividends': 'Dividendos',
  'store.order.payment.status': 'Status do Pagamento',
  'store.order.seller.buy.status': 'Status da compra',
  'store.order.status': 'Status do pedido',
  'store.order.date': 'data do pedido',
  'store.order.purchase.date': 'Data da compra',
  'store.order.action': 'Operação',
  'store.order.purchase.amount': 'Valor da compra',

  //renda
  'income.create.at': 'Tempo de registro',
  'income.order.sn': 'Número do pedido',
  'income.realpay': 'Valor do pedido',
  'receita.lucro': 'receita',

  //Contexto
  'setting.avatar': 'Avatar do negócio',
  'setting.upload': 'Carregar',
  'setting.shop.name': 'Nome da loja',
  'setting.shop.phone': 'Telefone de contato',
  'setting.shop.address': 'Endereço da loja',
  'setting.shop.save': 'Salvar',
  'setting.upload.pic': 'Carregar imagem',
  'setting.send.pic': 'Enviar foto',

  //vendedor
  'beseller.title': 'Inscreva-se para ser um comerciante',
  'beseller.account': 'Informações da conta',
  'beseller.store': 'Informações da loja',
  'beseller.store.name': 'Nome da loja',
  'beseller.store.address': 'Endereço da loja',

  //salvarcasa
  'store.home.title': 'Configurações da página inicial',
  'store.home.topimg': 'imagem superior',
  'store.home.banner': 'Imagem do carrossel',
  'store.home.up3': 'Observações: Pelo menos 3 uploads',
  'store.upload.more': 'Carregue mais imagens',
  'store.home.columns': 'Colunas',
  'store.home.bgimg': 'Imagem de fundo',
  'store.goods.remark': 'Observação: 5 itens por linha, 10 itens por prato no máximo',
  'store.home.select': 'Selecionar produto',
  'store.home.add': 'Adicionar coluna',

  //informações pessoais
  'setting.update.user': 'Informações pessoais',
  'setting.update.info': 'Modificar informações',
  'setting.user.avatar': 'Avatar do usuário',
  'setting.user.nickname': 'Apelido do usuário',
  'setting.user.nickname.desc': 'Por favor, digite o apelido do usuário',

  'setting.safe.title': 'Informações de segurança',
  'setting.user.passwd': 'Alterar senha',
  'setting.passwd.title1': 'Senha Antiga',
  'setting.passwd.desc1': 'Por favor, digite a senha antiga',
  'setting.passwd.title2': 'Nova Senha',
  'setting.passwd.desc2': 'Por favor, digite uma nova senha',
  'setting.passwd.title3': 'Confirmar senha',
  'setting.passwd.desc3': 'Por favor, digite uma senha de confirmação',
  'setting.invitation': 'Convidar amigos',
  'setting.copy': 'Copiar',

  'adv.add.products': 'Adicionar produtos promocionais',
  'adv.list.title': 'Promoção do produto',
  'adv.type': 'Tipo de promoção',
  'adv.begin.time': 'Tempo da promoção',
  'adv.end.time': 'Hora de término',
  'adv.status': 'Status',
  'adv.status.s1': 'Promovendo',
  'adv.status.s2': 'Fim',
  'adv.add.back': 'Retornar à lista de promovidos',
  'adv.recharge': 'Pontos de promoção de recarga',
  'adv.select.goods': 'Selecionar produtos',
  'adv.select.btn': 'Produtos Promovidos',
  'adv.recharge.title': 'Pontos de promoção de recarga',
  'adv.recharge.balance': 'Saldo de pontos',
  'adv.point': 'ponto',
  'adv.point.rate': 'Relação de pontos',
  'adv.recharge.amount': 'Valor da recarga',
  'adv.recharge.input.amount': 'Digite o valor da recarga',
  'adv.select.mod': 'Selecionar painel',
  'adv.mod1': 'Colunas recomendadas',
  'adv.mod2': 'Coluna preferida',
  'adv.mod3': 'Coluna de venda quente',
  'adv.mod4': 'Excelente lista',
  'adv.mod5': 'Lista de classificação',
  'adv.mod6': 'Promoção Detalhada',
  'adv.ge': 'Quantidade',
  'adv.xs': 'hora',
  'adv.xzbk': 'Selecionar prato',
  'adv.syme': 'Cota restante',
  'adv.tgsc': 'Duração da promoção',
  'adv.tgjg': 'Preço promocional',
  'adv.syye': 'Saldo atual',
  'adv.tgye': 'Saldo da promoção',
  'adv.yj1': 'esperado',
  'adv.yj2': 'Iniciar promoção',
  'adv.pay.point': 'Pontos de consumo',

  'wallet.ykcsxf': 'A taxa de manuseio foi deduzida',
  'wallet.sxf': 'Taxa de serviço',
  'wallet.zxje': 'Valor mínimo',
  'wallet.24hxe': 'limite de 24 horas',
  'wallet.desc.text': 'Sob nenhuma circunstância, o oficial não pedirá que você transfira dinheiro para uma conta, nem pedirá um código de verificação. Não participe de atividades ilegais, como compras em nome de terceiros, lavagem de dinheiro e arrecadação ilegal de fundos, e tome cuidado com fraudes on-line',
  'wallet.cunbi.text': 'Você só pode depositar {name} neste endereço, outros ativos não serão recuperados',

  'credit.title': 'Limite de Crédito',
  'credit.ed': 'Cota',
  'credit.hk': 'Reembolso',
  'credit.kyye': 'Saldo disponível',
  'credit.dqqk': 'Atraso atual',
  'credit.hkje': 'Valor do reembolso',
  'credit.hkje.desc': 'Insira o valor do reembolso',
  'credit.cgje': 'Valor da compra',
  'credit.qrz': 'Ir para autenticação',
  'credit.srxyj': 'Digite o valor do pagamento do crédito',

  'store.gzl': 'Quantidade de atenção',
  'store.wxz': 'Ilimitado',

  'auth.smrz': 'Autenticação de nome real',
  'auth.rzlx': 'Tipo',
  'auth.sfzm': 'frente do cartão de identificação',
  'auth.sffm': 'O verso do cartão de identificação',
  'auth.zsxm': 'nome real',
  'auth.zjhm': 'Número do documento',
  'auth.yyzz': 'Licença comercial',
  'auth.gsmc': 'Nome da empresa',
  'auth.shz': 'Auditoria',
  'auth.ytg': 'Aprovado',
  'auth.ybh': 'Rejeitado',
  'auth.zt': 'estado',
  'auth.gr': 'Pessoal',
  'auth.gs': 'Empresa',
  'auth.ljtj': 'Aplicar para autenticação',
  'auth.wrz': 'Não autenticado',

  'credit.qts': 'Ver descrição',
  'store.spss': 'Limite superior da mercadoria',
  'store.dpfh': 'Armazenar dividendos',
  'store.qydj': 'Nível de patrimônio',
  'store.level': 'nível',
  'store.jian': 'peças',

  'order.notify.email': 'Devido ao controle de risco de e-mail, os e-mails de lembrete de pedido da plataforma serão interceptados com frequência. Para recebê-los a tempo, é recomendável adicionar {e} à lista de permissões de e-mail de login',

  'setting.sjxx': 'Informações comerciais',
  'setting.smrz': 'Autenticação de nome real',
  'setting.aqzx': 'Central de Segurança',
  'setting.tzxx': 'Informações de notificação',
  'setting.dpzx': 'Página inicial de decoração',
  'setting.sjqy': 'Interesses comerciais',
  'setting.sjdjqy': 'Descrição dos benefícios de nível de negócios',

  'setting.fhbky': 'O dividendo atual não está disponível',
  'setting.spgzyzy': 'Regras e diretrizes da loja',

  'desc.dqqy': 'Patrimônio atual',
  'desc.xjqy': 'Interesses subordinados',
  'desc.sjqy_0': 'Limite de crédito da loja',
  'desc.sjqy_1': 'Armazenar dividendos',
  'desc.sjqy_2': 'Número de produtos que podem ser publicados',

  'share.yqlb': 'Lista de convites',
  'share.zmj': 'Vendedor total',
  'share.zdd': 'Pedido total',
  'share.zsy': 'Receita total de corretagem',
  'share.wcl': 'não processado',
  'share.ycl': 'Processado',
  'share.ywc': 'Concluído',
  'share.dpmc': 'Nome da loja',
  'share.ddl': 'Quantidade do Pedido',
  'share.jjsy': 'Receita de corretagem',
  'share.zts': 'Número total',

  'chat.estado': 'estado',
  'chat.zx': 'On-line',
  'chat.ljz': 'Conectando',

  'bind.wallet.title': 'Endereço de retirada',
  'bind.address.title': 'Adicionar endereço',
  'bind.bj': 'Editar',
  'bind.sc': 'Excluir',
  'bind.qrsc': 'Tem certeza que deseja deletar este endereço?',
  'bind.qbdz': 'Endereço da carteira',
  'bind.tjsj': 'Adicionar horário',
  'bind.cz': 'Operação',
  'withdrawal.address.choice': 'Por favor, escolha o endereço de retirada!',

  'order.sxsj': 'Tempo efetivo',
  'order.ljsj': 'imediatamente efetivo',
  'order.dssj': 'Efetivo cronometrado',

  'store.st.zt': 'Status da loja',
  'store.st.zc': 'normal',
  'store.st.zc.desc': 'Sua loja está normal, por favor continue assim',
  'store.st.yc': 'Exceção',
  'store.st.yc.desc': 'Sua loja tem alguns pedidos anormais ou recebeu reclamações de clientes',
  'store.st.dj': 'Congelar',
  'store.st.dj.desc': 'Sua loja tem um certo risco operacional e os fundos estão congelados',
  'store.st.jy': 'desativado',
  'store.st.jy.desc': 'Sua loja violou os acordos e regras relacionados à plataforma e foi desativada',

  'task.sy': 'Página inicial',
  'task.rwlbo': 'Lista de tarefas',
  'task.rwxqo': 'Detalhes da tarefa',
  'task.ljcy': 'Participe agora',
  'task.gdrw': 'Mais tarefas',
  'task.rwlb': 'Lista de tarefas de captura',
  'task.qbrw': 'Todas as tarefas',
  'task.wcyd': 'Eu participei',
  'task.qdz': 'Obtendo pedidos',
  'task.ywc': 'Concluído',
  'task.djy': 'a ser negociado',
  'task.ddje': 'Valor do pedido',
  'task.cyrs': 'Número de participantes',
  'task.ewsy': 'Benefícios adicionais',
  'task.jzsj': 'Prazo',
  'task.ckxq': 'Ver detalhes',
  'task.rwxq': 'Detalhes da tarefa',
  'task.cylb': 'Lista de participantes',
  'task.qdcyrw': 'Tem certeza que deseja participar desta tarefa de pedido?',
  'task.sd': 'Sim',
  'task.bue': 'não',

  'task.wdsy': 'Receita',
  'task.wdfh': 'Dividendos',
  'task.wdqd': 'Obter pedido',
  'task.dd.leixin': 'Tipo de pedido',
  'task.dd.qdrw': 'Tarefa de pedido rápido',
  'task.dd.ptdd': 'Ordem comum',

  'flow.lltg': 'Promoção de fluxo',
  'flow.gmll': 'Comprar pacote de dados',
  'flow.dqtc': 'Pacote atual',
  'flow.tc1': 'Pacote',
  'flow.sy2': 'restante',
  'flow.tian3': 'dia',
  'flow.jlzs': 'Número total de registros',
  'flow.lltc': 'Pacote de fluxo',
  'fluxo.ll': 'fluxo',
  'flow.gmsj': 'Tempo de compra',
  'flow.tcjg': 'Preço do pacote',
  'flow.sjzf': 'pagamento real',
  'flow.tcsm': 'Descrição do pacote',
  'flow.tcsm.sm': 'A compra deste pacote aumentará a exposição da loja e obterá mais tráfego de clientes. A fim de obter mais pedidos e aumentar a receita da loja. ',

  'flow.tcmc': 'Nome do pacote',
  'flow.gmsc': 'Duração da compra',
  'flow.zje': 'Valor total',
  'flow.jzsj': 'Prazo',
  'flow.gmrq': 'Data de compra',
  'flow.tian1': 'dia',
  'flow.zhou1': 'semana',
  'flow.yue1': 'mês',

  'share.dj': 'nível',

  'share.hz': 'Transferir',
  'share.hzd': 'Transferir para',
  'share.qbye': 'saldo da carteira',
  'share.tgje': 'Valor da promoção',
  'share.hzje': 'Valor da transferência',
  'share.qsrhzje': 'Insira o valor da transferência',

  '_inv._title': 'Descrição do convite',
  '_inv._t1': '1. Convite e relação convidada',
  '_inv._desc1': 'A convida B, B convida C, C convida D. O nível mais alto é 4, se D convida E novamente, então E não tem nada a ver com A. ',
  '_inv._t2': '2. O convidador recebe uma recompensa',
  '_inv._desc2': 'A recebe 4% do valor total concluído pelo pedido B, A recebe 2% do valor total concluído pelo pedido C e A recebe 1% do valor total concluído pelo pedido D',

  '_index._gm': 'Comprar',
  '_index._sm': 'Descrição: Aumente a exposição da loja e atraia tráfego',

  '_shop._update': '{n} vezes/mês, modificado {m} vezes no mês atual',

  '_chat._hc': 'Retirar',

  '_footer._suom': 'Descrição da política',

  '_footer._platform._qualification': 'Qualificação da empresa',
  '_store._ptzz': 'Qualificações relacionadas à plataforma ShopifyOutlets',

  '_abt._gywm': 'Sobre nós',
  '_abt._syms': 'Modelo de negócios',
  '_abt._qyzr': 'Responsabilidade Corporativa',
  '_abt._lxwm': 'Entre em contato conosco',

  '_wsj._wsjms': 'Comemore o Halloween com estilo em nossa loja assustadora de Halloween. Esteja você procurando uma doçura ou uma guloseima durante esta temporada assustadora, nossa coleção de fantasias, decorações e acessórios de Halloween tem algo para toda a família. Faça uma declaração arrepiante com nossas coleções femininas e masculinas, ou liberte a imaginação do seu filho com fantasias. De utensílios domésticos e brinquedos fofos e inesquecíveis a beleza e cosméticos glamorosos, encontre as peças perfeitas para celebrar o feriado mais emocionante do ano. ',

  '_wsj._b1': 'Decoração de outono',
  '_wsj._b2': 'Abóbora',
  '_wsj._b3': 'Casa',
  '_wsj._b4': 'Infantil',
  '_wsj._b5': 'Bebê',
  '_wsj._b6': 'Mulheres',
  '_wsj._b7': 'Vestido extravagante',
  '_wsj._b8': 'Personalizado',

  '_wsj._cj': 'Participar',
  '_wsj._gz': 'Regras de atividade',
  '_wsj._flss': 'Pesquisa por categoria',
  '_wsj._wsnz': 'Roupas femininas de Halloween',
  '_wsj._wsn': 'Roupas masculinas de Halloween',
  '_wsj._wst': 'Roupas infantis de Halloween',
  '_wsj._wszs': 'Decoração de Halloween',
  '_wsj._wslp': 'Presente de Halloween',

  '_wsj._desc1': '"Especial Halloween, Doces ou Travessuras!" Neste mês de outubro preparamos uma festa de carnaval para você! Venha e compre todos os tipos de produtos assustadores e chocantes, grandes descontos estão esperando por você! ',
  '_wsj._lkm': 'Vá às compras',

  '_wsj._wsj': 'Halloween',

  '_wsj._wsjjz': 'O Halloween está chegando',
  '_wsj._mzbt': 'Calendários do advento da beleza',
  '_wsj._mznr': 'A contagem regressiva para a época mais maravilhosa do ano acaba de chegar com calendários do advento para todos os gostos.',

  '_chr._tt1': 'Compre todo o Natal',
  '_chr._tt2': 'Decorações de Natal',
  '_chr._tt3': 'Comprar todos os presentes',
  '_chr._tt4': 'Compre todas as roupas de dormir de Natal',

  '_chr._dt1': 'A preparação para o Natal é tão mágica quanto o grande dia. Então, organize seus presentes, seus corredores enfeitados e seus pijamas combinando prontos para a magia do Natal.',

  '_chr._dtt1': 'Roupas de Natal',
  '_chr._dtt2': 'Correspondência de Natal em Família',
  '_chr._dtt3': 'Calendários do Advento',
  '_chr._dtt4': 'Natal em casa',
  '_chr._dtt5': 'Loja aconchegante',
  '_chr._dtt6': 'Móveis a tempo para o Natal',

  '_chr._tre1': 'Tendências agora',
  '_chr._tre2': 'Adicione um pouco de diversão festiva à sua casa com as tendências que todos estão adorando.',
  '_chr._tre3': 'Pão de gengibre',
  '_chr._tre4': 'Pudim de Natal',
  '_chr._tre5': 'Gonks de Natal',

  '_chr._ttre1': 'Cartões e papel de embrulho',
  '_chr._ttre2': 'Brinquedos e jogos',
  '_chr._ttre3': 'Presentes para comida e bebida',
  '_chr._ttre4': 'Preenchimentos de meia',
  '_chr._ttre5': 'Papai Noel Secreto',
  '_chr._ttre6': 'Primeiro Natal do bebê',
  '_chr._ttre7': 'Presentes de beleza',
  '_chr._ttre8': 'Presentes Premium',
  '_chr._ttre9': 'Presentes personalizados',

  '_sdj._sdnz': 'Roupas femininas',
  '_sdj._sdn': 'Roupas masculinas',
  '_sdj._sdetlw': 'presente',
  '_sdj._sdzs': 'Decoração',
  '_sdj._sdjj': 'brinquedo',

  '_sdj._title1': 'O Natal está chegando',
  '_sdj._desc1': '"Natal: a época da doação e da gratidão." Neste mês de dezembro, preparamos uma festa familiar calorosa e bem decorada para você! Venha e adquira uma variedade de produtos luminosos e quentes com grandes descontos esperando por você! ',

  '_ssd._lhpp': 'Pagamento flexível',
  '_ssd._lzhq': 'Fonte Global',
  '_ssd._pwyx': 'classificado como excelente',
  '_ssd._qsth': 'Retorno fácil',
  '_ssd._jgppcn': 'Correspondência de preços e compromisso',
  '_ssd._ztbt': 'Criar um balcão único global',
  '_ssd._yzspl': 'Fornecimento de estoque diretamente de mais de 8.000 marcas independentes',
  '_ssd._zcgm': 'Registre-se para comprar',
  '_ssd._zccs': 'Registrar para venda',

  '_ssd._dlwz': 'Login e registro',

  '_ssd._qjrgwc': 'Adicionar tudo ao carrinho',

  '_zddy._gzlb': 'Lista de observação',
  '_zddy._zsersxs': 'Vendido na shopifyoutlets',
  '_zddy._zccwsj': 'Registrar-se como comerciante',
  '_zddy._dlyh': 'Login',
  '_zddy._zcyh': 'Registrar',
  '_zddy._xyh': 'Novo usuário',
  '_zddy._gwc': 'Carrinho de compras',
  '_zddy._znxx': 'Mensagem',
  '_zddy._zshy': 'Mais populares',
  '_zddy._ddl': 'Ordem',
  '_zddy._mysj': 'Sem dados',
  '_zddy._ckgd': 'Ver mais',
  '_zddy._tjsp': 'Produtos recomendados',
  '_zddy._rmsp': 'Produtos populares, novas tendências, mais oportunidades de compra',
  '_zddy._xsp': 'Novo produto',
  '_zddy._jlzzr': 'Os itens mais quentes das últimas duas semanas',
  '_zddy._ljgm': 'Compre agora',
  '_zddy._gdsp': 'Produtos mais populares',
  '_zddy._ssbt': 'Pesquisar',
  '_zddy._ssgjz': 'Estou procurando..',

  '_zddy._bdhb': 'Moeda local',
  '_zddy._xzbdhb': 'Selecione a moeda local',
  '_zddy._zje': 'Valor total',
  '_zddy._xzzffs': 'Por favor, escolha o método de pagamento mais adequado para você',
  '_zddy._jmhb': 'Criptomoeda',

  '_zddy._cp': 'produto',
  '_zddy._sj': 'Empresário',
  '_zddy._mj': 'Vendedor',
  '_zddy._fhsy': 'Voltar à página inicial',

  '_sjsy._ddpl': 'Lista de pedidos',
  '_yhqd._czbz': 'Entre em contato com o atendimento ao cliente dedicado para confirmar as informações de recarga antes de recarregar.',
  '_yhqd._lxkf': 'Entre em contato com o atendimento ao cliente',
  '_yhqd._qsrje': 'Insira o valor da recarga',
  '_yhqd._wrhyhtd': 'Sem acesso ao banco',
  '_yhqd._yhzz': 'Transferência bancária',
  '_yhbt._yhlx': 'Tipo de banco',
  '_zcyz._sjdxyz': 'Verificação de SMS móvel',
  '_zcyz._sjhm': 'número de telefone',
  '_zcyz._srsjhm': 'Digite o número do celular',
  '_zzsd._qsrzzje': 'Por favor insira o valor da transferência',
  '_zzsd._zzje': 'valor da transferência',
  '_txcd._qsrtxje': 'Por favor insira o valor do saque',
  '_txcd._skr': 'Beneficiário',
  '_txcd._skr._ms': 'Por favor insira o nome do titular do cartão',
  '_txcd._skzh': 'Número da conta de pagamento',
  '_txcd._skzh._ms': 'Por favor insira o número da conta de pagamento',
  '_txcd._tjyhk': 'Adicionar cartão bancário',
  '_txcd._txje': 'Quantidade retirada',
  '_txcd._wdyhdz': 'endereço do banco',
  '_txcd._yhdm': 'código',
  '_txcd._yhdm._ms': 'Por favor insira o código do banco',
  '_txcd._yhdz': 'endereço',
  '_txcd._yhdz._ms': 'Por favor insira o endereço do banco',
  '_txcd._yhlx': 'tipo',
  '_txcd._yhlx._ms': 'Insira o tipo de cartão bancário',
  '_txcd._yhly': 'roteamento',
  '_txcd._yhly._ms': 'Insira a rota bancária',
  '_txcd._yhmc': 'banco',
  '_txcd._yhmc._ms': 'Por favor insira o nome do banco',
  '_txcd._yhtx': 'Saque de dinheiro bancário',

  '_jys._gmjmhb': 'Comprar criptomoeda',
  '_jys._gmzy': 'Você pode registrar uma conta e comprar criptomoedas acessando as seguintes exchanges e de acordo com as políticas atuais do seu país. Então recarregue na conta da plataforma! ',

  '_kdydj._bt': 'Abra uma loja e ganhe grandes prémios',
  '_kdydj._xyzbt': 'A sorte continua a girar',
  '_kdydj. _yx': 'Bombear {n} vezes',
  '_kdydj. _xh': 'Consumo {n} pontos',

  '_kdydj._jf': 'Pontos:',
  '_kdydj._zjjl': 'Recorde de vitórias',

  '_kdydj. _hdgz': 'Regras de atividade',
  '_kdydj._qx': 'Por favor',
  '_kdydj._dl': 'Entrar',

  '_kdydj._mjdl': 'Login do vendedor',
  '_kdydj. _yxh': 'E-mail',
  '_kdydj._yxhsm': 'Por favor, introduza o seu e-mail',
  '_kdydj. _yxhmm': 'Por favor, introduza a palavra-passe',
  '_kdydj._rgmyzh': 'Se não possui uma conta',
  '_kdydj._djzc': 'Clique para registar',
  '_kdydj._wjmm': 'Esqueci-me da password',

  '_kdydj._wdjp': 'O meu prémio',
  '_kdydj._zjts': 'Dicas para vencer',
  '_kdydj._jx': 'Continuar',
  '_kdydj._czl': 'Ganhou',

  '_kdydj._ljjl': 'Recompensa cumulativa',
  '_kdydj._ycy': 'Participou: {n} vezes',
  '_kdydj._cy': 'Participar {n} vezes',
  '_kdydj._lq': 'Receber',
  '_kdydj._ylq': 'Recebido',
  '_kdydj._wdd': 'Não alcançado',

  '_dk._dkbt1': 'Empréstimo para empreendedorismo resolve o problema de pedir dinheiro emprestado',
  '_dk._dkbt2': 'Fornecer empréstimos empresariais e serviços de circulação de capital',
  '_dk._dkbt3': 'Ajudá-lo-emos a resolver problemas de empréstimo, como fundos apertados, sem lugar para pedir dinheiro emprestado e ter vergonha de pedir dinheiro emprestado a amigos. momento. ',
  '_dk. _zxsq': 'Inscrição online',
  '_dk. _wddk': 'O meu empréstimo',
  '_dk._aqwy': 'Segurança e sem preocupações',
  '_dk._lhed': 'Cota flexível',
  '_dk. _lhedb': 'Valor: 5,00 a 500.000, juros baixos, métodos de reembolso flexíveis, sem taxas iniciais, empréstimo online, sem chamadas de revisão, confidencialidade da informação e empréstimo no mesmo dia. ',
  '_dk._jsfk': 'Empréstimo rápido',
  '_dk._jsfkb': 'Passe a revisão no prazo mais rápido de uma hora e o pagamento será feito imediatamente após a revisão. ',
  '_dk. _aqww1': 'Seguro e sem preocupações',
  '_dk._aqww1b': 'Sem empréstimo hipotecário, sem necessidade de depósito, sem chamada de retorno, este é um assunto privado. ',

  '_dk._gywm': 'Sobre nós',
  '_dk._gywm1': 'Estamos empenhados nos serviços financeiros de empréstimos, com base na filosofia empresarial de "cliente em primeiro lugar" e com a missão de resolver os problemas de rotatividade de capital dos clientes. ajudado inúmeros clientes a resolver a rotatividade do capital. ',
  '_dk._gywm2': 'Valor: 500 a 500.000, juros baixos, métodos de reembolso flexíveis, sem taxas iniciais, empréstimo online, sem chamadas de revisão, confidencialidade da informação e empréstimo no mesmo dia. ',
  '_dk._gywm3': 'Se não quiser pedir dinheiro emprestado a colegas e amigos, por favor, venha ter connosco. ',
  '_dk._gywm4': 'Comprometido com serviços de empréstimo para empreendedores',
  '_dk._gywm5': 'Resolva os seus problemas financeiros',
  '_dk._hzhb': 'Parceiro - Plataforma de Empréstimo Online',
  '_dk._smwda': 'Os utilizadores da estação global shopifyoutlets Business são oriundos de 112 países de todo o mundo e utilizam a criptomoeda USDT/ETH/BTC/TRX para liquidação. A criptomoeda é uma forma de comércio sem fronteiras, permitindo transações instantâneas de baixo custo em todo o mundo, sem esperas e sem taxas internacionais. ',

  '_dk._yjwa': 'shopifyoutlets Business© Co., Ltd. 2022-2024 Todos os direitos reservados',
  '_dk._yjwab': 'A ShopifyOutlets Holdings Global Sourcing Limited (0008945) está autorizada e regulada pela Autoridade de Conduta Financeira como corretora de crédito e trabalha exclusivamente com o credor Home Retail Group CardServices Limited, negociando como shopifyoutlets Business Financial Services, shopifyoutlets Business Financial Os serviços são autorizados e regulados pela Autoridade de Conduta Financeira para fornecer crédito. Home Retail GroupCard Services Limited está registada em Inglaterra (04007072), Avebury, 489-499Avebury Boulevard, Milton Keynes, Reino Unido. MK9 2NW. ',

  '_dk._cyzjsq': 'Preencher o pedido de empréstimo comercial',
  '_dk._dkll': 'Taxa de juro do empréstimo',
  '_dk._dkll2': 'Descrição da taxa de juro do empréstimo (clique para visualizar)',
  '_dk. _gs1': 'Fórmula',
  '_dk. _gs2': 'Juros',
  '_dk. _gs3': 'Valor do empréstimo',
  '_dk._gs4': 'Taxa de juro anual',
  '_dk. _gs5': 'Número de dias do empréstimo',
  '_dk. _gs6': 'por exemplo',
  '_dk. _gs7': 'Valor do empréstimo',
  '_dk. _gs8': 'Ciclo de empréstimo',
  '_dk. _gs9': 'dia',
  '_dk._fkfs': 'Método de empréstimo',
  '_dk. _zhye': 'Saldo da conta',
  '_dk. _dkqx': 'Período do empréstimo',
  '_dk._dkqx1': 'Selecionar período de empréstimo',
  '_dk. _sqje': 'Valor da aplicação',
  '_dk._qsrdk': 'Por favor, introduza o empréstimo',
  '_dk. _dkje': 'Valor do empréstimo',
  '_dk. _gj': 'Nacionalidade',
  '_dk._qxzgj': 'Por favor, selecione a nacionalidade',
  '_dk. _sqr': 'Requerente',
  '_dk._qsrsqsm': 'Por favor, introduza o nome do candidato',
  '_dk._zjhm': 'Número de identificação',
  '_dk._qsrzjhm': 'Por favor, introduza o número de identificação',
  '_dk._zjzp': 'Foto de identificação',
  '_dk._zjzp1': 'Por favor, carregue uma fotografia da frente do seu documento de identidade',
  '_dk._zjfm1': 'Por favor, carregue uma fotografia do verso do seu documento de identidade',
  '_dk._zjsc1': 'Por favor, carregue uma fotografia do seu documento de identidade',
  '_dk. _zjzp2': 'Selecionar imagem',
  '_dk. _pzslt': 'Exemplo de fotografia',
  '_dk._wytyb': 'Li e concordo',
  '_dk._jkxy': 'Contrato de Empréstimo',
  '_dk._tjsq': 'Submeter inscrição',

  '_dk. _wddk2': 'O meu empréstimo',
  '_dk. _ydk': 'Empréstimo',
  '_dk. _cslx': 'Gerar juros',
  '_dk._yjhk': 'Reembolso estimado',
  '_dk. _dkjl': 'Registo de empréstimo',
  '_dk._hkjl': 'Registo de reembolso',
  '_dk. _sqrq': 'Data da inscrição',
  '_dk._hkzq': 'Ciclo de reembolso',
  '_dk. _gdll': 'Taxa de juro fixa',
  '_dk._hkz': 'Reembolso em curso',
  '_dk._yhk': 'Reembolsado',
  '_dk._sqz': 'A aplicar',
  '_dk. _ytg': 'Aprovado',
  '_dk. _ybh': 'Rejeitado',
  '_dk._tqhk': 'Reembolso antecipado',

  '_dk._ljhk': 'Reembolsar imediatamente',
  '_dk._qrytqhk': 'Tem a certeza de que pretende solicitar o reembolso antecipado?',
  '_dk._qrytqhk1': 'Nota: Após o sucesso da encomenda, o valor depositado será devolvido diretamente ao empréstimo em prioridade',
  '_dk. _dkxq': 'Detalhes do empréstimo',
  '_dk. _dkje1': 'Valor do empréstimo',
  '_dk. _yhlx': 'Juros devidos',
  '_dk._hkze': 'Reembolso total',
  '_dk._sqhkz': 'A solicitar reembolso',
  '_dk._zxydbty': 'Por favor, leia atentamente e assinale a caixa para concordar com o "Contrato de Empréstimo"',
  '_dk._zzsqz': 'A aplicar',

  '_dk._dksq': 'Crédito',

  '_dk. _cs': 'Parâmetros',
  '_dk. _nllsm': 'Expresso em percentagem, por exemplo: 6,25%',
  '_dk._dktssm': 'O número total de dias desde o início até ao fim da aprovação do empréstimo',
  '_dk._yndts': 'O número de dias num ano: normalmente 365 dias',
  '_dk._zlj': 'Taxa de atraso',

  '_xbb. _xz': 'Descarregar',
  '_xbb. _xzapp': 'Descarregar aplicação ShopifyOutlets',
  '_xbb. _smewm': 'Digitalize o código QR para fazer o download',
  '_xbb. _syfl': 'Todas as categorias',
  '_xbb._gdfl': 'Mais',
  '_xbb. _sbbt1': 'Entrega rápida',
  '_xbb. _sbbt2': 'Os reembolsos estão disponíveis se houver algum problema',
  '_xbb. _sbbt3': 'Devoluções gratuitas',
  '_xbb. _sbbt4': 'No prazo de 90 dias',
  '_xbb._tjsp1': 'Produtos recomendados',
  '_xbb._tjsp2': 'Muitos descontos',
  '_xbb._tjsp3': 'Desconto por tempo limitado',

  '_xbb._asjgw': 'Compras por loja',
  '_xbb. _gdsp': 'Mais produtos',
  '_xbb._ljgm': 'Compre já',
  '_xbb. _ptbz': 'Garantia ShopifyOutlets',
  '_xbb. _aqzf': 'Pagamento seguro',
  '_xbb._aqzf1': 'Um método de pagamento utilizado por muitos compradores internacionais',
  '_xbb._aqys': 'Segurança e privacidade',
  '_xbb._aqys1': 'Respeitamos a sua privacidade, pelo que as suas informações pessoais estão seguras',
  '_xbb._mjbh': 'Protecção do comprador',
  '_xbb._mjbh1': 'Se o seu pedido não for entregue antes da data prevista ou não estiver satisfeito com o pedido, pode obter um reembolso',
  '_xbb._mzsm': 'Aviso/Isenção de responsabilidade',

  '_xbb. _sjxx': 'Empresário',
  '_xbb. _ggms': 'Especificação',
  '_xbb._mswa': 'Descrição',
  '_xbb._khpl': 'Comentários do cliente',
  '_xbb._zmfk': 'Feedback positivo',
  '_xbb._gzrs': 'Seguir',
  '_xbb. _gzrsan': 'Seguir',
  '_xbb. _ygzrsan': 'Já seguido',
  '_xbb. _fsxx': 'Mensagem',
  '_xbb._myrpl': 'Ainda não há comentários',
  '_xbb. _llgd': 'Ver mais',
  '_xbb. _pdcl': 'Promessa',
  '_xbb. _myf': 'Portes grátis',
  '_xbb._sdsj': 'Entregue em cerca de 5-10',
  '_xbb._ksjh': 'Entrega rápida',
  '_xbb. _bgdstk': 'Reembolso por pacote perdido',
  '_xbb. _shbgtk': 'Se o artigo estiver danificado, reembolso',
  '_xbb. _s30gtk': 'Reembolsos se não forem enviados no prazo de 30 dias',
  '_xbb._aqyys': 'Segurança e privacidade',
  '_xbb._wjtrxk': 'Pagamento seguro: não partilharemos as suas informações pessoais com terceiros sem o seu consentimento. ',
  '_xbb._sfgrxx': 'Dados pessoais seguros: protegemos a sua privacidade e mantemos os seus dados pessoais seguros e protegidos. ',
  '_xbb. _sl': 'Quantidade',
  '_xbb. _kcld': 'Inventário',

  '_xbb. _sjrz': 'Comerciante instalado',
  '_xbb._hezjh': 'O nosso programa de parceria oferece uma gama completa de apoio e serviços de marketing, e a nossa equipa de apoio ao cliente irá fornecer apoio e aconselhamento profissional para o ajudar a otimizar a exposição do seu produto e estratégia de vendas. Junte-se a nós agora! Vamos criar maiores oportunidades de negócio e crescer juntos! ',
  '_xbb. _xsje': 'Informações comerciais',
  '_xbb._rgsqjs': 'Se já é vendedor',
  '_xbb._qni': 'Por favor',
  '_xbb. _djdl': 'Clique para iniciar sessão',
  '_xbb. _dplog': 'Armazenar LOGOTIPO',
  '_xbb. _dpmc': 'Nome da loja',
  '_xbb. _srdpmc': 'Por favor, introduza o nome da loja',
  '_xbb. _zjhm': 'Número de identificação',
  '_xbb. _srzjhm': 'Por favor, introduza o número de identificação',
  '_xbb. _zsxm': 'nome verdadeiro',
  '_xbb. _srzsxm': 'Por favor, introduza o seu nome verdadeiro',
  '_xbb. _srgj': 'País',
  '_xbb._srsrgj': 'Por favor, introduza o país',
  '_xbb._zjz': 'Foto de identificação',
  '_xbb. _zjzm': 'Frente do documento',
  '_xbb. _zjfm': 'Verso do certificado',
  '_xbb._sczjz': 'Manter foto de identificação',
  '_xbb. _pssl': 'Exemplo de fotografia',
  '_xbb. _dlyx': 'E-mail de login',
  '_xbb. _srdlyx': 'Por favor, introduza o seu e-mail de login',
  '_xbb. _dlmm': 'Password de login',
  '_xbb. _srdlmm': 'Por favor, introduza a sua palavra-passe de login',
  '_xbb. _qrmm': 'Confirmar palavra-passe',
  '_xbb. _srqrmm': 'Por favor, introduza a palavra-passe de confirmação',
  '_xbb. _yqm': 'código de convite',
  '_xbb. _sryqm': 'Introduza o código de convite',
  '_xbb. _yxyzm': 'Código de verificação de e-mail',
  '_xbb. _sryzm': 'Introduza o código de verificação de e-mail',
  '_xbb._yydbty': 'Li e concordo',
  '_xbb._rzxy': 'Acordo de Liquidação',

  '_xbb._qgxty': 'Por favor, leia o acordo com atenção e marque a caixa para concordar',
  '_xbb._sryqmb': 'Por favor, insira o código de convite',

  '_xbb._ddpl': 'Comentários do pedido',
  '_xbb._dsppl': 'Comentários sobre produtos',
  '_xbb._dddpl': 'Comentário sobre o pedido',

  '_xhd._sdj': 'Natal'
}
