export default {
  'nav.account': 'Персональный центр',
  'nav.language': 'Упрощенный китайский',
  'signup.success': 'Регистрация прошла успешно',

  //ошибка
  'contact.empty': 'Контактная информация не может быть пустой',
  'phone.country.empty': 'Префикс телефона не может быть пустым',
  'phone.empty': 'Телефон не может быть пустым',
  'email.valid': 'Пожалуйста, введите действительный адрес электронной почты',

  // база
  'base.success': 'Успех',
  'base.save': 'Сохранить',
  'base.slide': 'Чтобы проверить, потяните ползунок',
  'base.send.code': 'Отправить код подтверждения',
  'base.send.time': '({time}) повторно отправить код подтверждения',
  'base.send.success': 'Отправить успешно',
  'base.view.more': 'Посмотреть больше',
  'base.Language': 'Язык',
  'base.previous': 'Предыдущая страница',
  'base.next': 'Следующая страница',
  'base.total.page': 'Всего страниц {page}',
  'base.go.to.page': 'Перейти на страницу',
  'base.go': 'Перейти',
  'base.followers': 'Подписчики',
  'base.follow': 'Подписаться',
  'base.following': 'Подписано',
  'base.visit.store': 'Просмотреть магазин',
  'base.contact': 'Свяжитесь с нами',
  'base.delivery': 'Расчетное время доставки: 1-7 дней',
  'base.search': 'Поиск',
  'base.search.text': 'Введите ключевые слова для поиска',
  'base.server': 'Онлайн-обслуживание клиентов',

  //индекс
  'text.categories.title': 'Категория товара',
  'text.welcome': 'Добро пожаловать за покупками!',
  'text.wishlist': 'Список желаний',
  'text.account': 'Персональный центр',
  'text.orders': 'Заказы',
  'text.messages': 'Сообщения',
  'text.suggest.title': 'Эксклюзивное предложение',
  'text.suggest.content': 'Только для новых глобальных премиум-пользователей!',
  'text.super.deals': 'Лучшие товары по невероятным ценам.',

  //зарегистрироваться
  'signup.register': 'Регистрация',
  'signup.signin': 'Войти',
  'signup.store.signin': 'Бизнес-логин',
  'signup.sign.out': 'Выйти',
  'signup.email': 'Адрес электронной почты',
  'signup.password': 'Пароль',
  'signup.qr.password': 'Подтвердите пароль',
  'signup.confirm.password': 'Подтвердите пароль',
  'signup.forgot.password': 'Забыли пароль',
  'signup.invitation': 'Код приглашения',
  'signup.char': 'Персонаж',
  'signup.contains': 'Содержит цифры, буквы или символы',
  'signup.qr.contains': 'Два пароля несовместимы',
  'signup.create.account': 'Создать аккаунт',
  'signup.agree': 'Создать аккаунт, вы согласны',
  'signup.member.agreement': 'Членское соглашение',
  'signup.and': 'и',
  'signup.privacy.policy': 'Политика конфиденциальности',
  'signup.email.code': 'Подтверждение электронной почты',
  'signup.last.step': 'Последний шаг',
  'signup.send.email': 'Пожалуйста, введите 4-значный код, отправленный на {email}',
  'signup.modify.email': 'Изменить электронную почту',
  'signup.verify.email': 'Электронная почта для подтверждения',
  'signup.have.store': 'Есть аккаунт продавца',
  'signup.goto.signin': 'Бизнес-логин',
  'signup.no.store': 'Нет аккаунта продавца',
  'signup.goto.store': 'Зарегистрироваться в магазине',
  'signup.next': 'Далее',
  'signup.your.email': 'Ваш адрес электронной почты',
  'signup.code.text': 'Код подтверждения',
  'signup.submit.signup': 'Зарегистрируйтесь сейчас',
  'signup.smrz': 'Аутентификация по реальному имени',
  'signup.derb': 'Второй шаг',
  'signup.qsrxm': 'Пожалуйста, введите название документа',
  'signup.qsrhm': 'Пожалуйста, введите идентификационный номер',

  //забыл
  'forgot.title': 'Сбросить пароль',
  'forgot.btn.check': 'Подтвердить электронную почту',
  'forgot.reset.now': 'Сбросить сейчас',

  //магазин
  'store.info.open': 'Открытая {y} неделя',

  // нижний колонтитул
  'footer.great.value': 'Отличное значение',
  'footer.great.value.desc': 'Мы предлагаем конкурентоспособные цены на более чем 100 миллионов товаров.',
  'footer.shopping': 'Покупки по всему миру',
  'footer.shopping.desc': 'Мы доставляем товары более чем в 200 стран и регионов, а наш веб-сайт доступен на 7 языках.',
  'footer.safe.payment': 'Безопасный платеж',
  'footer.safe.payment.desc': 'Оплачивайте самыми популярными и безопасными способами оплаты в мире.',
  'footer.shop.with.confidence': 'Покупайте с уверенностью',
  'footer.shop.with.confidence.desc': 'Наша политика защиты покупателей распространяется на весь процесс покупки.',
  'footer.help.center': 'Справочный центр',
  'footer.help.center.desc': 'Помощь 24/7 для создания комфортных покупок.',
  'footer.terms.conditions': 'Условия и положения',
  'footer.return.policy': 'Политика возврата',
  'footer.support.policy': 'Политика поддержки',
  'footer.privacy.policy': 'Политика конфиденциальности',
  'footer.be.seller': 'Стать продавцом',
  'footer.apply.now': 'Подать заявку сейчас',
  'footer.stay.connected': 'Оставайтесь на связи',

  'footer.about.us': 'О нас',
  'footer.about.company': 'Профиль компании',
  'footer.about.video': 'Введение видео',
  'footer.contact': 'Контакт',

  'footer.my.account': 'Мой аккаунт',
  'footer.my.logout': 'Выйти',
  'footer.my.order': 'История заказов',
  'footer.my.wish': 'Мой список желаний',
  'footer.my.join': 'Стать партнером',
  'footer.email': 'Электронная почта',
  'footer.gfemail': 'Официальный адрес электронной почты',
  'footer.fwemail': 'Служебная электронная почта',
  'footer.address': 'Адрес',

  'apply.success': 'Приложение выполнено успешно',
  'apply.success.desc': 'Применение успешно, войдите в учетную запись',

  // категории
  'category.title': 'Связанная категория',
  'category.all': 'Все категории',

  //деталь
  'detail.store.home': 'Главная страница магазина',
  'detail.sale.items': 'Список товаров',
  'detail.recommend': 'Рекомендуемый продукт',
  'detail.orders': 'Продажи',
  'detail.quantity': 'Количество',
  'detail.pieces.available': 'Доступно',
  'detail.delivery': 'Доставка',
  'detail.free.shipping': 'Бесплатная доставка',
  'detail.estimated.delivery': 'Расчетная доставка',
  'detail.days': 'Дней',
  'detail.buy.now': 'Купить',
  'detail.add.to.cart': 'Добавить в корзину',
  'detail.buyer.protection': 'Защита покупателя',
  'detail.money.guarantee': 'Гарантия возврата денег',
  'detail.refund.desc': 'Получите полный возврат средств, если товар не соответствует описанию или не доставлен',
  'detail.description': 'Описание товара',
  'detail.customer.reviews': 'Отзывы клиентов',
  'detail.specifications': 'Характеристики продукта',
  'detail.top.selling.products': 'Самые продаваемые товары',
  'detail.recommended.for.you': 'Рекомендуется для вас',
  'detail.sold': 'Продажи',
  'detail.receipt': 'Подтвердить получение',
  'detail.receipt.title': 'Вы уверены, что получили этот заказ?',
  'detail.receipt.content': 'После подтверждения транзакция заказа завершена',
  'detail.comment': 'Комментарий',

  //подтверждать
  'confirm.shipping.address': 'Адрес получателя',
  'confirm.change': 'Изменить',
  'confirm.payment.methods': 'Способы оплаты',
  'confirm.summary': 'Расчет',
  'confirm.total.item.costs': 'Всего по позиции',
  'confirm.total': 'Всего',
  'confirm.checkout': 'Оформить заказ',
  'confirm.place.order': 'Разместите заказ сейчас',
  'confirm.pay.now': 'Оплатить сейчас',
  'confirm.order.desc': "Нажав 'Разместить заказ', я подтверждаю, что прочитал и подтверждаю",
  'confirm.order.policy': 'Все условия и правила',
  'confirm.payment': 'Global Shopping обеспечивает безопасность вашей информации и платежей',

  // адрес
  'address.title': 'Адрес получателя',
  'адрес.по умолчанию': 'по умолчанию',
  'address.edit': 'Редактировать',
  'address.delete': 'Удалить',
  'address.new': 'Создать новый адрес',
  'address.contact': 'Контакт',
  'адрес.адрес': 'Адрес',
  'address.phone': 'Номер телефона',
  'address.set.default': 'Установить по умолчанию',
  'address.confirm': 'Подтвердить',
  'address.cancel': 'Отмена',
  'address.del.title': 'Подтвердить удаление адреса?',
  'address.del.content': 'Вы уверены, что хотите удалить этот адрес доставки?',

  'payment.method': 'Способ оплаты',
  // Корзина
  'shopping.title': 'Корзина',
  'shopping.back.buy': 'Вернуться к специальным покупкам',
  'shopping.start.shopping': 'Начать покупки',

  // член
  'member.account': 'Персональный центр',
  'member.overview': 'Обзор',
  'member.orders': 'Заказы',
  'member.payment': 'Мой кошелек',
  'member.address': 'Адрес доставки',
  'member.wishlist': 'Список желаний',
  'member.followlist': 'Список подписчиков',
  'member.message.center': 'Центр сообщений',
  'member.setting': 'Личные настройки',
  'member.shop.info': 'Информация о магазине',
  'member.shop.setting': 'Настройки магазина',
  'member.order.notify': 'Уведомление о новом заказе',
  'member.order.tips': 'Отправляя письмо на адрес электронной почты аккаунта продавца',

  // член.заказ
  'member.order.title': 'Мой заказ',
  'member.order.view.all': 'Просмотреть все',
  'member.order.all': 'Все',
  'member.order.unpaid': 'Не оплачено',
  'member.order.paid': 'Оплачено',
  'member.order.procurement': 'В ожидании закупки',
  'member.order.seller.paid': 'Куплено',
  'member.order.processing': 'В ожидании',
  'member.order.shipped': 'отправлено',
  'member.order.return': 'Возвращение',
  'member.order.completed': 'Завершено',
  'member.order.refund': 'Возмещено',
  'member.order.all.time': 'Все',
  'member.order.empty': 'Пока нет заказов',
  'member.order.date': 'Дата',
  'member.order.purchase.date': 'Покупка',
  'member.order.cpoy': 'Копировать',
  'member.order.id': 'Заказ',
  'member.order.detail': 'Детали заказа',
  'member.order.logistics': 'Логистическая информация',

  // участник.оплата
  'member.payment.title': 'Мой кошелек',
  'member.wallet.balance': 'Баланс кошелька',
  'member.crypto.recharge': 'Пополнить',
  'member.crypto.withdrawal': 'Вывод средств',
  'member.crypto.bank': 'Банковская карта',
  'member.wallet.record': 'Запись депозита монет',
  'member.bankcard.record': 'Запись банковской карты',
  'member.withdrawal.record': 'Запись о выходе',
  'member.income.record': 'Отчет о доходах',
  'member.transaction.record': 'Запись транзакции',
  'member.wallet.freeze': 'Заморозить средства',
  'member.wallet.profit': 'Расчетный доход',

  // перезарядка
  'recharge.currency': 'Валюта',
  'recharge.protocol': 'Протокол',
  'recharge.qrcode': 'QR-код',
  'recharge.address': 'Адрес кошелька',
  'recharge.copy': 'Скопировать адрес',
  'recharge.ok.text': 'Подтверждение',
  'recharge.cancel.text': 'Отмена',

  //банк
  'bank.recharge.title': 'Пополнение банковской карты',
  'bank.title': 'Название банка',
  'bank.name': 'имя',
  'bank.account': 'Счет',
  'bank.routing': 'Маршрутизация',
  'bank.code': 'код',
  'bank.bankname': 'Название банка',
  'bank.bankaddress': 'Адрес банка',
  'bank.ok.text': 'Далее',
  'bank.ok.prev': 'Предыдущий шаг',
  'bank.submit': 'Отправить сейчас',
  'bank.amount': 'Сумма пополнения',
  'bank.amount.desc': 'Пожалуйста, введите сумму пополнения',
  'bank.type': 'Тип',
  'bank.usd': 'доллары США',
  'bank.eur': 'евро',
  'bank.receipt.number': 'Номер квитанции',
  'bank.receipt.number.desc': 'Пожалуйста, введите номер квитанции об операции',
  'bank.credential.picture': 'Изображение учетных данных',
  'bank.credential.picture.desc': 'Пожалуйста, загрузите изображение учетных данных',
  'bank.remark': 'Примечание',
  'bank.upload': 'Загрузить картинку',
  'bank.text.title': 'Заметки',
  'bank.text.t1': 'Примечания к переводу не нужны, оставьте поле пустым',
  'bank.text.t2': 'Для домохозяйств Гонконга выберите Гонконг вместо Китая',
  'bank.text.t3': 'Американский денежный перевод, в будние дни переводить европейский денежный перевод до 15:00, денежный перевод доступен в рабочее время банка!',
  'bank.text.t4': 'Время платежа - T+1, а последний платеж - T+3 в Европе',
  'bank.text.t5': 'Сообщите платформе перед переводом денег, чтобы подтвердить, доступен ли счет. Если счет закрыт, компенсация не выплачивается.',
  'bank.text.t6': 'Депозит с помощью банковской карты. Информация об обслуживании клиентов имеет преимущественную силу.',

  //банклист
  'bank.list.title': 'Название банка',
  'bank.list.code': 'Код банка',
  'bank.list.amount': 'Сумма перевода',
  'bank.list.number': 'Номер квитанции',
  'bank.list.img': 'Изображение ваучера',
  'bank.list.status': 'Статус',
  'bank.list.time': 'Время',
  'bank.list.status1': 'На рассмотрении',
  'bank.list.status2': 'Пройдено',
  'bank.list.status3': 'Отклонено',

  // Снятие
  'withdrawal.address.desc': 'Пожалуйста, введите адрес кошелька!',
  'withdrawal.number': 'Номер',
  'withdrawal.real.number': 'фактическое прибытие',
  'withdrawal.number.desc': 'Пожалуйста, введите сумму вывода!',
  'withdrawal.btn.all': 'Все',
  'withdrawal.balance': 'Баланс',
  'withdrawal.commission': 'Комиссия',
  'withdrawal.actual.amount': 'Счет',
  'withdrawal.notice': 'Уведомление',
  'withdrawal.notice.text': 'Перед передачей убедитесь, что информация об адресе получения верна. После того, как активы будут переданы, они не могут быть возвращены.',
  'withdrawal.notice.content': '{name}({cp_name}) комиссия: текущая рыночная стоимость {fee_rate}%/транзакция, минимальный стандарт: {fee_min} {name}/транзакция',
  'withdrawal.submit': 'Отправить',
  'withdrawal.choice': 'Выберите криптовалюту',
  'withdrawal.yzm': 'Код подтверждения',
  'withdrawal.fs': 'Отправить',
  'withdrawal.qsryzm': 'Пожалуйста, введите код подтверждения электронной почты',

  // получаем
  'recive.method': 'Протокол',
  'recive.amount': 'количество',
  'recive.address': 'Адрес',
  'recive.date': 'Время',
  'recive.status': 'Статус',
  'recive.create.at': 'Время транзакции',
  'recive.type': 'Тип',
  'recive.befor': 'Перед транзакцией',
  'recive.balance': 'Баланс',
  'recive.freeze': 'Заморозить',
  'recive.review': 'Рецензирование',
  'recive.success': 'Пройдено',
  'recive.reject': 'Отклонено',

  // реклама
  'advertise.title': 'Центр продвижения',
  'advertise.shop.title': 'Реклама магазина',
  'advertise.shop.status': 'Статус',
  'advertise.shop.expired': 'Просрочено',
  'advertise.shop.promotion': 'Продвижение',
  'advertise.shop.expire.date': 'Дата истечения срока действия',
  'advertise.shop.renew': 'Продлить рекламу',
  'advertise.shop.payable': 'Сумма к оплате',
  'advertise.shop.explanation': 'Объяснение рекламы',
  'advertise.shop.text': 'Участвуйте в рекламном продвижении платформы, повышайте узнаваемость магазина и продвигайте транзакционные заказы',
  'advertise.shop.paynow': 'Продлить сейчас',
  'advertise.shop.modal.title': 'Подтвердить платеж',
  'advertise.shop.modal.desc': 'Подтвердите оплату промоакции',
  'advertise.shop.modal.btn': 'Подтвердить платеж',

  //Список желаний
  'wishlist.title': 'Список желаний',
  'wishlist.delete': 'Удалить',
  'wishlist.orders': 'Продажи',

  //Подписаться на список
  'followlist.title': 'Список подписчиков',
  'followlist.delete': 'Удалить',
  'followlist.follow': 'Подписаться',

  // магазин
  'store.dashboard': 'Панель инструментов',
  'store.products': 'Товары',
  'store.products.list': 'Список товаров',
  'store.products.reviews': 'Отзывы о товарах',
  'store.orders': 'Заказы',
  'store.wallet': 'Кошелек',
  'store.message': 'Центр сообщений',
  'store.setting': 'Настройки',
  'store.order.total.profit': 'Расчетный общий операционный доход',

  //панель приборов
  'dashboard.store.hour.views': 'Посещения в режиме реального времени',
  'dashboard.store.today.views': 'Сегодняшние просмотры',
  'dashboard.product.total': 'Общий продукт',
  'dashboard.product.today': 'Добавлено сегодня',
  'dashboard.order.total': 'Общая сумма заказа',
  'dashboard.sales.total': 'Расчетный оборот',
  'dashboard.sales.real': 'Реальные продажи',
  'dashboard.sales.pay': 'Общая выплата',
  'dashboard.sales.profit': 'Общая прибыль',
  'dashboard.commission.total': 'Общий доход',
  'dashboard.commission.today': 'Сегодняшний доход',
  'dashboard.order.sales': 'Продажи товаров',
  'dashboard.sales.list': 'Рейтинг продаж',
  'dashboard.goods.cate.rate': 'Соотношение категорий товаров',
  'dashboard.goods.wish': 'Рейтинг лайков товаров',
  'dashboard.january': 'Январь',
  'dashboard.february': 'Февраль',
  'dashboard.march': 'Март',
  'приборная панель. апрель': 'апрель',
  'dashboard.may': 'Май',
  'приборная панель. июнь': 'июнь',
  'приборная панель. июль': 'июль',
  'dashboard.august': 'Август',
  'dashboard.september': 'Сентябрь',
  'dashboard.october': 'Октябрь',
  'dashboard.november': 'ноябрь',
  'dashboard.december': 'декабрь',

  // продукты
  'products.add.new': 'Добавить новый товар',
  'products.add.from.warehouse': 'Добавить товары со склада',
  'products.delete': 'Удалить',
  'products.add': 'Добавить',
  'products.table.img': 'Изображение',
  'products.table.name': 'Название продукта',
  'products.table.category': 'Категория',
  'products.table.wish': 'нравится',
  'products.table.stock': 'Склад',
  'products.table.price': 'Цена',
  'products.table.profit': 'Прибыль',
  'products.table.action': 'Действие',
  'products.search.category': 'Поиск по категории',
  'products.back.product': 'Вернуться к списку товаров',
  'products.total': 'Всего продуктов',
  'products.yes': 'Да',
  'products.no': 'Отмена',
  'products.batch.add': 'Групповое добавление',
  'products.ask.add': 'Вы уверены, что хотите добавить товары?',
  'products.batch.delete': 'Пакетное удаление',
  'products.ask.delete': 'Вы уверены, что хотите удалить продукт?',
  'products.top': 'Топ',
  'products.syzd': 'Верх главной страницы',
  'products.zdwz': 'Верхняя позиция',
  'products.t1': 'Продвижение',
  'products.t2': 'Предпочитаемый',
  'products.t3': 'Бутик',
  'products.t4': 'нет',

  // отзывы
  'reviews.title': 'Отзывы о товарах',
  'reviews.product.name': 'Товар',
  'reviews.user.name': 'Пользователь',
  'reviews.star': 'Рейтинг',
  'reviews.comment': 'Просмотреть контент',
  'reviews.sku': 'Характеристики товара',
  'reviews.imgs': 'Фотогалерея',
  'reviews.created': 'Дата',

  // магазин. заказ
  'store.order.purchase': 'Купить сейчас',
  'store.order.purchase.desc': 'Вы уверены, что хотите оплатить этот товар?',
  'store.order.purchase.yes': 'платить немедленно',
  'store.order.purchase.no': 'Отмена',
  'store.order.desc': 'Для покупки этого заказа требуется оплата, и вы получите преимущества после того, как заказ будет выполнен.',
  'store.order.no': 'Номер заказа',
  'store.order.number': 'Количество',
  'store.order.buyer': 'Покупатель',
  'store.order.total': 'Общая сумма',
  'store.order.will.earning': 'Заработок',
  'store.order.profit': 'Прибыль',
  'store.order.dividends': 'Дивиденды',
  'store.order.payment.status': 'Статус оплаты',
  'store.order.seller.buy.status': 'Статус покупки',
  'store.order.status': 'Статус заказа',
  'store.order.date': 'Дата заказа',
  'store.order.purchase.date': 'Дата покупки',
  'store.order.action': 'Действие',
  'store.order.purchase.amount': 'Сумма покупки',

  //доход
  'income.create.at': 'Рекордное время',
  'income.order.sn': 'Номер заказа',
  'income.realpay': 'Сумма заказа',
  'доход.прибыль': 'доход',

  //Параметр
  'setting.avatar': 'Бизнес-аватар',
  'setting.upload': 'Загрузить',
  'setting.shop.name': 'Название магазина',
  'setting.shop.phone': 'Контактный телефон',
  'setting.shop.address': 'Адрес магазина',
  'setting.shop.save': 'Сохранить',
  'setting.upload.pic': 'Загрузить картинку',
  'setting.send.pic': 'Отправить картинку',

  // бесселлер
  'beseller.title': 'Подать заявку, чтобы стать продавцом',
  'beseller.account': 'Информация об учетной записи',
  'beseller.store': 'Информация о магазине',
  'beseller.store.name': 'Название магазина',
  'beseller.store.address': 'Адрес магазина',

  // сохранить дом
  'store.home.title': 'Настройки домашней страницы',
  'store.home.topimg': 'Главное изображение',
  'store.home.banner': 'Карусель',
  'store.home.up3': 'Примечания: не менее 3 загрузок',
  'store.upload.more': 'Загрузить больше изображений',
  'store.home.columns': 'Колонки',
  'store.home.bgimg': 'Фоновое изображение',
  'store.goods.remark': 'Примечание: 5 товаров в строке, максимум 10 товаров в разделе',
  'store.home.select': 'Выберите товар',
  'store.home.add': 'Добавить столбец',

  //персональная информация
  'setting.update.user': 'Личная информация',
  'setting.update.info': 'Изменить информацию',
  'setting.user.avatar': 'Аватар пользователя',
  'setting.user.nickname': 'Псевдоним пользователя',
  'setting.user.nickname.desc': 'Пожалуйста, введите псевдоним пользователя',

  'setting.safe.title': 'Информация о безопасности',
  'setting.user.passwd': 'Изменить пароль',
  'setting.passwd.title1': 'Старый пароль',
  'setting.passwd.desc1': 'Пожалуйста, введите старый пароль',
  'setting.passwd.title2': 'Новый пароль',
  'setting.passwd.desc2': 'Пожалуйста, введите новый пароль',
  'setting.passwd.title3': 'Подтвердите пароль',
  'setting.passwd.desc3': 'Пожалуйста, введите пароль для подтверждения',
  'setting.invitation': 'Пригласить друзей',
  'setting.copy': 'Копировать',

  'adv.add.products': 'Добавить рекламные товары',
  'adv.list.title': 'Продвижение товара',
  'adv.type': 'Тип продвижения',
  'adv.begin.time': 'Время акции',
  'adv.end.time': 'Время окончания',
  'adv.status': 'Статус',
  'adv.status.s1': 'Продвижение',
  'adv.status.s2': 'Конец',
  'adv.add.back': 'Вернуться в продвигаемый список',
  'adv.recharge': 'Пополнить бонусные баллы',
  'adv.select.goods': 'Выбрать товары',
  'adv.select.btn': 'Рекламируемые товары',
  'adv.recharge.title': 'Пополнить бонусные баллы',
  'adv.recharge.balance': 'Баланс баллов',
  'adv.point': 'точка',
  'adv.point.rate': 'Соотношение баллов',
  'adv.recharge.amount': 'Сумма пополнения',
  'adv.recharge.input.amount': 'Введите сумму пополнения',
  'adv.select.mod': 'Выбрать панель',
  'adv.mod1': 'Рекомендуемые столбцы',
  'adv.mod2': 'Предпочтительный столбец',
  'adv.mod3': 'Горячая колонка продаж',
  'adv.mod4': 'Отличный список',
  'adv.mod5': 'Список классификаций',
  'adv.mod6': 'Подробное продвижение',
  'adv.ge': 'количество',
  'adv.xs': 'час',
  'adv.xzbk': 'Выбрать номер',
  'adv.syme': 'Оставшаяся квота',
  'adv.tgsc': 'Длительность акции',
  'adv.tgjg': 'Стоимость акции',
  'adv.syye': 'Текущий баланс',
  'adv.tgye': 'Баланс продвижения',
  'adv.yj1': 'ожидается',
  'adv.yj2': 'Начать продвижение',
  'adv.pay.point': 'Очки потребления',

  'wallet.ykcsxf': 'Плата за обработку была вычтена',
  'wallet.sxf': 'Плата за обслуживание',
  'wallet.zxje': 'Минимальная сумма',
  'wallet.24hxe': '24-часовой лимит',
  'wallet.desc.text': 'Ни при каких обстоятельствах чиновник не попросит вас перевести деньги на счет и не запросит у вас код подтверждения. Не участвуйте в незаконных действиях, таких как покупка от имени других лиц, отмывание денег и незаконный сбор средств, а также остерегайтесь онлайн-мошенничества',
  'wallet.cunbi.text': 'Вы можете перевести {name} только на этот адрес, другие активы не будут получены',

  'credit.title': 'Кредитный лимит',
  'credit.ed': 'Квота',
  'credit.hk': 'Погашение',
  'credit.kyye': 'Доступный баланс',
  'credit.dqqk': 'Текущая задолженность',
  'credit.hkje': 'Сумма погашения',
  'credit.hkje.desc': 'Пожалуйста, введите сумму погашения',
  'credit.cgje': 'Сумма покупки',
  'credit.qrz': 'Сертификация',
  'credit.srxyj': 'Введите сумму кредитного платежа',

  'store.gzl': 'следить',
  'store.wxz': 'Неограниченно',

  'auth.smrz': 'Аутентификация по реальному имени',
  'auth.rzlx': 'Тип',
  'auth.sfzm': 'Лицевая сторона удостоверения личности',
  'auth.sffm': 'Обратная сторона удостоверения личности',
  'auth.zsxm': 'настоящее имя',
  'auth.zjhm': 'Номер документа',
  'auth.yyzz': 'Бизнес-лицензия',
  'auth.gsmc': 'Название компании',
  'auth.shz': 'Аудит',
  'auth.ytg': 'Пройдено',
  'auth.ybh': 'Отклонено',
  'auth.zt': 'статус',
  'auth.gr': 'Личное',
  'auth.gs': 'Предприятие',
  'auth.ljtj': 'Подать заявку на аутентификацию',
  'auth.wrz': 'Не прошел проверку подлинности',

  'credit.qts': 'Посмотреть описание',
  'store.spss': 'Верхний предел товара',
  'store.dpfh': 'Сохранить дивиденды',
  'store.qydj': 'Уровень акций',
  'магазин.уровень': 'уровень',
  'store.jian': 'кусочки',

  'order.notify.email': 'Из-за контроля рисков электронной почты частая отправка электронных писем с напоминанием о заказе платформы будет перехвачена. Чтобы получать их вовремя, рекомендуется добавить {e} в логин белый список адресов электронной почты',

  'setting.sjxx': 'Информация',
  'setting.smrz': 'Аутентификация',
  'setting.aqzx': 'Безопасность',
  'setting.tzxx': 'Уведомление',
  'setting.dpzx': 'Украшение',
  'setting.sjqy': 'Капитал',
  'setting.sjdjqy': 'Описание преимуществ уровня продавца',

  'setting.fhbky': 'Текущий дивиденд недоступен',
  'setting.spgzyzy': 'Правила и рекомендации магазина',

  'desc.dqqy': 'Текущий капитал',
  'desc.xjqy': 'Подчиненные интересы',
  'desc.sjqy_0': 'Кредитный лимит магазина',
  'desc.sjqy_1': 'Сохранить дивиденды',
  'desc.sjqy_2': 'Количество товаров, которые можно опубликовать',

  'share.yqlb': 'Список приглашенных',
  'share.zmj': 'Всего продавца',
  'share.zdd': 'Общий заказ',
  'share.zsy': 'Общий брокерский доход',
  'share.wcl': 'необработанный',
  'share.ycl': 'Обработано',
  'share.ywc': 'Завершено',
  'share.dpmc': 'Название магазина',
  'share.ddl': 'Количество заказа',
  'share.jjsy': 'Доход от брокерских услуг',
  'share.zts': 'Общее количество элементов',

  'chat.state': 'состояние',
  'chat.zx': 'Онлайн',
  'chat.ljz': 'Подключение',

  'bind.wallet.title': 'Адрес для вывода средств',
  'bind.address.title': 'Добавить адрес',
  'bind.bj': 'Изменить',
  'bind.sc': 'Удалить',
  'bind.qrsc': 'Вы уверены, что хотите удалить этот адрес?',
  'bind.qbdz': 'Адрес кошелька',
  'bind.tjsj': 'Добавить время',
  'bind.cz': 'Операция',
  'withdrawal.address.choice': 'Пожалуйста, выберите адрес для вывода!',

  'order.sxsj': 'Эффективное время',
  'order.ljsj': 'вступает в силу немедленно',
  'order.dssj': 'Вступает в силу по времени',

  'store.st.zt': 'Статус магазина',
  'store.st.zc': 'нормальный',
  'store.st.zc.desc': 'Ваш магазин нормальный, продолжайте в том же духе',
  'store.st.yc': 'Исключение',
  'store.st.yc.desc': 'В вашем магазине есть нестандартные заказы или получены жалобы покупателей',
  'store.st.dj': 'Заморозить',
  'store.st.dj.desc': 'У вашего магазина есть определенный операционный риск, и средства заморожены',
  'store.st.jy': 'отключено',
  'store.st.jy.desc': 'Ваш магазин нарушил соглашения и правила, связанные с платформой, и был отключен',

  'task.sy': 'Домашняя страница',
  'task.rwlbo': 'Список задач',
  'task.rwxqo': 'Детали задачи',
  'task.ljcy': 'Участвовать сейчас',
  'task.gdrw': 'Еще задачи',
  'task.rwlb': 'Список задач захвата',
  'task.qbrw': 'Все задачи',
  'task.wcyd': 'Я участвовал',
  'task.qdz': 'Получение заказов',
  'task.ywc': 'Завершено',
  'task.djy': 'для обмена',
  'task.ddje': 'Сумма заказа',
  'task.cyrs': 'Количество участников',
  'task.ewsy': 'Дополнительные преимущества',
  'task.jzsj': 'Крайний срок',
  'task.ckxq': 'Подробнее',
  'task.rwxq': 'Сведения о задаче',
  'task.cylb': 'Список участников',
  'task.qdcyrw': 'Вы уверены, что хотите участвовать в выполнении этой задачи по заказу?',
  'task.sd': 'Да',
  'task.bue': 'нет',

  'task.wdsy': 'Доход',
  'task.wdfh': 'Дивиденды',
  'task.wdqd': 'Получить заказ',
  'task.dd.leixin': 'Тип ордера',
  'task.dd.qdrw': 'Быстрый заказ',
  'task.dd.ptdd': 'Обычный заказ',

  'flow.lltg': 'Продвижение потока',
  'flow.gmll': 'Купить пакет данных',
  'flow.dqtc': 'Текущий пакет',
  'flow.tc1': 'Пакет',
  'flow.sy2': 'оставшийся',
  'flow.tian3': 'день',
  'flow.jlzs': 'Общее количество записей',
  'flow.lltc': 'Пакет Flow',
  'flow.ll': 'поток',
  'flow.gmsj': 'Время покупки',
  'flow.tcjg': 'Стоимость пакета',
  'flow.sjzf': 'фактический платеж',
  'flow.tcsm': 'Описание пакета',
  'flow.tcsm.sm': 'Приобретение этого пакета повысит узнаваемость магазина и привлечет больше клиентов. Для того, чтобы получить больше заказов и увеличить доход магазина. ',

  'flow.tcmc': 'Имя пакета',
  'flow.gmsc': 'Продолжительность покупки',
  'flow.zje': 'Общая сумма',
  'flow.jzsj': 'Крайний срок',
  'flow.gmrq': 'Дата покупки',
  'flow.tian1': 'день',
  'flow.zhou1': 'неделя',
  'flow.yue1': 'месяц',

  'share.dj': 'уровень',

  'share.hz': 'Передача',
  'share.hzd': 'Перевести в',
  'share.qbye': 'баланс кошелька',
  'share.tgje': 'Сумма поощрения',
  'share.hzje': 'Сумма перевода',
  'share.qsrhzje': 'Пожалуйста, введите сумму перевода',

  '_inv._title': 'Описание приглашения',
  '_inv._t1': '1. Приглашение и отношения приглашения',
  '_inv._desc1': 'A приглашает B, B приглашает C, C приглашает D. Самый высокий уровень — 4, если D снова приглашает E, то E не имеет ничего общего с A. ',
  '_inv._t2': '2. Пригласивший получает вознаграждение',
  '_inv._desc2': 'A получает 4% от общей суммы, выполненной по заказу B, A получает 2% от общей суммы, выполненной по заказу C, и A получает 1% от общей суммы, выполненной по заказу D',

  '_index._gm': 'Покупка',
  '_index._sm': 'Описание: Повысьте узнаваемость магазина и привлеките трафик',

  '_shop._update': '{n} раз/месяц, изменено {m} раз в текущем месяце',

  '_chat._hc': 'Вывести',

  '_footer._suom': 'Описание политики',

  '_footer._platform._qualification': 'Квалификация компании',
  '_store._ptzz': 'Квалификации, связанные с платформой ShopifyOutlets',

  '_abt._gywm': 'О нас',
  '_abt._syms': 'Бизнес-модель',
  '_abt._qyzr': 'Корпоративная ответственность',
  '_abt._lxwm': 'Свяжитесь с нами',

  '_wsj._wsjms': 'Отпразднуйте Хэллоуин стильно в нашем жутком Хэллоуинском магазине. Ищете ли вы какой-нибудь трюк или угощение в это жуткое время года, в нашей коллекции костюмов, украшений и аксессуаров для Хэллоуина найдется что-то для всей семьи. Сделайте сделайте леденящее душу заявление с нашими женскими и мужскими коллекциями или дайте волю воображению вашего ребенка с помощью маскарадных костюмов. От незабываемых милых товаров для дома и игрушек до гламурной красоты и косметики — найдите идеальные вещи, чтобы отпраздновать самый волнующий праздник в году',

  '_wsj._b1': 'Осенний домашний декор',
  '_wsj._b2': 'Тыква',
  '_wsj._b3': 'Дом',
  '_wsj._b4': 'Детский',
  '_wsj._b5': 'Детский',
  '_wsj._b6': 'Женский',
  '_wsj._b7': 'Маскарадное платье',
  '_wsj._b8': 'Персонализированный',

  '_wsj._cj': 'Участвовать',
  '_wsj._gz': 'Правила активности',
  '_wsj._flss': 'Поиск по категориям',
  '_wsj._wsnz': 'Женская одежда на Хэллоуин',
  '_wsj._wsn': 'Мужская одежда на Хэллоуин',
  '_wsj._wst': 'Детская одежда на Хэллоуин',
  '_wsj._wszs': 'Украшение Хэллоуина',
  '_wsj._wslp': 'Подарок на Хэллоуин',

  '_wsj._desc1': '"Особенный Хэллоуин: Кошелек или жизнь!" В октябре мы подготовили для вас карнавальную вечеринку! Приходите и приобретайте самые страшные и шокирующие товары, вас ждут большие скидки! ',
  '_wsj._lkm': 'Пойти за покупками',

  '_wsj._wsj': 'Хэллоуин',

  '_wsj._wsjjz': 'Хэллоуин приближается',
  '_wsj._mzbt': 'Адвент-календари красоты',
  '_wsj._mznr': 'Обратный отсчет до самого чудесного времени года только что начался благодаря адвент-календарям, которые подойдут каждому.',

  '_chr._tt1': 'Купить все рождественские товары',
  '_chr._tt2': 'Рождественские украшения',
  '_chr._tt3': 'Купить все подарки',
  '_chr._tt4': 'Купить всю рождественскую ночную одежду',

  '_chr._dt1': 'Преддверие Рождества так же волшебно, как и большой день. Так что разберитесь с подарками, украсьте залы и подготовьте подходящие пижамы к волшебству Рождества.',

  '_chr._dtt1': 'Рождественская одежда',
  '_chr._dtt2': 'Соответствующее семейное Рождество',
  '_chr._dtt3': 'Адвент-календари',
  '_chr._dtt4': 'Рождество дома',
  '_chr._dtt5': 'Уютный магазин',
  '_chr._dtt6': 'Мебель к Рождеству',

  '_chr._tre1': 'Сейчас в тренде',
  '_chr._tre2': 'Добавьте немного праздничного веселья в свой дом с помощью трендов, которые всем нравятся.',
  '_chr._tre3': 'Имбирный пряник',
  '_chr._tre4': 'Рождественский пудинг',
  '_chr._tre5': 'Рождественские гонки',

  '_chr._ttre1': 'Открытки и упаковочная бумага',
  '_chr._ttre2': 'Игрушки и игры',
  '_chr._ttre3': 'Подарки с едой и напитками',
  '_chr._ttre4': 'Наполнители для чулок',
  '_chr._ttre5': 'Тайный Санта',
  '_chr._ttre6': 'Первое Рождество ребенка',
  '_chr._ttre7': 'Подарки для красоты',
  '_chr._ttre8': 'Премиум-подарки',
  '_chr._ttre9': 'Персонализированные подарки',

  '_sdj._sdnz': 'Женская одежда',
  '_sdj._sdn': 'Мужская одежда',
  '_sdj._sdetlw': 'подарок',
  '_sdj._sdzs': 'Украшение',
  '_sdj._sdjj': 'игрушка',

  '_sdj._title1': 'Рождество приближается',
  '_sdj._desc1': '"Рождество: время дарения и благодарности. В декабре мы подготовили для вас ярко оформленный и теплый семейный праздник!" Приходите и забирайте разнообразные яркие и теплые товары с большими скидками, которые ждут вас! ',

  '_ssd._lhpp': 'Гибкая оплата',
  '_ssd._lzhq': 'Источник глобальный',
  '_ssd._pwyx': 'оценено как отлично',
  '_ssd._qsth': 'Легкий возврат',
  '_ssd._jgppcn': 'Согласование цен и обязательства',
  '_ssd._ztbt': 'Создайте глобальную универсальную систему покупок',
  '_ssd._yzspl': 'Поставка инвентаря напрямую от более чем 8000 независимых брендов',
  '_ssd._zcgm': 'Зарегистрируйтесь для покупки',
  '_ssd._zccs': 'Зарегистрируйтесь для продажи',

  '_ssd._dlwz': 'Войти и зарегистрироваться',

  '_ssd._qjrgwc': 'Добавить все в корзину',

  '_zddy._gzlb': 'Список наблюдения',
  '_zddy._zsersxs': 'Продано в shopifyoutlets',
  '_zddy._zccwsj': 'Зарегистрируйтесь как торговец',
  '_zddy._dlyh': 'Войти',
  '_zddy._zcyh': 'Зарегистрироваться',
  '_zddy._xyh': 'Новый пользователь',
  '_zddy._gwc': 'Корзина',
  '_zddy._znxx': 'Сообщение',
  '_zddy._zshy': 'Самый популярный',
  '_zddy._ddl': 'Заказ',
  '_zddy._mysj': 'Нет данных',
  '_zddy._ckgd': 'Увидеть больше',
  '_zddy._tjsp': 'Рекомендуемые продукты',
  '_zddy._rmsp': 'Популярные продукты, новые тенденции, больше возможностей для покупок',
  '_zddy._xsp': 'Новый продукт',
  '_zddy._jlzzr': 'Самые горячие статьи за последние две недели',
  '_zddy._ljgm': 'Купить сейчас',
  '_zddy._gdsp': 'Более популярные товары',
  '_zddy._ssbt': 'Поиск',
  '_zddy._ssgjz': 'Я ищу..',

  '_zddy._bdhb': 'Местная валюта',
  '_zddy._xzbdhb': 'Выберите местную валюту',
  '_zddy._zje': 'Общая сумма',
  '_zddy._xzzffs': 'Пожалуйста, выберите подходящий вам способ оплаты',
  '_zddy._jmhb': 'Криптовалюта',

  '_zddy._cp': 'продукт',
  '_zddy._sj': 'Бизнесмен',
  '_zddy._mj': 'Продавец',
  '_zddy._fhsy': 'Вернуться на домашнюю страницу',

  '_sjsy._ddpl': 'Список заказов',
  '_yhqd._czbz': 'Пожалуйста, свяжитесь со специальной службой поддержки клиентов, чтобы подтвердить информацию о пополнении счета перед пополнением счета.',
  '_yhqd._lxkf': 'Свяжитесь со службой поддержки клиентов',
  '_yhqd._qsrje': 'Пожалуйста, введите сумму пополнения',
  '_yhqd._wrhyhtd': 'Нет доступа к банку',
  '_yhqd._yhzz': 'Банковский перевод',
  '_yhbt._yhlx': 'Тип банка',
  '_zcyz._sjdxyz': 'Мобильная SMS-верификация',
  '_zcyz._sjhm': 'номер телефона',
  '_zcyz._srsjhm': 'Введите номер мобильного телефона',
  '_zzsd._qsrzzje': 'Пожалуйста, введите сумму перевода',
  '_zzsd._zzje': 'сумма перевода',
  '_txcd._qsrtxje': 'Пожалуйста, введите сумму вывода',
  '_txcd._skr': 'Получатель платежа',
  '_txcd._skr._ms': 'Пожалуйста, введите имя владельца карты',
  '_txcd._skzh': 'Номер платежного счета',
  '_txcd._skzh._ms': 'Пожалуйста, введите номер платежного счета',
  '_txcd._tjyhk': 'Добавить банковскую карту',
  '_txcd._txje': 'Сумма вывода',
  '_txcd._wdyhdz': 'Адрес банка',
  '_txcd._yhdm': 'код',
  '_txcd._yhdm._ms': 'Пожалуйста, введите код банка',
  '_txcd._yhdz': 'адрес',
  '_txcd._yhdz._ms': 'Пожалуйста, введите адрес банка',
  '_txcd._yhlx': 'тип',
  '_txcd._yhlx._ms': 'Пожалуйста, введите тип банковской карты',
  '_txcd._yhly': 'маршрутизация',
  '_txcd._yhly._ms': 'Пожалуйста, введите банковский маршрут',
  '_txcd._yhmc': 'банк',
  '_txcd._yhmc._ms': 'Пожалуйста, введите название банка',
  '_txcd._yhtx': 'Снятие наличных в банке',

  '_jys._gmjmhb': 'Купить криптовалюту',
  '_jys._gmzy': 'Вы можете зарегистрировать учетную запись и купить криптовалюту, перейдя на следующие биржи и в соответствии с политикой вашей текущей страны. Тогда пополняйте счет платформы! ',

  '_kdydj._bt': 'Откройте магазин и выиграйте большие призы',
  '_kdydj._xyzbt': 'Удача продолжает поворачиваться',
  '_kdydj._yx': 'Накачать {n} раз',
  '_kdydj._xh': 'Потребление {n} очков',

  '_kdydj._jf': 'Очки:',
  '_kdydj._zjjl': 'Победный рекорд',

  '_kdydj._hdgz': 'Правила активности',
  '_kdydj._qx': 'Пожалуйста',
  '_kdydj._dl': 'Войти',

  '_kdydj._mjdl': 'Логин продавца',
  '_kdydj._yxh': 'Электронная почта',
  '_kdydj._yxhsm': 'Пожалуйста, введите свой адрес электронной почты',
  '_kdydj._yxhmm': 'Пожалуйста, введите пароль',
  '_kdydj._rgmyzh': 'Если у вас нет учетной записи',
  '_kdydj._djzc': 'Нажмите, чтобы зарегистрироваться',
  '_kdydj._wjmm': 'Забыли пароль',

  '_kdydj._wdjp': 'Мой приз',
  '_kdydj._zjts': 'Советы для победителей',
  '_kdydj._jx': 'Продолжить',
  '_kdydj._czl': 'Выиграл',

  '_kdydj._ljjl': 'Накопительная награда',
  '_kdydj._ycy': 'Участвовал: {n} раз',
  '_kdydj._cy': 'Принять участие {n} раз',
  '_kdydj._lq': 'Получить',
  '_kdydj._ylq': 'Получено',
  '_kdydj._wdd': 'Не достигнуто',

  '_dk._dkbt1': 'Предпринимательский кредит решает проблему займа денег',
  '_dk._dkbt2': 'Предоставлять предпринимательские кредиты и услуги по обращению капитала',
  '_dk._dkbt3': 'Мы поможем вам решить проблемы с кредитом, такие как нехватка средств, негде взять деньги в долг или неловкость просить друзей одолжить деньги. Мы предоставляем профессиональные услуги на протяжении всего процесса и отвечаем на ваши вопросы в любое время. Цель – сделать каждый кредит прозрачным, позволяя клиентам использовать его быстро и с уверенностью. ',
  '_dk._zxsq': 'Онлайн-приложение',
  '_dk._wddk': 'Мой кредит',
  '_dk._aqwy': 'Безопасность и отсутствие забот',
  '_dk._lhed': 'Гибкая квота',
  '_dk._lhedb': 'Сумма: от 5,00 до 500 000, низкие проценты, гибкие методы погашения, отсутствие авансовых комиссий, онлайн-кредитование, отсутствие звонков для проверки, конфиденциальность информации и кредит в тот же день. ',
  '_dk._jsfk': 'Быстрый кредит',
  '_dk._jsfkb': 'Пройдите проверку за самый быстрый час, и оплата будет произведена сразу после проверки. ',
  '_dk._aqww1': 'Безопасно и без забот',
  '_dk._aqww1b': 'Никакого ипотечного кредита, никакого залога, никаких обратных звонков, это личное дело. ',

  '_dk._gywm': 'О нас',
  '_dk._gywm1': 'Мы стремимся предоставлять кредитные финансовые услуги, основываясь на философии бизнеса «клиент прежде всего» и ставя перед собой задачу решения проблем оборота капитала клиентов. С момента своего создания компания помогла бесчисленному количеству клиентов решить проблему оборота капитала. проблемы. ',
  '_dk._gywm2': 'Сумма: от 500 до 500 000, низкая процентная ставка, гибкие методы погашения, отсутствие авансовых комиссий, онлайн-кредитование, отсутствие звонков для проверки, конфиденциальность информации и кредит в тот же день. ',
  '_dk._gywm3': 'Если не хочешь занимать деньги у одноклассников и друзей, приходи к нам. ',
  '_dk._gywm4': 'Приверженность кредитным услугам для предпринимателей',
  '_dk._gywm5': 'Решите свои финансовые проблемы',
  '_dk._hzhb': 'Партнер - Платформа онлайн-кредитования',
  '_dk._smwda': 'Пользователи глобальной станции shopifyoutlets Business приезжают из 112 стран мира и используют для расчетов криптовалюту USDT/ETH/BTC/TRX. Криптовалюта — это безграничный способ торговли, позволяющий совершать мгновенные недорогие транзакции по всему миру без ожидания и международных комиссий. ',

  '_dk._yjwa': 'shopifyoutlets Business© Co., Ltd., 2022–2024 гг., Все права защищены',
  '_dk._yjwab': 'ShopifyOutlets Holdings Global Sourcing Limited (0008945) авторизована и регулируется Управлением финансового надзора в качестве кредитного брокера и работает исключительно с кредитором Home Retail Group CardServices Limited, торгуя как shopifyoutlets Business Financial Services, shopifyoutlets Business Financial Services. Услуги разрешены и регулируются Управлением финансового надзора для предоставления кредитов. Главная Розничная торговля GroupCard Services Limited зарегистрирована в Англии (04007072), Эйвбери, 489-499Avebury Boulevard, Милтон Кейнс, Великобритания. МК9 2НВ. ',

  '_dk._cyzjsq': 'Заполните заявку на бизнес-кредит',
  '_dk._dkll': 'Процентная ставка по кредиту',
  '_dk._dkll2': 'Описание процентной ставки по кредиту (нажмите, чтобы отобразить)',
  '_dk._gs1': 'Формула',
  '_dk._gs2': 'Интерес',
  '_dk._gs3': 'Сумма кредита',
  '_dk._gs4': 'Годовая процентная ставка',
  '_dk._gs5': 'Количество дней кредита',
  '_dk._gs6': 'например',
  '_dk._gs7': 'Сумма кредита',
  '_dk._gs8': 'Кредитный цикл',
  '_dk._gs9': 'день',
  '_dk._fkfs': 'Метод кредитования',
  '_dk._zhye': 'Баланс счета',
  '_dk._dkqx': 'Период кредита',
  '_dk._dkqx1': 'Выберите период кредита',
  '_dk._sqje': 'Сумма заявки',
  '_dk._qsrdk': 'Пожалуйста, введите кредит',
  '_dk._dkje': 'Сумма кредита',
  '_dk._gj': 'Национальность',
  '_dk._qxzgj': 'Пожалуйста, выберите национальность',
  '_dk._sqr': 'Заявитель',
  '_dk._qsrsqsm': 'Пожалуйста, введите имя заявителя',
  '_dk._zjhm': 'Идентификационный номер',
  '_dk._qsrzjhm': 'Пожалуйста, введите идентификационный номер',
  '_dk._zjzp': 'ID фото',
  '_dk._zjzp1': 'Пожалуйста, загрузите фотографию лицевой стороны вашего удостоверения личности',
  '_dk._zjfm1': 'Пожалуйста, загрузите фотографию обратной стороны вашего удостоверения личности',
  '_dk._zjsc1': 'Пожалуйста, загрузите фотографию вашего удостоверения личности',
  '_dk._zjzp2': 'Выбрать изображение',
  '_dk._pzslt': 'Пример фотографии',
  '_dk._wytyb': 'Я прочитал и согласен',
  '_dk._jkxy': 'Кредитное соглашение',
  '_dk._tjsq': 'Отправить заявку',

  '_dk._wddk2': 'Мой кредит',
  '_dk._ydk': 'Взаймы',
  '_dk._cslx': 'Вызывать интерес',
  '_dk._yjhk': 'Расчетный срок погашения',
  '_dk._dkjl': 'Запись о кредите',
  '_dk._hkjl': 'Запись о погашении',
  '_dk._sqrq': 'Дата подачи заявления',
  '_dk._hkzq': 'Цикл погашения',
  '_dk._gdll': 'Фиксированная процентная ставка',
  '_dk._hkz': 'Выполняется погашение',
  '_dk._yhk': 'Погашен',
  '_dk._sqz': 'Применение',
  '_dk._ytg': 'Пройдено',
  '_dk._ybh': 'Отклонено',
  '_dk._tqhk': 'Досрочное погашение',

  '_dk._ljhk': 'Погасить немедленно',
  '_dk._qrytqhk': 'Вы уверены, что хотите подать заявку на погашение заранее?',
  '_dk._qrytqhk1': 'Примечание: после того, как заявка будет успешной, внесенная сумма будет возвращена непосредственно в кредит в приоритетном порядке',
  '_dk._dkxq': 'Детали кредита',
  '_dk._dkje1': 'Сумма кредита',
  '_dk._yhlx': 'Причитающиеся проценты',
  '_dk._hkze': 'Общая сумма погашения',
  '_dk._sqhkz': 'Подача заявки на погашение',
  '_dk._zxydbty': 'Пожалуйста, внимательно прочитайте и установите флажок, чтобы согласиться с «Кредитным соглашением»',
  '_dk._zzsqz': 'Применение',

  '_dk._dksq': 'Кредит',

  '_dk._cs': 'Параметры',
  '_dk._nllsm': 'Выражается в процентах, например: 6,25%',
  '_dk._dktssm': 'Общее количество дней от начала до конца одобрения кредита',
  '_dk._yndts': 'Количество дней в году: обычно 365 дней',
  '_dk._zlj': 'Плата за просрочку',

  '_xbb._xz': 'Загрузить',
  '_xbb._xzapp': 'Загрузить приложение ShopifyOutlets',
  '_xbb._smewm': 'Отсканируйте QR-код для загрузки',
  '_xbb._syfl': 'Все категории',
  '_xbb._gdfl': 'Еще',
  '_xbb._sbbt1': 'Быстрая доставка',
  '_xbb._sbbt2': 'В случае возникновения проблем возможен возврат средств',
  '_xbb._sbbt3': 'Бесплатный возврат',
  '_xbb._sbbt4': 'В течение 90 дней',
  '_xbb._tjsp1': 'Рекомендуемые продукты',
  '_xbb._tjsp2': 'Много скидок',
  '_xbb._tjsp3': 'Ограниченная по времени скидка',

  '_xbb._asjgw': 'Покупки по магазинам',
  '_xbb._gdsp': 'Больше продуктов',
  '_xbb._ljgm': 'Купить сейчас',
  '_xbb._ptbz': 'Гарантия ShopifyOutlets',
  '_xbb._aqzf': 'Безопасный платеж',
  '_xbb._aqzf1': 'Способ оплаты, используемый многими международными покупателями',
  '_xbb._aqys': 'Безопасность и конфиденциальность',
  '_xbb._aqys1': 'Мы уважаем вашу конфиденциальность, поэтому ваша личная информация в безопасности',
  '_xbb._mjbh': 'Защита покупателя',
  '_xbb._mjbh1': 'Если ваш заказ не будет доставлен до ожидаемой даты или вы не удовлетворены заказом, вы можете получить возмещение',
  '_xbb._mzsm': 'Предупреждение/Отказ от ответственности',

  '_xbb._sjxx': 'Бизнесмен',
  '_xbb._ggms': 'Спецификация',
  '_xbb._mswa': 'Описание',
  '_xbb._khpl': 'Комментарии клиентов',
  '_xbb._zmfk': 'Положительный отзыв',
  '_xbb._gzrs': 'Следовать',
  '_xbb._gzrsan': 'Следовать',
  '_xbb._ygzrsan': 'Уже подписано',
  '_xbb._fsxx': 'Сообщение',
  '_xbb._myrpl': 'Комментариев пока нет',
  '_xbb._llgd': 'Показать больше',
  '_xbb._pdcl': 'Обещание',
  '_xbb._myf': 'Бесплатная доставка',
  '_xbb._sdsj': 'Доставка примерно через 5-10',
  '_xbb._ksjh': 'Быстрая доставка',
  '_xbb._bgdstk': 'Возврат средств за утерянную посылку',
  '_xbb._shbgtk': 'Если товар поврежден, верните деньги',
  '_xbb._s30gtk': 'Возврат средств, если не будет отправлен в течение 30 дней',
  '_xbb._aqyys': 'Безопасность и конфиденциальность',
  '_xbb._wjtrxk': 'Безопасная оплата: мы не будем передавать вашу личную информацию третьим лицам без вашего согласия. ',
  '_xbb._sfgrxx': 'Безопасность личных данных: мы защищаем вашу конфиденциальность и сохраняем ваши личные данные в безопасности. ',
  '_xbb._sl': 'Количество',
  '_xbb._kcld': 'Инвентарь',

  '_xbb._sjrz': 'Торговец обосновался',
  '_xbb._hezjh': 'Наша партнерская программа предоставляет вам полный спектр маркетинговой поддержки и услуг, а наша команда обслуживания клиентов предоставит профессиональную поддержку и советы, которые помогут вам оптимизировать отображение вашей продукции и стратегию продаж. Присоединяйтесь к нам сейчас! Давайте создадим больше возможностей для бизнеса и будем расти вместе! ',
  '_xbb._xsje': 'Деловая информация',
  '_xbb._rgsqjs': 'Если вы уже являетесь продавцом',
  '_xbb._qni': 'Пожалуйста',
  '_xbb._djdl': 'Нажмите, чтобы войти',
  '_xbb._dplog': 'ЛОГОТИП магазина',
  '_xbb._dpmc': 'Название магазина',
  '_xbb._srdpmc': 'Пожалуйста, введите название магазина',
  '_xbb._zjhm': 'Идентификационный номер',
  '_xbb._srzjhm': 'Пожалуйста, введите идентификационный номер',
  '_xbb._zsxm': 'настоящее имя',
  '_xbb._srzsxm': 'Пожалуйста, введите свое настоящее имя',
  '_xbb._srgj': 'Страна',
  '_xbb._srsrgj': 'Пожалуйста, укажите страну',
  '_xbb._zjz': 'Фото на удостоверение личности',
  '_xbb._zjzm': 'Передняя часть документа',
  '_xbb._zjfm': 'Обратная сторона сертификата',
  '_xbb._sczjz': 'Сохранить фото для удостоверения личности',
  '_xbb._pssl': 'Пример фотографии',
  '_xbb._dlyx': 'Адрес электронной почты для входа',
  '_xbb._srdlyx': 'Пожалуйста, введите адрес электронной почты для входа',
  '_xbb._dlmm': 'Пароль для входа',
  '_xbb._srdlmm': 'Пожалуйста, введите свой пароль для входа',
  '_xbb._qrmm': 'Подтвердите пароль',
  '_xbb._srqrmm': 'Пожалуйста, введите пароль подтверждения',
  '_xbb._yqm': 'пригласительный код',
  '_xbb._sryqm': 'Введите код приглашения',
  '_xbb._yxyzm': 'Код подтверждения электронной почты',
  '_xbb._sryzm': 'Введите код подтверждения электронной почты',
  '_xbb._yydbty': 'Я прочитал и согласен',
  '_xbb._rzxy': 'Соглашение об урегулировании',

  '_xbb._qgxty': 'Пожалуйста, внимательно прочитайте мировое соглашение и установите флажок, чтобы согласиться',
  '_xbb._sryqmb': 'Пожалуйста, введите код приглашения',

  '_xbb._ddpl': 'Комментарии к заказу',
  '_xbb._dsppl': 'Комментарии к продуктам',
  '_xbb._dddpl': 'Комментарий к заказу',

  '_xhd._sdj': 'Рождество'
}
