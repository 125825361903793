export default {
  'nav.account': 'パーソナル センター',
  'nav.language': '簡体字中国語',
  'signup.success': 'サインアップ成功',

  //エラー
  'contact.empty': '連絡先情報を空にすることはできません',
  'phone.country.empty': '電話プレフィックスを空にすることはできません',
  'phone.empty': '電話を空にすることはできません',
  'email.valid': '有効なメールアドレスを入力してください',

  // ベース
  'base.success': '成功',
  'base.save': '保存',
  'base.slide': 'スライダーを引いて確認してください',
  'base.send.code': '確認コードを送信',
  'base.send.time': '({time}) 確認コードを再送信',
  'base.send.success': '送信成功',
  'base.view.more': 'もっと見る',
  'base.Language': '言語',
  'base.previous': '前のページ',
  'base.next': '次のページ',
  'base.total.page': '合計 {page} ページ',
  'base.go.to.page': 'ページに移動',
  'base.go': '移動',
  'base.followers': 'フォロワー',
  'base.follow': 'フォロー',
  'base.following': 'フォロー中',
  'base.visit.store': 'ストアを参照',
  'base.contact': 'お問い合わせ',
  'base.delivery': '配送予定日: 1-7 日',
  'base.search': '検索',
  'base.search.text': '検索するキーワードを入力してください',
  'base.server': 'オンラインカスタマーサービス',

  //索引
  'text.categories.title': '商品カテゴリー',
  'text.welcome': 'ショッピングへようこそ!',
  'text.wishlist': 'ウィッシュリスト',
  'text.account': 'パーソナル センター',
  'text.orders': '注文',
  'text.messages': '情報',
  'text.suggest.title': '限定オファー',
  'text.suggest.content': '新しいグローバル プレミアム メンバー限定!',
  'text.super.deals': '信じられない価格のトップ商品。',

  //サインアップ
  'signup.register': 'サインアップ',
  'signup.signin': 'サインイン',
  'signup.store.signin': 'ビジネスログイン',
  'signup.sign.out': 'ログアウト',
  'signup.email': 'メールアドレス',
  'signup.password': 'パスワード',
  'signup.qr.password': 'パスワードの確認',
  'signup.confirm.password': 'パスワードの確認',
  'signup.forgot.password': 'パスワードを忘れた',
  'signup.invitation': '招待コード',
  'signup.char': 'キャラクター',
  'signup.contains': '数字、文字、記号を含む',
  'signup.qr.contains': '2 つのパスワードが一致していません',
  'signup.create.account': 'アカウントを作成',
  'signup.agree': 'アカウントを作成します。同意します',
  'signup.member.agreement': '会員規約',
  'signup.and': 'and',
  'signup.privacy.policy': 'プライバシー ポリシー',
  'signup.email.code': 'メール認証',
  'signup.last.step': '最後のステップ',
  'signup.send.email': '{email} に送信された 4 桁のコードを入力してください',
  'signup.modify.email': 'メールを変更',
  'signup.verify.email': '確認メール',
  'signup.have.store': 'マーチャント アカウントを持っている',
  'signup.goto.signin': 'ビジネスログイン',
  'signup.no.store': '販売アカウントがありません',
  'signup.goto.store': '加盟店の登録',
  'signup.next': '次へ',
  'signup.your.email': 'あなたのメールアドレス',
  'signup.code.text': '認証コード',
  'signup.submit.signup': '今すぐサインアップ',
  'signup.smrz': '実名認証',
  'signup.derb': '2 番目のステップ',
  'signup.qsrxm': 'ドキュメントの名前を入力してください',
  'signup.qsrhm': 'ID 番号を入力してください',

  //忘れた
  'forgot.title': 'パスワードをリセット',
  'forgot.btn.check': 'メールアドレスを確認',
  'forgot.reset.now': '今すぐリセット',

  //お店
  'store.info.open': '{y}週オープン',

  // フッター
  'footer.great.value': 'お得です',
  'footer.great.value.desc': '1 億以上の商品を競争力のある価格で提供しています。',
  'footer.shopping': 'グローバル ショッピング',
  'footer.shopping.desc': '当社は 200 を超える国と地域に発送しており、当社のウェブサイトは 7 か国語で利用できます。',
  'footer.safe.payment': '安全な支払い',
  'footer.safe.payment.desc': '世界で最も人気があり、最も安全な支払い方法で支払います。',
  'footer.shop.with.confidence': '自信を持って買い物をする',
  'footer.shop.with.confidence.desc': '当社の買い手保護ポリシーは、購入プロセス全体をカバーしています。',
  'footer.help.center': 'ヘルプセンター',
  'footer.help.center.desc': 'スムーズなショッピング体験を作成するための年中無休のサポート。',
  'footer.terms.conditions': '利用規約',
  'footer.return.policy': '返品ポリシー',
  'footer.support.policy': 'サポート ポリシー',
  'footer.privacy.policy': 'プライバシー ポリシー',
  'footer.be.seller': '売り手になる',
  'footer.apply.now': '今すぐ申し込む',
  'footer.stay.connected': '接続を維持',

  'footer.about.us': '私たちについて',
  'footer.about.company': '会社概要',
  'footer.about.video': '動画紹介',
  'footer.contact': '連絡先',

  'footer.my.account': '私のアカウント',
  'footer.my.logout': 'ログアウト',
  'footer.my.order': '注文履歴',
  'footer.my.wish': '私のウィッシュリスト',
  'footer.my.join': 'メンバー パートナーになる',
  'footer.email': 'メール',
  'footer.gfemail': '公式メールアドレス',
  'footer.fwemail': 'サービスメール',
  'footer.address': 'アドレス',

  'apply.success': '申請成功',
  'apply.success.desc': '正常に適用されました。ログイン アカウントに移動します',

  // カテゴリ
  'category.title': '関連カテゴリ',
  'category.all': 'すべてのカテゴリ',

  //詳細
  'detail.store.home': '店舗ホーム',
  'detail.sale.items': '商品一覧',
  'detail.recommend': 'おすすめ',
  'detail.orders': '販売',
  'detail.quantity': '量',
  'detail.pieces.available': '利用可能',
  'detail.delivery': '配送',
  'detail.free.shipping': '送料無料',
  'detail.estimated.delivery': '配送予定',
  'detail.days': '日数',
  'detail.buy.now': '購入',
  'detail.add.to.cart': 'カートに入れる',
  'detail.buyer.protection': '買い手保護',
  'detail.money.guarantee': '返金保証',
  'detail.refund.desc': '商品が説明と異なる場合、または配送されなかった場合は、全額返金されます',
  'detail.description': '商品説明',
  'detail.customer.reviews': 'カスタマーレビュー',
  'detail.specifications': '製品仕様',
  'detail.top.selling.products': 'ベストセラー商品',
  'detail.recommended.for.you': 'あなたへのおすすめ',
  'detail.sold': '販売',
  'detail.receipt': '領収書の確認',
  'detail.receipt.title': '注文の受領を確認しますか?',
  'detail.receipt.content': '確認後、取引注文完了',
  'detail.comment': 'コメント',

  //確認
  'confirm.shipping.address': '受取人の住所',
  'confirm.change': '変更',
  'confirm.payment.methods': '支払い方法',
  'confirm.summary': '決済',
  'confirm.total.item.costs': 'アイテム合計',
  'confirm.total': '合計',
  'confirm.checkout': 'チェックアウト',
  'confirm.place.order': '今すぐ注文する',
  'confirm.pay.now': '今すぐ支払う',
  'confirm.order.desc': '[注文する] をクリックした後、内容を確認して確認しました',
  'confirm.order.policy': 'すべての条件とポリシー',
  'confirm.payment': 'グローバル ショッピングは、お客様の情報と支払いのセキュリティを保証します',

  // 住所
  'address.title': '受信者アドレス',
  'address.default': 'デフォルト',
  'address.edit': '編集',
  'address.delete': '削除',
  'address.new': '新しいアドレスを作成する',
  'address.contact': '連絡先',
  'address.address': '住所',
  'address.phone': '電話番号',
  'address.set.default': 'デフォルトに設定',
  'address.confirm': '確認',
  'address.cancel': 'キャンセル',
  'address.del.title': 'アドレスの削除を確認しますか?',
  'address.del.content': 'この配送先住所を削除してもよろしいですか?',

  'payment.method': '支払い方法',

  // ショッピングカート
  'shopping.title': 'ショッピングカート',
  'shopping.back.buy': '特別なショッピングに戻る',
  'shopping.start.shopping': 'ショッピングを開始',

  // メンバー
  'member.account': 'パーソナル センター',
  'member.overview': '概要',
  'member.orders': '注文',
  'member.payment': '私の財布',
  'member.address': '配送先住所',
  'member.wishlist': 'ウィッシュリスト',
  'member.followlist': 'フォローリスト',
  'member.message.center': 'メッセージ センター',
  'member.setting': '個人設定',
  'member.shop.info': 'ショップ情報',
  'member.shop.setting': 'ショップ設定',
  'member.order.notify': '新規注文通知',
  'member.order.tips': 'マーチャント アカウントのメールにメールを送信することにより',

  // member.order
  'member.order.title': '私の注文',
  'member.order.view.all': 'すべて表示',
  'member.order.all': 'すべて',
  'member.order.unpaid': '未払い',
  'member.order.paid': '有料',
  'member.order.procurement': '保留中の調達',
  'member.order.seller.paid': '購入済み',
  'member.order.processing': '保留中',
  'member.order.shipped': '発送済み',
  'member.order.return': '返品中',
  'member.order.completed': '完了',
  'member.order.refund': '返金済み',
  'member.order.all.time': 'すべて',
  'member.order.empty': 'まだ注文がありません',
  'member.order.date': '日付',
  'member.order.purchase.date': '購入',
  'member.order.cpoy': 'コピー',
  'member.order.id': '注文',
  'member.order.detail': '注文の詳細',
  'member.order.logical': '物流情報',

  // メンバー.支払い
  'member.payment.title': '私の財布',
  'member.wallet.balance': 'ウォレットの残高',
  'member.crypto.recharge': '再充電',
  'member.crypto.withdrawal': '撤退',
  'member.crypto.bank': '銀行カード',
  'member.wallet.record': 'コイン入金記録',
  'member.bankcard.record': '銀行カードの記録',
  'member.withdrawal.record': '退会記録',
  'member.income.record': '収入記録',
  'member.transaction.record': '取引記録',
  'member.wallet.freeze': '資金を凍結',
  'member.wallet.profit': '推定収益',

  //再充電
  'recharge.currency': '通貨',
  'recharge.protocol': 'プロトコル',
  'recharge.qrcode': 'QRコード',
  'recharge.address': 'ウォレットアドレス',
  'recharge.copy': 'コピーアドレス',
  'recharge.ok.text': '確認',
  'recharge.cancel.text': 'キャンセル',

  //銀行
  'bank.recharge.title': '銀行カードのリチャージ',
  'bank.title': '銀行名',
  'bank.name': '名前',
  'bank.account': '口座',
  'bank.routing': 'ルーティング',
  'bank.code': 'コード',
  'bank.bankname': '銀行名',
  'bank.bankaddress': '銀行の住所',
  'bank.ok.text': '次へ',
  'bank.ok.prev': '前のステップ',
  'bank.submit': '今すぐ提出',
  'bank.amount': 'チャージ金額',
  'bank.amount.desc': 'チャージ金額を入力してください',
  'bank.type': 'タイプ',
  'bank.usd': '米ドル',
  'bank.eur': 'ユーロ',
  'bank.receipt.number': '領収書番号',
  'bank.receipt.number.desc': '取引受付番号を入力してください',
  'bank.credential.picture': '資格証明画像',
  'bank.credential.picture.desc': '認証写真をアップロードしてください',
  'bank.remark': '備考',
  'bank.upload': '画像をアップロード',
  'bank.text.title': 'メモ',
  'bank.text.t1': 'お振込みの場合は備考不要ですので空欄にしてください',
  'bank.text.t2': '香港の世帯の場合は、中国ではなく香港を選択してください',
  'bank.text.t3': 'アメリカの送金は平日、ヨーロッパの送金は午後 3 時までに送金してください。送金は銀行の営業時間内に可能です!',
  'bank.text.t4': '支払い時間は T+1 で、最後の支払いはヨーロッパで T+3 です',
  'bank.text.t5': '送金前にプラットフォームに連絡して、口座が利用可能かどうかを確認してください。口座が閉鎖された場合、補償は支払われません。',
  'bank.text.t6': '銀行カードによる入金。すべての顧客サービス情報が優先されます。',

  //バンクリスト
  'bank.list.title': '銀行名',
  'bank.list.code': '銀行コード',
  'bank.list.amount': '送金金額',
  'bank.list.number': '領収書番号',
  'bank.list.img': 'クーポン画像',
  'bank.list.status': 'ステータス',
  'bank.list.time': '時間',
  'bank.list.status1': '審査中',
  'bank.list.status2': '合格',
  'bank.list.status3': '拒否',

  // 出金
  'withdrawal.address.desc': 'ウォレットアドレスを入力してください!',
  'withdrawal.number': '量',
  'withdrawal.real.number': '実際の到着',
  'withdrawal.number.desc': '出金額を入力してください!',
  'withdrawal.btn.all': 'すべて',
  'withdrawal.balance': '残高',
  'withdrawal.commission': 'コミッション',
  'withdrawal.actual.amount': 'アカウント',
  'withdrawal.notice': 'お知らせ',
  'withdrawal.notice.text': '譲渡する前に、受取先住所情報が正しいことを確認してください。資産が譲渡されると、それらは返却できません。',
  'withdrawal.notice.content': '{name}({cp_name}) 手数料: 現在の市場価値 {fee_rate}%/トランザクション、最低基準: {fee_min} {name}/トランザクション',
  'withdrawal.submit': '提出',
  'withdrawal.choice': '暗号通貨を選択',
  'withdrawal.yzm': '検証コード',
  'withdrawal.fs': '送信',
  'withdrawal.qsryzm': 'メール認証コードを入力してください',

  // 受信
  'recive.method': 'プロトコル',
  'recive.amount': '総額',
  'recive.address': 'アドレス',
  'recive.date': '時間',
  'recive.status': 'ステータス',
  'recive.create.at': 'トランザクション時間',
  'recive.type': 'タイプ',
  'recive.befor': '取引前',
  'recive.balance': '残高',
  'recive.freeze': 'フリーズ',
  'recive.review': 'レビュー中',
  'recive.success': '合格',
  'recive.reject': '却下',

  // アドバタイズ
  'advertise.title': 'プロモーション センター',
  'advertise.shop.title': 'ショップのプロモーション',
  'advertise.shop.status': 'ステータス',
  'advertise.shop.expired': '期限切れ',
  'advertise.shop.promotion': '宣伝中',
  'advertise.shop.expire.date': '有効期限',
  'advertise.shop.renew': '広告の更新',
  'advertise.shop.payable': 'お支払い金額',
  'advertise.shop.explanation': '広告の説明',
  'advertise.shop.text': 'プラットフォームの広告宣伝に参加し、店舗での露出を増やし、取引注文を促進する',
  'advertise.shop.paynow': 'すぐに更新',
  'advertise.shop.modal.title': '支払いの確認',
  'advertise.shop.modal.desc': 'プロモーション料金の支払いを確認しました',
  'advertise.shop.modal.btn': '支払いの確認',

  //ウィッシュリスト
  'wishlist.title': 'ウィッシュリスト',
  'wishlist.delete': '削除',
  'wishlist.orders': '販売',

  //フォローリスト
  'followlist.title': 'フォローリスト',
  'followlist.delete': '削除',
  'followlist.follow': 'フォロー',

  // お店
  'store.dashboard': 'ダッシュボード',
  'store.products': '商品',
  'store.products.list': '商品一覧',
  'store.products.reviews': '商品レビュー',
  'store.orders': '注文',
  'store.wallet': 'ウォレット',
  'store.message': 'メッセージ センター',
  'store.setting': '設定',
  'store.order.total.profit': '推定総営業利益',

  //ダッシュボード
  'dashboard.store.hour.views': 'リアルタイム訪問',
  'dashboard.store.today.views': '今日のビュー',
  'dashboard.product.total': '総製品',
  'dashboard.product.today': '本日追加',
  'dashboard.order.total': '注文合計',
  'dashboard.sales.total': '推定売上高',
  'dashboard.sales.real': '実際の売上',
  'dashboard.sales.pay': '支払い総額',
  'dashboard.sales.profit': '総利益',
  'dashboard.commission.total': '総収入',
  'dashboard.commission.today': '今日の収益',
  'dashboard.order.sales': '商品の売上',
  'dashboard.sales.list': '売上ランキング',
  'dashboard.goods.cate.rate': '商品カテゴリ比率',
  'dashboard.goods.wish': 'いいね！グッズランキング',
  'dashboard.january': '1月',
  'dashboard.february': '2月',
  'dashboard.march': '3月',
  'dashboard.april': '4月',
  'dashboard.may': '5月',
  'dashboard.june': '6月',
  'dashboard.july': '7月',
  'dashboard.august': '8月',
  'dashboard.september': '9月',
  'dashboard.october': '10月',
  'dashboard.november': '11月',
  'dashboard.december': '12月',

  // 製品
  'products.add.new': '新しい商品を追加',
  'products.add.from.warehouse': '倉庫から商品を追加',
  'products.delete': '削除',
  'products.add': '追加',
  'products.table.img': '画像',
  'products.table.name': '商品名',
  'products.table.category': 'カテゴリー',
  'products.table.wish': 'いいね',
  'products.table.stock': '在庫',
  'products.table.price': '価格',
  'products.table.profit': '利益',
  'products.table.action': 'アクション',
  'products.search.category': 'カテゴリーで検索',
  'products.back.product': '商品リストに戻る',
  'products.total': '総製品',
  'products.yes': 'はい',
  'products.no': 'キャンセル',
  'products.batch.add': '一括追加',
  'products.ask.add': '本当に製品を追加しますか?',
  'products.batch.delete': '一括削除',
  'products.ask.delete': '本当に製品を削除しますか?',
  'products.top': 'トップ',
  'products.syzd': 'ホームページトップ',
  'products.zdwz': 'トップ ポジション',
  'products.t1': 'プロモーション',
  'products.t2': '優先',
  'products.t3': 'ブティック',
  'products.t4': 'なし',

  // レビュー
  'reviews.title': '商品レビュー',
  'reviews.product.name': '商品',
  'reviews.user.name': 'ユーザー',
  'reviews.star': '評価',
  'reviews.comment': 'レビューの内容',
  'reviews.sku': '商品仕様',
  'reviews.imgs': 'フォト ギャラリー',
  'reviews.created': '日付',

  // store.order
  'store.order.purchase': '今すぐ購入',
  'store.order.purchase.desc': 'この商品の支払いをしてもよろしいですか?',
  'store.order.purchase.yes': 'すぐに支払う',
  'store.order.purchase.no': 'キャンセル',
  'store.order.desc': 'この注文を購入するには支払いが必要であり、注文が完了すると特典を受け取ります。',
  'store.order.no': '注文番号',
  'store.order.number': '数量',
  'store.order.buyer': 'バイヤー',
  'store.order.total': '合計金額',
  'store.order.will.earning': '収益',
  'store.order.profit': '利益',
  'store.order.dividends': '配当',
  'store.order.payment.status': '支払い状況',
  'store.order.seller.buy.status': '購入状況',
  'store.order.status': '注文状況',
  'store.order.date': '注文日',
  'store.order.purchase.date': '購入日',
  'store.order.action': 'アクション',
  'store.order.purchase.amount': '購入金額',

  //所得
  'income.create.at': '記録時間',
  'income.order.sn': '注文番号',
  'income.realpay': '注文金額',
  'income.profit': '収入',

  //設定
  'setting.avatar': 'ビジネスアバター',
  'setting.upload': 'アップロード',
  'setting.shop.name': 'ショップ名',
  'setting.shop.phone': '連絡先電話',
  'setting.shop.address': 'ショップの住所',
  'setting.shop.save': '保存',
  'setting.upload.pic': '画像をアップロード',
  'setting.send.pic': '写真を送る',

  //ベセラー
  'beseller.title': '加盟店に申し込む',
  'beseller.account': 'アカウント情報',
  'beseller.store': '店舗情報',
  'beseller.store.name': 'ストア名',
  'beseller.store.address': '店舗住所',

  //セーブホーム
  'store.home.title': 'ホームページ設定',
  'store.home.topimg': 'トップ画像',
  'store.home.banner': 'カルーセル',
  'store.home.up3': '備考: 少なくとも 3 回のアップロード',
  'store.upload.more': '複数の写真をアップロード',
  'store.home.columns': '列',
  'store.home.bgimg': '背景画像',
  'store.goods.remark': '備考: 1 行あたり 5 アイテム、セクションあたり最大 10 アイテム',
  'store.home.select': '商品を選択',
  'store.home.add': '列を追加',

  //個人情報
  'setting.update.user': '個人情報',
  'setting.update.info': '情報を修正',
  'setting.user.avatar': 'ユーザーアバター',
  'setting.user.nickname': 'ユーザーのニックネーム',
  'setting.user.nickname.desc': 'ユーザーのニックネームを入力してください',

  'setting.safe.title': '安全情報',
  'setting.user.passwd': 'パスワードを変更',
  'setting.passwd.title1': '古いパスワード',
  'setting.passwd.desc1': '古いパスワードを入力してください',
  'setting.passwd.title2': '新しいパスワード',
  'setting.passwd.desc2': '新しいパスワードを入力してください',
  'setting.passwd.title3': 'パスワードの確認',
  'setting.passwd.desc3': '確認用パスワードを入力してください',
  'setting.invitation': '友達を招待',
  'setting.copy': 'コピー',

  'adv.add.products': 'プロモーション製品を追加',
  'adv.list.title': '製品プロモーション',
  'adv.type': 'プロモーション タイプ',
  'adv.begin.time': 'プロモーション時間',
  'adv.end.time': '終了時間',
  'adv.status': 'ステータス',
  'adv.status.s1': '昇格中',
  'adv.status.s2': '終了',
  'adv.add.back': '昇格したリストに戻る',
  'adv.recharge': 'リチャージ プロモーション ポイント',
  'adv.select.goods': '商品を選択',
  'adv.select.btn': 'プロモーション製品',
  'adv.recharge.title': 'リチャージプロモーションポイント',
  'adv.recharge.balance': 'ポイント残高',
  'adv.point': 'ポイント',
  'adv.point.rate': 'ポイント率',
  'adv.recharge.amount': 'リチャージ量',
  'adv.recharge.input.amount': 'リチャージ額を入力してください',
  'adv.select.mod': '選択パネル',
  'adv.mod1': '推奨列',
  'adv.mod2': '優先列',
  'adv.mod3': '売れ筋コラム',
  'adv.mod4': '優れたリスト',
  'adv.mod5': '分類リスト',
  'adv.mod6': '詳細プロモーション',
  'adv.ge': '量',
  'adv.xs': '時間',
  'adv.xzbk': 'プレートを選択',
  'adv.syme': '残りのクォータ',
  'adv.tgsc': 'プロモーション期間',
  'adv.tgjg': 'プロモーション価格',
  'adv.syye': '現在の残高',
  'adv.tgye': 'プロモーションバランス',
  'adv.yj1': '予想',
  'adv.yj2': 'プロモーションを開始',
  'adv.pay.point': '消費ポイント',

  'wallet.ykcsxf': '手数料が差し引かれました',
  'wallet.sxf': 'サービス手数料',
  'wallet.zxje': '最低額',
  'wallet.24hxe': '24時間制限',
  'wallet.desc.text': 'どのような状況下でも、当局はあなたに口座への送金を要求したり、確認コードを要求したりすることはありません. 代理購入、マネーロンダリング、違法な資金調達などの違法行為に加担せず、オンライン詐欺にご注意ください',
  'wallet.cunbi.text': 'このアドレスに入金できるのは {name} のみです。他の資産は取得されません',

  'credit.title': '与信限度額',
  'credit.ed': 'クォータ',
  'credit.hk': '返済',
  'credit.kyye': '利用可能な残高',
  'credit.dqqk': '現在の延滞',
  'credit.hkje': '返済額',
  'credit.hkje.desc': '返済額を入力してください',
  'credit.cgje': '購入金額',
  'credit.qrz': '証明書',
  'credit.srxyj': 'クレジット支払い額を入力してください',

  'store.gzl': 'フォロー',
  'store.wxz': '無制限',

  'auth.smrz': '実名認証',
  'auth.rzlx': 'タイプ',
  'auth.sfzm': 'ID カード前面',
  'auth.sffm': 'IDカードの裏面',
  'auth.zsxm': '本名',
  'auth.zjhm': '文書番号',
  'auth.yyzz': 'ビジネスライセンス',
  'auth.gsmc': '会社名',
  'auth.shz': '監査中',
  'auth.ytg': '合格',
  'auth.ybh': '拒否されました',
  'auth.zt': 'ステータス',
  'auth.gr': '個人',
  'auth.gs': 'エンタープライズ',
  'auth.ljtj': '認証申請',
  'auth.wrz': '未認証',

  'credit.qts': '説明を見る',
  'store.spss': '商品上限',
  'store.dpfh': 'ストアの配当',
  'store.qydj': '株式水準',
  'store.level': 'レベル',
  'store.jian': 'ピース',

  'order.notify.email': '電子メールのリスク管理のため、プラットフォームの注文リマインダー メールが頻繁に送信され、傍受される可能性があります。時間内に受信するには、ログインに {e} を追加することをお勧めしますメールのホワイトリスト',

  'setting.sjxx': '情報',
  'setting.smrz': '認証',
  'setting.aqzx': 'セキュリティ',
  'setting.tzxx': '通知',
  'setting.dpzx': '装飾',
  'setting.sjqy': 'エクイティ',
  'setting.sjdjqy': '加盟店レベル特典の説明',

  'setting.fhbky': '現在の配当は利用できません',
  'setting.spgzyzy': 'ストアのルールとガイドライン',

  'desc.dqqy': '現在の株式',
  'desc.xjqy': '従属利益',
  'desc.sjqy_0': 'ストアの与信限度額',
  'desc.sjqy_1': 'ストア配当',
  'desc.sjqy_2': '掲載可能商品数',

  'share.yqlb': '招待リスト',
  'share.zmj': 'トータルセラー',
  'share.zdd': '注文合計',
  'share.zsy': '仲介収入総額',
  'share.wcl': '未処理',
  'share.ycl': '処理済み',
  'share.ywc': '完了',
  'share.dpmc': 'ストア名',
  'share.ddl': '注文数量',
  'share.jjsy': '仲介収入',
  'share.zts': '総アイテム数',

  'chat.state': '状態',
  'chat.zx': 'オンライン',
  'chat.ljz': '接続中',

  'bind.wallet.title': '出金アドレス',
  'bind.address.title': 'アドレスを追加',
  'bind.bj': '編集',
  'bind.sc': '削除',
  'bind.qrsc': 'このアドレスを削除してもよろしいですか?',
  'bind.qbdz': 'ウォレットアドレス',
  'bind.tjsj': '時間を追加',
  'bind.cz': '操作',
  'withdrawal.address.choice': '引き出しアドレスを選択してください!',

  'order.sxsj': '有効時間',
  'order.ljsj': '即時有効',
  'order.dssj': '時間制限付き有効',

  'store.st.zt': 'ストアステータス',
  'store.st.zc': '通常',
  'store.st.zc.desc': 'あなたのストアは通常通りです、そのままにしておいてください',
  'store.st.yc': '例外',
  'store.st.yc.desc': 'あなたのストアには異常な注文があるか、顧客から苦情を受けています',
  'store.st.dj': 'フリーズ',
  'store.st.dj.desc': 'あなたのストアには一定の運営リスクがあり、資金は凍結されています',
  'store.st.jy': '無効',
  'store.st.jy.desc': 'あなたのストアはプラットフォーム関連の契約と規則に違反したため、無効になりました',

  'task.sy': 'ホームページ',
  'task.rwlbo': 'タスクリスト',
  'task.rwxqo': 'タスクの詳細',
  'task.ljcy': '今すぐ参加',
  'task.gdrw': 'その他のタスク',
  'task.rwlb': '取得タスクのリスト',
  'task.qbrw': 'すべてのタスク',
  'task.wcyd': '参加しました',
  'task.qdz': '注文の取得',
  'task.ywc': '完了',
  'task.djy': '取引される',
  'task.ddje': '注文金額',
  'task.cyrs': '参加者数',
  'task.ewsy': '追加の特典',
  'task.jzsj': '期限',
  'task.ckxq': '詳細を表示',
  'task.rwxq': 'タスクの詳細',
  'task.cylb': '参加リスト',
  'task.qdcyrw': 'この注文タスクに参加してもよろしいですか?',
  'task.sd': 'はい',
  'task.bue': 'いいえ',

  'task.wdsy': '収益',
  'task.wdfh': '配当',
  'task.wdqd': '注文を取得',
  'task.dd.leixin': '注文タイプ',
  'task.dd.qdrw': 'クイックオーダータスク',
  'task.dd.ptdd': '通常の順序',

  'flow.lltg': 'フロープロモーション',
  'flow.gmll': 'データ パッケージを購入',
  'flow.dqtc': '現在のパッケージ',
  'flow.tc1': 'パッケージ',
  'flow.sy2': '残り',
  'flow.tian3': '日',
  'flow.jlzs': 'レコードの総数',
  'flow.lltc': 'フローパッケージ',
  'flow.ll': 'フロー',
  'flow.gmsj': '購入時間',
  'flow.tcjg': 'パッケージ価格',
  'flow.sjzf': '実際の支払い',
  'flow.tcsm': 'パッケージの説明',
  'flow.tcsm.sm': 'このパッケージを購入すると、ストアの露出が増加し、より多くの顧客トラフィックを獲得できます。 より多くの注文を獲得し、店舗の収益を増やすため。 ',

  'flow.tcmc': 'パッケージ名',
  'flow.gmsc': '購入期間',
  'flow.zje': '合計金額',
  'flow.jzsj': '締め切り',
  'flow.gmrq': '購入日',
  'flow.tian1': '日',
  'flow.zhou1': '週',
  'flow.yue1': '月',

  'share.dj': 'レベル',
  'share.hz': '転送',
  'share.hzd': '転送先',
  'share.qbye': 'ウォレット残高',
  'share.tgje': 'プロモーション金額',
  'share.hzje': '送金金額',
  'share.qsrhzje': '送金額を入力してください',

  '_inv._title': '招待の説明',
  '_inv._t1': '1. 招待と招待された関係',
  '_inv._desc1': 'A は B を招待し、B は C を招待し、C は D を招待します。 最高レベルは 4 で、D が再度 E を招待した場合、E は A と何の関係もありません。 ',
  '_inv._t2': '2. 招待者は報酬を受け取ります',
  '_inv._desc2': 'A は注文 B で完了した合計金額の 4% を取得し、A は注文 C で完了した合計金額の 2% を取得し、A は注文 D で完了した合計金額の 1% を取得します',

  '_index._gm': '購入',
  '_index._sm': '説明: 店舗の露出を増やし、トラフィックを引き寄せます',

  '_shop._update': '月に {n} 回、今月は {m} 回変更されました',

  '_chat._hc': '撤退',

  '_footer._suom': 'ポリシーの説明',

  '_footer._platform._qualification': '会社の資格',
  '_store._ptzz': 'ShopifyOutlets プラットフォーム関連の資格',

  '_abt._gywm': '私たちについて',
  '_abt._syms': 'ビジネスモデル',
  '_abt._qyzr': '企業の責任',
  '_abt._lxwm': 'お問い合わせ',

  '_wsj._wsjms': '不気味なハロウィーン ストアで、スタイリッシュにハロウィーンを祝いましょう。この不気味な季節にトリックを探しているのか、それともご褒美を探しているのかに関係なく、ハロウィーンの衣装、装飾品、アクセサリーのコレクションには、ご家族全員が楽しめるものが揃っています。 「ウィメンズとメンズのコレクションで背筋が凍るようなステートメントを表現したり、仮装コスチュームでお子様の想像力を解き放ちましょう。忘れられないかわいい家庭用品やおもちゃから、魅力的な美容品や化粧品まで、一年で最もエキサイティングなホリデーを祝うのに最適なアイテムを見つけてください。',

  '_wsj._b1': '秋の室内装飾',
  '_wsj._b2': 'カボチャ',
  '_wsj._b3': 'ホーム',
  '_wsj._b4': '子供向け',
  '_wsj._b5': '赤ちゃんの',
  '_wsj._b6': '女性用',
  '_wsj._b7': '仮装',
  '_wsj._b8': 'パーソナライズされた',

  '_wsj._cj': '参加',
  '_wsj._gz': 'アクティビティ ルール',
  '_wsj._flss': 'カテゴリ検索',
  '_wsj._wsnz': 'ハロウィンの婦人服',
  '_wsj._wsn': 'ハロウィンの紳士服',
  '_wsj._wst': 'ハロウィン子供服',
  '_wsj._wszs': 'ハロウィンの飾り',
  '_wsj._wslp': 'ハロウィンギフト',

  '_wsj._desc1': '「ハロウィンスペシャル、トリック・オア・トリート！」 今年の10月は、カーニバル・パーティーをご用意しました！ あらゆる種類の怖くて衝撃的な製品を手に取りに来てください。大きな割引があなたを待っています! ',
  '_wsj._lkm': '買い物に行く',

  '_wsj._wsj': 'ハロウィン',

  '_wsj._wsjjz': 'ハロウィンが近づいています',
  '_wsj._mzbt': 'ビューティーアドベントカレンダー',
  '_wsj._mznr': '一年で最も素晴らしい時期へのカウントダウンが、誰もが楽しめるアドベントカレンダーとともに登場しました。',

  '_chr._tt1': 'クリスマスの買い物',
  '_chr._tt2': 'クリスマスの飾り',
  '_chr._tt3': 'すべてのギフトを購入',
  '_chr._tt4': 'クリスマスのナイトウェアをすべて購入',

  '_chr._dt1': 'クリスマスへの準備は、大切な日と同じくらい魔法のようです。 だから、クリスマスの魔法に備えて、プレゼントを整理し、玄関を飾り付け、おそろいのパジャマを着て準備を整えてください。',

  '_chr._dtt1': 'クリスマスの服装',
  '_chr._dtt2': 'お揃いのファミリークリスマス',
  '_chr._dtt3': 'アドベントカレンダー',
  '_chr._dtt4': 'お家でクリスマス',
  '_chr._dtt5': '居心地の良いショップ',
  '_chr._dtt6': 'クリスマスに間に合う家具',

  '_chr._tre1': '今トレンド',
  '_chr._tre2': 'みんなが大好きなトレンドで、ご自宅にちょっとしたお祝いの楽しみを加えましょう。',
  '_chr._tre3': 'ジンジャーブレッド',
  '_chr._tre4': 'クリスマスプディング',
  '_chr._tre5': 'クリスマスゴンク',

  '_chr._ttre1': 'カードと包装紙',
  '_chr._ttre2': 'おもちゃとゲーム',
  '_chr._ttre3': '食べ物と飲み物のギフト',
  '_chr._ttre4': 'ストッキングの詰め物',
  '_chr._ttre5': 'シークレットサンタ',
  '_chr._ttre6': '赤ちゃんの初めてのクリスマス',
  '_chr._ttre7': 'ビューティーギフト',
  '_chr._ttre8': 'プレミアムギフト',
  '_chr._ttre9': 'パーソナライズされたギフト',

  '_sdj._sdnz': '婦人服',
  '_sdj._sdn': '紳士服',
  '_sdj._sdetlw': 'ギフト',
  '_sdj._sdzs': '装飾',
  '_sdj._sdjj': 'おもちゃ',

  '_sdj._title1': 'クリスマスが近づいています',
  '_sdj._desc1': '「クリスマス：贈りものと感謝の季節。」今年の12月は、華やかで心温まるファミリーパーティーをご用意しました！ 明るくて温かみのあるさまざまな製品を大幅な割引価格で手に入れてください。 ',

  '_ssd._lhpp': '柔軟な支払い',
  '_ssd._lzhq': 'ソース グローバル',
  '_ssd._pwyx': '優れていると評価されました',
  '_ssd._qsth': '簡単な返品',
  '_ssd._jgppcn': '価格のマッチングとコミットメント',
  '_ssd._ztbt': 'グローバルなワンストップ ショッピングを作成',
  '_ssd._yzspl': '8,000 を超える独立系ブランドから在庫を直接調達',
  '_ssd._zcgm': '購入登録',
  '_ssd._zccs': '販売のために登録',

  '_ssd._dlwz': 'ログインして登録',

  '_ssd._qjrgwc': 'すべてカートに追加',

  '_zddy._gzlb': 'ウォッチリスト',
  '_zddy._zsersxs': 'シアーズグローバルで販売',
  '_zddy._zccwsj': '販売者として登録',
  '_zddy._dlyh': 'ログイン',
  '_zddy._zcyh': '登録',
  '_zddy._xyh': '新規ユーザー',
  '_zddy._gwc': 'ショッピングカート',
  '_zddy._znxx': 'メッセージ',
  '_zddy._zshy': '最も人気のある',
  '_zddy._ddl': '注文',
  '_zddy._mysj': 'データがありません',
  '_zddy._ckgd': 'もっと見る',
  '_zddy._tjsp': '推奨製品',
  '_zddy._rmsp': '人気の製品、新しいトレンド、より多くの購入機会',
  '_zddy._xsp': '新製品',
  '_zddy._jlzzr': '過去 2 週間で最も人気のあるアイテム',
  '_zddy._ljgm': '今すぐ購入',
  '_zddy._gdsp': 'さらに人気のある製品',
  '_zddy._ssbt': '検索',
  '_zddy._ssgjz': '探しています...',

  '_zddy._bdhb': '現地通貨',
  '_zddy._xzbdhb': '現地通貨を選択',
  '_zddy._zje': '合計金額',
  '_zddy._xzzffs': 'ご希望のお支払い方法を選択してください',
  '_zddy._jmhb': '暗号通貨',

  '_zddy._cp': '製品',
  '_zddy._sj': 'ビジネスマン',
  '_zddy._mj': '販売者',
  '_zddy._fhsy': 'ホームページに戻る',

  '_sjsy._ddpl': '注文リスト',
  '_yhqd._czbz': '充電前に専用カスタマーサービスにお問い合わせいただき、充電情報をご確認ください。',
  '_yhqd._lxkf': 'カスタマーサービスに連絡する',
  '_yhqd._qsrje': 'チャージ金額を入力してください',
  '_yhqd._wrhyhtd': '銀行アクセス不可',
  '_yhqd._yhzz': '銀行振込',
  '_yhbt._yhlx': '銀行の種類',
  '_zcyz._sjdxyz': 'モバイルSMS認証',
  '_zcyz._sjhm': '電話番号',
  '_zcyz._srsjhm': '携帯電話番号を入力してください',
  '_zzsd._qsrzzje': '送金額を入力してください',
  '_zzsd._zzje': '払込金額',
  '_txcd._qsrtxje': '出金額を入力してください',
  '_txcd._skr': '受取人',
  '_txcd._skr._ms': 'カード所有者名を入力してください',
  '_txcd._skzh': '支払い口座番号',
  '_txcd._skzh._ms': 'お支払い口座番号を入力してください',
  '_txcd._tjyhk': '銀行カードを追加する',
  '_txcd._txje': '出金金額',
  '_txcd._wdyhdz': '銀行住所',
  '_txcd._yhdm': 'コード',
  '_txcd._yhdm._ms': '銀行コードを入力してください',
  '_txcd._yhdz': '住所',
  '_txcd._yhdz._ms': '銀行の住所を入力してください',
  '_txcd._yhlx': 'タイプ',
  '_txcd._yhlx._ms': '銀行カードの種類を入力してください',
  '_txcd._yhly': 'ルーティング',
  '_txcd._yhly._ms': '銀行ルーティングを入力してください',
  '_txcd._yhmc': '銀行',
  '_txcd._yhmc._ms': '銀行名を入力してください',
  '_txcd._yhtx': '銀行現金引き出し',

  '_jys._gmjmhb': '暗号通貨を購入',
  '_jys._gmzy': '現在の国の政策に従って、次の取引所にアクセスしてアカウントを登録し、暗号通貨を購入できます。 その後、プラットフォーム アカウントにリチャージしてください。 ',

  '_kdydj._bt': 'ストアをオープンして高額賞品を獲得',
  '_kdydj._xyzbt': '幸運は回り続ける',
  '_kdydj._yx': '{n} 回ポンプします',
  '_kdydj._xh': '消費 {n} ポイント',

  '_kdydj._jf': 'ポイント:',
  '_kdydj._zjjl': '優勝記録',

  '_kdydj._hdgz': 'アクティビティ ルール',
  '_kdydj._qx': 'お願いします',
  '_kdydj._dl': 'ログイン',

  '_kdydj._mjdl': '販売者ログイン',
  '_kdydj._yxh': 'メール',
  '_kdydj._yxhsm': 'メールアドレスを入力してください',
  '_kdydj._yxhmm': 'パスワードを入力してください',
  '_kdydj._rgmyzh': 'アカウントをお持ちでない場合',
  '_kdydj._djzc': 'クリックして登録',
  '_kdydj._wjmm': 'パスワードを忘れた場合',

  '_kdydj._wdjp': '私の賞品',
  '_kdydj._zjts': '勝利のヒント',
  '_kdydj._jx': '続行',
  '_kdydj._czl': '勝ちました',

  '_kdydj._ljjl': '累計報酬',
  '_kdydj._ycy': '参加回数: {n} 回',
  '_kdydj._cy': '{n} 回参加します',
  '_kdydj._lq': '受信',
  '_kdydj._ylq': '受信しました',
  '_kdydj._wdd': '到達していません',

  '_dk._dkbt1': '起業家ローンはお金の借り方の悩みを解決します',
  '_dk._dkbt2': '起業家向け融資と資本循環サービスを提供します',
  '_dk._dkbt3': '資金が厳しい、お金を借りることができない、友人にお金を借りるのが恥ずかしいなどのローンの問題を解決するお手伝いをします。プロセス全体を通じて専門的なサービスを提供し、いつでも質問に答えます。目標は、すべてのローンを透明化し、顧客が迅速かつ安心してローンを利用できるようにすることです。 ',
  '_dk._zxsq': 'オンライン申請',
  '_dk._wddk': '私のローン',
  '_dk._aqwy': 'セキュリティで安心',
  '_dk._lhed': '柔軟なクォータ',
  '_dk._lhedb': '金額: 5,00 ～ 500,000、低金利、柔軟な返済方法、頭金なし、オンライン融資、審査電話なし、情報機密保持、即日融資。 ',
  '_dk._jsfk': 'クイックローン',
  '_dk._jsfkb': '最速1時間で審査通過、審査後すぐに入金されます。 ',
  '_dk._aqww1': '安全で安心',
  '_dk._aqww1b': '住宅ローン、頭金は必要ありません。折り返しの電話もありません。これは個人的な問題です。 ',

  '_dk._gywm': '私たちについて',
  '_dk._gywm1': '当社は、「顧客第一」の経営理念に基づき、顧客の資金回転問題を解決することを使命として、融資金融サービスに取り組んでおり、創業以来、数多くの顧客の資金回転問題の解決を支援してきました。問題。 ',
  '_dk._gywm2': '金額: 500 ～ 500,000、低金利、柔軟な返済方法、頭金なし、オンライン融資、審査電話なし、情報秘密保持、即日融資。 ',
  '_dk._gywm3': 'クラスメイトや友達にお金を借りたくない方は、ぜひご来店ください。 ',
  '_dk._gywm4': '起業家向け融資サービスに注力',
  '_dk._gywm5': '経済的な問題を解決してください',
  '_dk._hzhb': 'パートナー - オンライン融資プラットフォーム',
  '_dk._smwda': 'shopifyoutlets Business グローバル ステーションのユーザーは世界 112 か国から来ており、決済に暗号通貨 USDT/ETH/BTC/TRX を使用しています。暗号通貨は国境のない取引方法であり、待ち時間や国際手数料なしで世界中で即座に低コストの取引を行うことができます。 ',

  '_dk._yjwa': 'shopifyoutlets Business© Co., Ltd. 2022-2024 All Rights Reserved',
  '_dk._yjwab': 'シアーズ ホールディングス グローバル ソーシング リミテッド (0008945) は、信用ブローカーとして金融行動監視機構によって認可および規制されており、金融業者であるホーム リテール グループ カードサービス リミテッドと独占的に連携し、シアーズ グローバル ビジネス ファイナンシャル サービス、シアーズ グローバル ビジネス ファイナンシャルとして取引されています。サービスは、信用を提供するために金融行動監視機構によって認可および規制されています。 Home Retail GroupCard Services Limited はイングランド (04007072)、Avebury、489-499Avebury Boulevard、Milton Keynes、United Kingdom で登録されています。 MK9 2NW。 ',

  '_dk._cyzjsq': 'ビジネスローン申請書に記入',
  '_dk._dkll': 'ローン金利',
  '_dk._dkll2': 'ローン金利の説明 (クリックして表示)',
  '_dk._gs1': '数式',
  '_dk._gs2': '関心',
  '_dk._gs3': '融資金額',
  '_dk._gs4': '年利',
  '_dk._gs5': '融資日数',
  '_dk._gs6': 'たとえば',
  '_dk._gs7': '融資金額',
  '_dk._gs8': 'ローンサイクル',
  '_dk._gs9': '日',
  '_dk._fkfs': '融資方法',
  '_dk._zhye': 'アカウント残高',
  '_dk._dkqx': '貸出期間',
  '_dk._dkqx1': '貸出期間を選択',
  '_dk._sqje': '申請金額',
  '_dk._qsrdk': 'ローンを入力してください',
  '_dk._dkje': '融資額',
  '_dk._gj': '国籍',
  '_dk._qxzgj': '国籍を選択してください',
  '_dk._sqr': '申請者',
  '_dk._qsrsqsm': '申請者の名前を入力してください',
  '_dk._zjhm': 'ID番号',
  '_dk._qsrzjhm': 'ID番号を入力してください',
  '_dk._zjzp': '証明写真',
  '_dk._zjzp1': '身分証明書の表面の写真をアップロードしてください',
  '_dk._zjfm1': '身分証明書の裏面の写真をアップロードしてください',
  '_dk._zjsc1': '身分証明書の写真をアップロードしてください',
  '_dk._zjzp2': '画像を選択',
  '_dk._pzslt': '写真の例',
  '_dk._wytyb': '読んで同意します',
  '_dk._jkxy': 'ローン契約',
  '_dk._tjsq': '申請を送信',

  '_dk._wddk2': '私のローン',
  '_dk._ydk': '貸し出されました',
  '_dk._cslx': '利息を生成',
  '_dk._yjhk': '返済予定額',
  '_dk._dkjl': '融資記録',
  '_dk._hkjl': '返済記録',
  '_dk._sqrq': '申請日',
  '_dk._hkzq': '返済サイクル',
  '_dk._gdll': '固定金利',
  '_dk._hkz': '返済中',
  '_dk._yhk': '返済済み',
  '_dk._sqz': '適用中',
  '_dk._ytg': '合格',
  '_dk._ybh': '拒否されました',
  '_dk._tqhk': '繰り上げ返済',

  '_dk._ljhk': 'すぐに返済してください',
  '_dk._qrytqhk': '繰り上げ返済を申請してもよろしいですか?',
  '_dk._qrytqhk1': '注: 申請が成功すると、入金された金額は優先的にローンに直接返還されます',
  '_dk._dkxq': 'ローンの詳細',
  '_dk._dkje1': '融資金額',
  '_dk._yhlx': '利息の支払い',
  '_dk._hkze': '総返済額',
  '_dk._sqhkz': '返済申請中',
  '_dk._zxydbty': 'よく読んで、「ローン契約」に同意するボックスにチェックを入れてください',
  '_dk._zzsqz': '適用中',

  '_dk._dksq': 'クレジット',

  '_dk._cs': 'パラメータ',
  '_dk._nllsm': 'パーセンテージで表します。例: 6.25%',
  '_dk._dktssm': '融資承認の開始から終了までの合計日数',
  '_dk._yndts': '1 年の日数: 通常 365 日',
  '_dk._zlj': '延滞料金',

  '_xbb._xz': 'ダウンロード',
  '_xbb._xzapp': 'ShopifyOutlets アプリをダウンロード',
  '_xbb._smewm': 'QR コードをスキャンしてダウンロード',
  '_xbb._syfl': 'すべてのカテゴリ',
  '_xbb._gdfl': '詳細',
  '_xbb._sbbt1': '迅速な配達',
  '_xbb._sbbt2': '何か問題があった場合は返金可能です',
  '_xbb._sbbt3': '無料返品',
  '_xbb._sbbt4': '90日以内',
  '_xbb._tjsp1': '推奨製品',
  '_xbb._tjsp2': '多くの割引',
  '_xbb._tjsp3': '期間限定割引',

  '_xbb._asjgw': '店舗別ショッピング',
  '_xbb._gdsp': 'その他の製品',
  '_xbb._ljgm': '今すぐ購入',
  '_xbb._ptbz': 'ShopifyOutlets 保証',
  '_xbb._aqzf': '安全な支払い',
  '_xbb._aqzf1': '多くの海外の買い物客が使用する支払い方法',
  '_xbb._aqys': 'セキュリティとプライバシー',
  '_xbb._aqys1': '私たちはあなたのプライバシーを尊重しているので、あなたの個人情報は安全です',
  '_xbb._mjbh': '購入者保護',
  '_xbb._mjbh1': '注文が予定日までに配達されない場合、または注文に満足できない場合は、返金を受けることができます',
  '_xbb._mzsm': '警告/免責事項',

  '_xbb._sjxx': 'ビジネスマン',
  '_xbb._ggms': '仕様',
  '_xbb._mswa': '説明',
  '_xbb._khpl': 'お客様のコメント',
  '_xbb._zmfk': '肯定的なフィードバック',
  '_xbb._gzrs': 'フォロー',
  '_xbb._gzrsan': 'フォロー',
  '_xbb._ygzrsan': 'すでにフォローしています',
  '_xbb._fsxx': 'メッセージ',
  '_xbb._myrpl': 'まだコメントはありません',
  '_xbb._llgd': 'もっと見る',
  '_xbb._pdcl': '約束',
  '_xbb._myf': '送料無料',
  '_xbb._sdsj': '約 5 - 10 日でお届けします',
  '_xbb._ksjh': '迅速な配達',
  '_xbb._bgdstk': '紛失した荷物の払い戻し',
  '_xbb._shbgtk': '商品が破損していた場合は返金します',
  '_xbb._s30gtk': '30 日以内に発送されない場合は返金',
  '_xbb._aqyys': 'セキュリティとプライバシー',
  '_xbb._wjtrxk': '安全なお支払い: お客様の同意なしに個人情報を第三者と共有することはありません。 ',
  '_xbb._sfgrxx': '個人情報の保護: 当社はあなたのプライバシーを保護し、個人情報を安全に保ちます。 ',
  '_xbb._sl': '数量',
  '_xbb._kcld': 'インベントリ',

  '_xbb._sjrz': '商人が定住しました',
  '_xbb._hezjh': '当社のパートナー プログラムは、あらゆる範囲のマーケティング サポートとサービスを提供し、当社のカスタマー サービス チームは、製品の表示と販売戦略を最適化するために専門的なサポートとアドバイスを提供します。今すぐ参加しましょう！より大きなビジネスチャンスを創造し、一緒に成長していきましょう！ ',
  '_xbb._xsje': 'ビジネス情報',
  '_xbb._rgsqjs': 'すでに販売者である場合',
  '_xbb._qni': 'お願いします',
  '_xbb._djdl': 'クリックしてログイン',
  '_xbb._dplog': 'ストアのロゴ',
  '_xbb._dpmc': 'ストア名',
  '_xbb._srdpmc': 'ストア名を入力してください',
  '_xbb._zjhm': 'ID番号',
  '_xbb._srzjhm': 'ID番号を入力してください',
  '_xbb._zsxm': '本名',
  '_xbb._srzsxm': '本名を入力してください',
  '_xbb._srgj': '国',
  '_xbb._srsrgj': '国を入力してください',
  '_xbb._zjz': '証明写真',
  '_xbb._zjzm': 'ドキュメントの前面',
  '_xbb._zjfm': '証明書の裏面',
  '_xbb._sczjz': '証明写真を保持',
  '_xbb._pssl': '写真例',
  '_xbb._dlyx': 'ログインメールアドレス',
  '_xbb._srdlyx': 'ログインメールアドレスを入力してください',
  '_xbb._dlmm': 'ログインパスワード',
  '_xbb._srdlmm': 'ログインパスワードを入力してください',
  '_xbb._qrmm': 'パスワードを確認してください',
  '_xbb._srqrmm': '確認パスワードを入力してください',
  '_xbb._yqm': '招待コード',
  '_xbb._sryqm': '招待コードを入力してください',
  '_xbb._yxyzm': 'メール認証コード',
  '_xbb._sryzm': '電子メール確認コードを入力してください',
  '_xbb._yydbty': '読んで同意しました',
  '_xbb._rzxy': '和解合意',

  '_xbb._qgxty': '和解契約をよく読み、同意するボックスにチェックを入れてください',
  '_xbb._sryqmb': '招待コードを入力してください',

  '_xbb._ddpl': 'コメントの順序',
  '_xbb._dsppl': '製品に関するコメント',
  '_xbb._dddpl': '注文に関するコメント',

  '_xhd._sdj': 'クリスマス'
}
